<h2>Submit feedback</h2>
<mat-form-field class="mat-paginator-page-size-select">
  <mat-label></mat-label>
  <textarea matInput [(ngModel)]="feedback"
    placeholder="Information about what you were working on will automatically be sent with your feedback."
    rows="10"></textarea>
</mat-form-field>
<div fxLayout="row" fxLayoutAlign="end center">
  <button mat-button color="primary" id="feedbackCancel" (click)="dialogRef.close()">Cancel</button>
  <button mat-raised-button color="primary" id="feedbackSubmit" (click)="dialogRef.close(feedback)"
    [disabled]="!feedback.length">Submit</button>
</div>