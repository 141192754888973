<app-tenants-form [tenants]='tenants$ | async' (manageAccess)='manageAccess($event)' (refresh)='refreshToken$.next()'
  (acceptInvitation)="acceptRejectInvitation($event, 'accepted')"
  (rejectInvitation)="acceptRejectInvitation($event, 'rejected')" (leaveTenant)='leaveTenant($event)'>
</app-tenants-form>

<h1>{{ currentTenant?.name }}</h1>

<app-tenant-deployments-list *ngIf='deployments$ | async | nonIccaDeploymentsListFilter as deployments'
  [deployments]='deployments' [currentTenantData]='currentTenantData' (selected)='onSelect($event)'
  (deleted)='onDelete($event)'>
</app-tenant-deployments-list>

<app-access-management [members]='members$ | async' [currentUser]='user' [tenantOwner]='tenantOwner$ | async'
  (deleteUser)='deleteUser($event)' (changeRole)='changeRole($event)' (inviteMember)='inviteMember()'>
</app-access-management>