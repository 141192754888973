import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-sysinfo-view',
  templateUrl: './sysinfo-view.component.html',
  styleUrls: ['./sysinfo-view.component.scss'],
})
export class SysinfoViewComponent {
  @Input() versionData;
  @Input() launchResources;
}
