import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User, DeploymentService, TenantsService, UsersService } from 'api';
import { filter, map, switchMap, take, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/core/auth.service';
import { BehaviorSubject, combineLatest, Observable, of, Subscription } from 'rxjs';
import { DeploymentsService } from '../../../../deployments/deployments.service';
import { SharedService } from '../../../../shared/services/shared.service';
import { MatDialog } from '@angular/material/dialog';
import { InviteMembersDialogComponent } from '../../access-management/dialogs/invite-members/invite-members-dialog.component';
import { DeleteTenantDialogComponent } from '../../access-management/dialogs/delete-tenant/delete-tenant-dialog.component';
import { DeleteUserDialogComponent } from '../../access-management/dialogs/delete-user/delete-user-dialog.component';
import { DeploymentDeleteDialogComponent } from '../../../../deployments/common/list/dialogs/deployment-delete/deployment-delete-dialog.component';
import { MainMenuService } from '../../../../core/main-menu.service';

@Component({
  selector: 'app-tenants-page',
  templateUrl: './tenants-page.component.html',
  styleUrls: ['./tenants-page.component.scss'],
})
export class TenantsPageComponent implements OnInit, OnDestroy {
  refreshToken$ = new BehaviorSubject<void>(undefined);

  public tenants$: Observable<any>;
  public invitedTenants$: Observable<any>;
  public members$: Observable<any>;
  public deployments$: Observable<any>;

  private sub = new Subscription();

  public members: any;

  public user: User;
  public currentTenant: any;
  public currentUserTenantId: any;
  public tenantOwner$ = new BehaviorSubject<any>(undefined);
  public currentTenantData: any;

  constructor(
    private authService: AuthService,
    private deploymentsService: DeploymentsService,
    private deploymentService: DeploymentService,
    private tenantsService: TenantsService,
    private usersService: UsersService,
    private route: ActivatedRoute,
    private router: Router,
    private sharedService: SharedService,
    private mainMenuService: MainMenuService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.user;

    this.tenants$ = this.refreshToken$.pipe(
      switchMap(() => this.authService.authorise(null, null)),
      switchMap(() =>
        combineLatest([
          this.usersService.getUserByUsername(this.user.username).pipe(map(user => user.tenants)),
          this.usersService.getTenantInvitations(this.user.username).pipe(map(result => result.invitations)),
        ]),
      ),
      map(([userTenants, invitations]) => {
        // ID of current user need just for able to delete other users
        this.currentUserTenantId = Object.keys(userTenants)[0];
        // Create a new list containing both current tenants and invitations
        const curentTenants = userTenants
          ? Object.keys(userTenants).map(id => ({ ...userTenants[id], id, status: 'Active' }))
          : [];
        const currentInvitations = invitations
          ? invitations.map(invitation => ({
              id: invitation.tenantid,
              tenantid: invitation.tenantid,
              invitationid: invitation.invitationid,
              name: invitation.tenant_name,
              role: invitation.role,
              type: invitation.item_type,
              status: 'Pending',
            }))
          : [];
        return curentTenants.concat(currentInvitations);
      }),
    );

    this.currentTenantData = this.authService.getCurrentTenantData();
  }

  manageAccess(tenant: any): void {
    this.currentTenant = tenant;
    if (tenant.role == 'admin' || tenant.role == 'write') {
      this.members$ = this.refreshToken$.pipe(
        switchMap(() =>
          combineLatest([this.tenantsService.getMembers(tenant.id), this.tenantsService.getInvitedMembers(tenant.id)]),
        ),
        map(([members, invitations]) => {
          /// Create a new list containing both current members and invitations
          const curentMembers = members
            ? members.members.map(member => ({
                ...member,
                member: member.first_name + ' ' + member.last_name + ' @' + member.username,
                table_id: member.username,
                status: 'Active',
              }))
            : [];
          const currentInvitations = invitations
            ? invitations.invitations.map(invitation => ({
                ...invitation,
                member: invitation.email,
                table_id: invitation.email.replace(/[^\w]/g, ''),
                status: 'Pending',
              }))
            : [];
          if (members?.tenant_owner) this.tenantOwner$.next(members.tenant_owner);
          this.members = curentMembers.concat(currentInvitations);
          return curentMembers.concat(currentInvitations);
        }),
      );
    } else {
      this.members$ = null;
    }

    this.deployments$ = this.tenantsService.getDeploymentsByTenantId(tenant.id).pipe(
      map(deployments => {
        return deployments?.deployments
          ? { deployments: deployments.deployments.sort((a, b) => a.deploymentname.localeCompare(b.deploymentname)) }
          : undefined;
      }),
    );
  }

  leaveTenant(tenant): void {
    const dialogRef = this.dialog.open(DeleteTenantDialogComponent, {
      data: {
        tenant: tenant.name,
        name: this.user.username,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((result: any) => result),
        tap(result => {
          if (result) {
            let serviceCall = null;
            serviceCall = this.tenantsService.kickMember(tenant.id, this.user.username);
            this.sub.add(
              serviceCall
                .pipe(
                  tap(() => {
                    this.deployments$ = of(null);
                    this.members$ = of(null);
                    this.currentTenant = null;
                    this.authService.onLeaveTenant(tenant.id);
                    this.refreshToken$.next();
                  }),
                )
                .subscribe(
                  () => {
                    this.sharedService.showSuccess('Successfully left tenant ' + tenant.name);
                  },
                  () => {
                    this.sharedService.showAlert('Something went wrong. Please, try again later.');
                  },
                ),
            );
          }
        }),
      )
      .subscribe();
  }

  deleteUser(user: any): void {
    const dialogRef = this.dialog.open(DeleteUserDialogComponent, {
      data: {
        tenant: this.currentTenant.name,
        name: user.member,
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter((result: any) => result),
        tap(result => {
          if (result) {
            let serviceCall = null;
            if (user.status == 'Pending') {
              serviceCall = this.tenantsService.kickInvitationAcceptRejectInvitation(
                this.currentTenant.id,
                user.invitationid,
                { invitation_status: 'WITHDRAWN' },
              );
            } else {
              serviceCall = this.tenantsService.kickMember(this.currentTenant.id, user.username);
            }
            this.sub.add(
              serviceCall
                .pipe(
                  tap(() => {
                    // Handle a situation when we delete ourselves
                    if (user.username == this.authService.user.username) {
                      this.deployments$ = of(null);
                      this.members$ = of(null);
                      this.authService.onLeaveTenant(this.currentTenant.id);
                      this.currentTenant = null;
                    }
                    this.refreshToken$.next();
                  }),
                )
                .subscribe(
                  () => {
                    this.sharedService.showSuccess('Successfully removed member ' + user.member);
                  },
                  () => {
                    this.sharedService.showAlert('Something went wrong. Please, try again later.');
                  },
                ),
            );
          }
        }),
      )
      .subscribe();
  }

  changeRole(formModel: any): void {
    this.sub.add(
      this.tenantsService
        .changeMemberRole(this.currentTenant.id, formModel.username, { role: formModel.newRole })
        .pipe(
          tap(() => {
            if (formModel.username == this.authService.user.username) {
              this.deployments$ = of(null);
              this.members$ = of(null);
              this.currentTenant = null;
            }
            this.refreshToken$.next();
          }),
        )
        .subscribe(
          () => {
            this.sharedService.showSuccess('Successfully changed a role for ' + formModel.username);
          },
          () => {
            this.sharedService.showAlert('Something went wrong. Please, try again later.');
          },
        ),
    );
  }

  inviteMember(): void {
    const dialogRef = this.dialog.open(InviteMembersDialogComponent, {
      data: {
        deploymentName: this.route.snapshot.paramMap.get('deploymentId'),
        members: this.members,
        isAdmin: this.user.is_admin || this.currentTenant.role == 'admin',
      },
    });

    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result),
        tap(result => {
          if (result) {
            this.sub.add(
              this.tenantsService
                .inviteMember(this.currentTenant.id, {
                  created_by: this.authService.user.username,
                  email: result.email,
                  role: result.role.value,
                  tenant_name: this.currentTenant.name,
                })
                .pipe(tap(() => this.refreshToken$.next()))
                .subscribe(
                  () => {
                    this.sharedService.showSuccess('Successfully invited a new member');
                  },
                  () => {
                    this.sharedService.showAlert('Something went wrong. Please, try again later.');
                  },
                ),
            );
          }
        }),
      )
      .subscribe();
  }

  acceptRejectInvitation(invitation, status) {
    this.sub.add(
      this.tenantsService
        .kickInvitationAcceptRejectInvitation(invitation.tenantid, invitation.invitationid, {
          invitation_status: status,
        })
        .pipe(tap(() => this.refreshToken$.next()))
        .subscribe(
          () => {
            this.authService.setCurrentTenantId(invitation.tenantid);
            this.sharedService.showSuccess('Successfully ' + status + ' invitation');
            this.mainMenuService.getTenantInvitations();
          },
          () => {
            this.sharedService.showAlert('Something went wrong. Please, try again later.');
          },
        ),
    );
  }

  onSelect(deploymentId: string) {
    this.authService.setCurrentTenantId(this.currentTenant.id);
    this.router.navigate(['/deployments', deploymentId, 'overview']);
  }

  onDelete(deploymentId: string) {
    of(deploymentId)
      .pipe(
        switchMap(id => this.deploymentsService.deployment$(id)),
        take(1),
        switchMap(deployment => this.openConfirmationDialog(deployment)),
        filter(dialogResult => dialogResult),
        switchMap(() => this.deploymentService.deleteDeploymentById(this.authService.currentTenantId, deploymentId)),
        tap(() => this.sharedService.reloadCurrentRoute()),
        tap(() => this.sharedService.showSuccess('Successfully deleted deployment')),
      )
      .subscribe();
  }

  openConfirmationDialog(deployment) {
    return this.dialog
      .open(DeploymentDeleteDialogComponent, {
        data: { deploymentName: deployment.deploymentname },
      })
      .afterClosed();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
