<div class="wrapper">
  <a
    class="section-toggle"
    tabindex="0"
    [attr.id]="field?.id ? (formState?.Id ? formState?.Id + '-' : '') + field?.id + '-toggle' : null"
    [class.expanded]="field?.formControl?.value"
    (click)="toggle()"
    (keydown.space)="$event.preventDefault()"
    (keyup.Space)="$event.stopPropagation(); toggle()"
  >
    <mat-icon *ngIf="!field?.formControl?.value" class="icon">{{ field?.iconCollapsed }}</mat-icon>
    <mat-icon *ngIf="field?.formControl?.value" class="icon">{{ field?.iconExpanded }}</mat-icon>
    <span class="section-toggle-text">{{ field?.templateOptions?.label }}</span>
  </a>
</div>
