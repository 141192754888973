import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { switchMap, catchError } from 'rxjs/operators';
import { MainMenuService } from 'src/app/core/main-menu.service';
import { DeploymentsService } from '../deployments.service';
import { DeploymentObject } from 'api';
import { IRISAuthService } from '../icca-common/components/iris-login/iris-auth.service';
import { IRISCredential } from '../icca-common/model/iris-credential'; 

@Injectable({
  providedIn: 'root',
})
export class IRISAuthGuard implements CanActivate, CanActivateChild {
  constructor(
    //private authService: AuthService,
    private router: Router,
    private mainMenuService: MainMenuService,
    private deploymentsService: DeploymentsService,
    private irisAuthService: IRISAuthService,
  ) {}

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    //if (!this.deploymentsService.deployments) return true;
    const redirectUrl = state?.url === '/sign-out' ? '/' : state?.url;
    if (route.paramMap.get('deploymentId') == '') {
      if (state) {
        this.router.navigate(['deployments']);
      }
      return false;
    }
    return this._check(route.paramMap.get('deploymentId'), redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const redirectUrl = state?.url === '/sign-out' ? '/' : state?.url;
    const currentDeployment = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      childRoute.paramMap.get('deploymentId'),
    ) as DeploymentObject;

    if (!currentDeployment) {
      this.router.navigate(['/']);
      return true;
    }

    if (this.deploymentsService.getOfferArchitecture(currentDeployment.deploymenttype) != 'icca') return true;

    return this._check(currentDeployment.deploymentid, redirectUrl);
  }

  /**
   * Check the authenticated status
   *
   * @param deploymentId
   * @param redirectUrl
   * @private
   */
  private _check(deploymentId: string, redirectUrl: string): Observable<boolean> {
    // Check the authentication status
    return this.irisAuthService.check$(deploymentId).pipe(
      switchMap(authenticated => {
        // If the user is not authenticated...
        if (!authenticated) {
          // Redirect to the sign-in page
          //this.mainMenuService.updateMenu();
          this.router.navigate(['deployments', deploymentId, 'irislogin'], { queryParams: { redirectUrl } });
          // Prevent the access
          return of(false);
        }
        // Allow the access
        return of(true);
      }),
      catchError(() => {
        this.router.navigate(['deployments', deploymentId, 'irislogin'], { queryParams: { redirectUrl } });
        return of(false);
      }),
    );
  }
}
