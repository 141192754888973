import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { FormButton, FDN } from '@intersystems/isc-form';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { DeploymentObject } from 'api';
import { Subscription } from 'rxjs';
import { delay, map, repeatWhen, tap } from 'rxjs/operators';
import { DeploymentInfo } from 'src/app/deployments/icca-common/model/deployment-info';
import { IccaCommonService } from 'src/app/deployments/icca-common/services/icca-common.service';
import { SdsDeploymentInfo } from 'src/app/deployments/sds/model/sds-deployment-info';

@Component({
  selector: 'app-deployment-logs-dialog',
  templateUrl: './deployment-logs-dialog.component.html',
  styleUrls: ['./deployment-logs-dialog.component.scss'],

})
export class DeploymentLogsDialogComponent implements OnInit, OnDestroy{
  constructor(
    public dialogRef: MatDialogRef<DeploymentLogsDialogComponent>,
    private iccaCommonService: IccaCommonService,
    private tableService: TableService,
    
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}
  
  private sub = new Subscription();
  logData:any = [];

  ngOnInit(): void {
    this.sub.add(this.iccaCommonService.loadDeploymentEvents$(this.data.deployment.deploymenttype,
      this.data.deployment.deploymentid,
      this.data.deployment.region).pipe(
      repeatWhen(obs => obs.pipe(delay(5000))),
      tap(deploymentEvents => {
        //sort by timestamp desc
        this.logData= deploymentEvents.info.events.sort((a: any, b: any) => {
          return this.tableService.compareAlphaNumeric(a.timestamp.toUpperCase(), b.timestamp.toUpperCase(), false);
        }
        );
      }),
    ).subscribe(
    deploymentEvents => {},
    //lambda will return 404 if deployment is deleted
    err =>{
      if ((err?.status==404)&&this.data.errorStatus=='deleting') {
        this.tableLogs.noDataMessage='Error occurred fetching cluster. Incomplete deployment creation or incomplete deployment deletion. Please delete deployment.';
      } else if (err?.status==404) {
        this.tableLogs.noDataMessage='Deployment not found';
      }
      else {
        this.tableLogs.noDataMessage='Error getting deployment info: ' + err.error.error;
      }     
    }));

  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }

  tableLogs: TableConfig = {
    key: 'deployment-logs-table',
    cssTRClass: 'tr-hotspot',
    useSearch: true,
      "searchConfig": {
        "noEntriesFoundLabel": "No entries found",
        "placeholderLabel": "Search",
        //"selectedRowHiddenLabel": "(hiddenRowCount: number) => string // TODO"
      },
    noDataMessage: 'No log entries to display.',
    sort: {
      sortFunction: (event: Sort, data: any) => {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          switch (event.active) {
            case "type":
            case "status":
            case "reason":
            case "message":
              return this.tableService.compareAlphaNumeric(a[event.active].toUpperCase(), b[event.active].toUpperCase(), false);
            case "timestamp":
              return this.tableService.compareAlphaNumeric(a[event.active], b[event.active], isAsc);
            default:
              return 0
              
          }
        });
        return sortedData;
      },
      initialSortArrowDirection: {
        columnKey: "timestamp",
        direction: "desc",
      }
    },
    columns: [
      {
        title: 'Timestamp',
        key: 'timestamp',
        sortable: false,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            if (!row?.timestamp) return '';
            //need to do this because safari doesn't support the - or . in the dateformat.
            var dateVal=row.timestamp;
            dateVal=dateVal.replace(/-/g, "/");
            //strip off decimal on the time if it exists.
            if (dateVal.indexOf('.')>0) {
              dateVal=dateVal.substr(0, dateVal.indexOf('.')); 
            }
            dateVal=dateVal.toLocaleString();
            return dateVal;   //test.toLocaleString(); //row.lastModifiedTime.toLocaleString();
          },
        },
      },
      {
        title: 'Type',
        key: 'type',
        sortable: false,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.type;
          },
        },
      },
      {
        title: 'Reason',
        key: 'reason',
        sortable: false,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.reason;
          },
        },
      },
      {
        title: 'Message',
        key: 'message',
        sortable: false,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.message;
          },
        },
      },
    ],
  };
  paginatorConfig: PaginatorConfig = {
    pageSize: 5,
  };
  
  
}
