<mat-card
  class="depCard"
  [class.clickable]="canClick()"
  (click)="onClick()"
  [attr.id]="'btn-select-deployment-' + deployment.deploymentname"
>
  <div class="indicator-panel" [ngClass]="{ active: isActive(deployment) }"></div>
  <button
    *ngIf="isActive(deployment) && currentTenantData.role != 'read'"
    mat-mini-fab
    class="depMenu"
    [matMenuTriggerFor]="menu"
    (click)="$event.stopPropagation()"
    aria-label="Menu"
    [attr.id]="'btn-menu-deployment-' + deployment.deploymentname"
  >
    <mat-icon svgIcon="dots-vertical"></mat-icon>
  </button>
  <div class="header">
    <mat-card-title>
      {{ deployment.deploymentname }}
    </mat-card-title>
    <div>{{ getServiceType(deployment) }}</div>
    <div>{{ deployment.region | RegionTextTransformPipe }} {{ deployment.region }}</div>
    <div *ngIf="isICCA && +billingInfo?.balance > 0">
      <br />Remaining free trial balance ${{ +billingInfo?.balance }}
    </div>
    <div *ngIf="isICCA && +billingInfo?.balance <= 0"><br />Billed to your AWS account</div>
    <div *ngIf="getDeploymentStatus(deployment, deploymentInfo) != 'COMPLETE'">
      <span [attr.id]="'label-deployment-id-' + deployment.deploymentname">{{ deployment.deploymentid }}</span>
      <app-copy-button
        [copyText]="deployment.deploymentid"
        [buttonName]="'btn-copy-deployment-id-' + deployment.deploymentname"
      >
      </app-copy-button>
    </div>
    <div *ngIf="hasFeature(deployment, 'dr')" class="feature-status">
      <mat-icon>link</mat-icon>
      <span id="dr-feature-status">
        DR: {{ deployment.features["dr"].role | titlecase }}, {{ deployment.features["dr"].status | titlecase }}</span
      >
    </div>
  </div>
  <div class="content">
    <div fxLayout="row" fxLayoutAlign="space-between">
      <!--NOTE this deployment deleted is only temporary for cost savings when we delete backend deployments but they are still registered in portal-->
      <div
        *ngIf="deploymentErrorStatus == 'notFound'"
        style="text-align: begin"
        [id]="'deployment-status-' + deployment.deploymentid"
      >
        <b>Deployment {{ deployment.deploymentid }} could not be found</b>
      </div>
      <div
        *ngIf="deploymentErrorStatus == 'deleting'"
        style="text-align: begin"
        [id]="'deployment-status-' + deployment.deploymentid"
      >
        Status: Deleting
      </div>
      <div
        *ngIf="deploymentErrorStatus == ''"
        style="text-align: begin"
        [id]="'deployment-status-' + deployment.deploymentid"
      >
        {{ getDeploymentStatus(deployment, deploymentInfo) }}
      </div>
      <div *ngIf="deployment.service_level != 'Default'" style="text-align: end">
        <mat-chip-list>
          <mat-chip mat-mini-fab selected="selected" color="primary">{{ deployment.service_level }} </mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
</mat-card>
<div *ngIf="deployment.deploymenttype == 'sds'" class="sds-card-buttons" fxLayout="row" fxLayoutAlign="flex-start">
  <fr-button [config]="getLogsButtonconfig" [Id]="'get-logs-button-' + deployment.deploymentname"> </fr-button>
  <fr-button [config]="retryButtonConfig" [Id]="'retry-button-' + deployment.deploymentname"> </fr-button>
</div>

<mat-menu #menu="matMenu">
  <button
    mat-menu-item
    *ngIf="canChangeServiceLevel()"
    (click)="servicelevelClick($event)"
    [attr.id]="'btn-service-level-deployment-' + deployment.deploymentname"
    aria-label="Service Level"
  >
    <span>Service Level</span>
  </button>
  <button
    disabled="true"
    mat-menu-item
    *ngIf="canUpdate()"
    (click)="updateversionClick($event)"
    [attr.id]="'btn-update-deployment-' + deployment.deploymentname"
    aria-label="Update"
  >
    <span>Update</span>
  </button>
  <button
    mat-menu-item
    *ngIf="canGetLogs()"
    (click)="getLogsClick($event)"
    [attr.id]="'btn-getlogs-deployment-' + deployment.deploymentname"
    aria-label="Show logs"
  >
    <span>Show logs</span>
  </button>
  <button
    mat-menu-item
    *ngIf="canDelete()"
    (click)="deleteClick($event)"
    [attr.id]="'btn-delete-deployment-' + deployment.deploymentname"
    aria-label="Delete"
  >
    <span>Delete</span>
  </button>
</mat-menu>
