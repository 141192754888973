import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../core/auth.service';

@Injectable({
  providedIn: 'root',
})
// Checks if the given user is a portal-level admin
export class PortalAdminGuard implements CanActivate {
  constructor(private authService: AuthService) {}
  
  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.user) return this.authService.user.is_admin;
  }
}
