<div class="wrapper">
  <mat-slider class="example-margin" thumbLabel="true" [displayWith]="formatLabel" [max]="deploymentSizes.length-1"
    [min]="currentSize" [step]="1" [tickInterval]="1" [(value)]="targetSize" (input)="onSlideEvent($event)"
    id="resize-slider">
  </mat-slider>
  <div id="card-container">
    <mat-card>
      <mat-card-header>
        <mat-card-title>Current</mat-card-title>
      </mat-card-header>
      <p>{{deploymentSizes[currentSize].name}}</p>
      <p>Cores: {{deploymentSizes[currentSize].cores}}</p>
      <p>RAM: {{deploymentSizes[currentSize].ram}}</p>
      <p>Storage: {{deploymentSizes[currentSize].storage}}</p>
      <!--<p>{{deploymentSizes[currentSize].description}}</p>-->
    </mat-card>
    <mat-card>
      <mat-card-header>
        <mat-card-title>Target</mat-card-title>
      </mat-card-header>
      <p>{{deploymentSizes[targetSize].name}}</p>
      <p>Cores: {{deploymentSizes[targetSize].cores}}</p>
      <p>RAM: {{deploymentSizes[targetSize].ram}}</p>
      <p>Storage: {{deploymentSizes[targetSize].storage}} </p>
      <!--<p>{{deploymentSizes[targetSize].description}}</p>-->
    </mat-card>
  </div>
  <p>By confirming deployment resizing, you agree to a change in the cost of your
    subscription. Please note that the service will be unavailable during this process.</p>
  <!--
    <a>
    <button id="btn-resize" mat-raised-button color="primary" [disabled]="currentSize==targetSize"
      (click)="resize.emit(deploymentSizes[targetSize].size)">Resize</button>
  </a>
  -->

</div>