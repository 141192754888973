<mat-card-title class="card-title">Schema Tree
  <app-info-button [htmlText]="infoObject.htmlText" [title]="infoObject.infoTitle"></app-info-button>
</mat-card-title>
<div><mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
</div>
<mat-tree id="schema-tree" class="tree" [dataSource]="dataSource" [treeControl]="treeControl"> 

  
    <mat-tree-node class="fr-primary" *matTreeNodeDef="let node" 
    matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="20" 
    class="tree-node" [id]="'schema-' + node.id" (click)="nodeClick(node)">
    <button class="tree-button" mat-icon-button disabled [id]="'btn-schema-' + node.id" ></button>
    {{node.name}}
  </mat-tree-node>




    <!-- This is the tree node template for expandable nodes -->
    <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20" 
      class="tree-node" [id]="'schema-' + node.id" (click)="nodeClick(node)"> 
      <button class="tree-button" mat-icon-button matTreeNodeToggle
              [attr.aria-label]="'Toggle ' + node.name" [id]="'btn-schema-' + node.id" >
        <mat-icon>
          {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      {{node.name}}
    </mat-tree-node>
  </mat-tree>

  