import { Pipe, PipeTransform } from '@angular/core';
/*
 * Format number of bytes into number of gigabytes
 */
@Pipe({ name: 'formatGBytes' })
export class FormatGBytesPipe implements PipeTransform {
  transform(value: number): number {
    return Math.round((value / 1024 ** 3) * 10) / 10;
  }
}
