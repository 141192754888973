import { Component, Inject, OnInit } from '@angular/core';
import { IscFormModalData } from '@intersystems/isc-form';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormButton, FDN, VALIDATORS } from '@intersystems/isc-form';
@Component({
  selector: 'app-external-connection-dialog',
  templateUrl: './external-connection-dialog.component.html',
  styleUrls: ['./external-connection-dialog.component.scss'],
})
export class ExternalConnectionDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ExternalConnectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IscFormModalData,
  ) {}
}
