import { Component, OnInit } from '@angular/core';
import { Field, FieldData } from '@intersystems/isc-form';

@Component({
  selector: 'app-icca-file-viewer',
  templateUrl: './file-viewer.component.html',
  styleUrls: ['./file-viewer.component.scss'],
})
export class IccaFileViewerComponent implements OnInit {
  field: FieldData;
  formState: any;
  model: any;
  fieldContent: string;

  ngOnInit(): void {
    this.fieldContent = this.field.content;
    this.field.content = '';
  }
}
