import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-performance-support',
  templateUrl: './performance-support.component.html',
  styleUrls: [`./performance-support.component.scss`],
})
export class PerformanceSupportComponent {
  constructor(
    private router: Router,
  ) {}
  @Output() closeDrawer = new EventEmitter<any>();

  stage=environment.STAGE;
  
  preloadCreatePage() {
    //need to figure out how to preload create page with values needed for IML
    this.router.navigate(['/deployments', 'create','iml']);

  }  

    
}
