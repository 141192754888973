import { Component, Input, ViewChild } from '@angular/core';
import { MatTooltip } from '@angular/material/tooltip';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'app-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
})
export class CopyButtonComponent {
  constructor(private clipboard: Clipboard) {}
  @Input() copyText = '';
  @Input() buttonName = 'btn-copy';
  @ViewChild('tooltip') tooltip: MatTooltip;
  copyClick() {
    this.clipboard.copy(this.copyText);
    this.tooltip.show();
    setTimeout(() => {
      //this.tooltip.message = 'Copy to clipboard';
      this.tooltip.hide();
    }, 3000);
  }
}
