import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Input } from '@angular/core';
import { BehaviorSubject, Observable, pipe, Subscription } from 'rxjs';
import { FileListItem } from 'src/app/deployments/icca-common/model/file-list';
import { TableConfig, EPresetOptions, CheckboxConfig, TableConfigService, Column, TableService } from '@intersystems/table';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatorConfig } from '@intersystems/table';
// First import the typescript interfaces
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from '@intersystems/confirmation-dialog';
import { TopbarControlService } from '@intersystems/header';
import { ActivatedRoute, Router } from '@angular/router';
import { cloneDeep } from 'lodash';

import { DeploymentObject } from 'api';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { IscFormModalData, IscFormModalComponent } from '@intersystems/isc-form';
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { NotificationService } from '@intersystems/notification';
import { ImportResponse } from '../../model/sql-response'; 
import { HttpClient, HttpEventType, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { IccaFileViewerComponent } from './file-viewer/file-viewer.component';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { Sort } from '@angular/material/sort';
import { SQLQueryService } from 'src/app/deployments/sqlaas/sql-query/sql-query.service';
import { FileHandlerService } from '../../services/file-handler.service';
import { ExternalObjectStorageService } from 'src/app/deployments/icca-common/services/external-object-storage.service';
import { SpinnerService } from '@intersystems/spinner';
import { SharedService } from 'src/app/shared/services/shared.service';
import { IccaCommonService } from '../../services/icca-common.service';
import { IccaExternalObjectStorageDialogComponent } from '../../dialogs/external-object-storage/external-object-storage-dialog.component';
import { ExternalStorageObject } from '../../model/external-object-storage';
import { delay, repeatWhen, take, takeWhile } from 'rxjs/operators';
import { GenericDialogComponent } from '../../dialogs/generic/generic-dialog.component';

export enum FormModes {
  VIEW = 'view',
  EDIT = 'edit',
}

@Component({
  selector: 'app-icca-uploaded-files',
  templateUrl: './uploaded-files.component.html',
  styleUrls: ['./uploaded-files.component.scss'],
})
export class IccaUploadedFilesComponent implements OnInit, OnDestroy {
  //get events from step1 to update label
  @Input() stepperEvents: Observable<void>;
  @Input() importType = ''; // decorate the property with @Input()
  @Input() ddlType = ''; // decorate the property with @Input()
  @Output() fileEvents = new EventEmitter<string>();
  private sub = new Subscription();
  fileManagement:boolean=true;
  acceptFilter='.csv, .ddl, .dml, .sql, .txt';
  
  uploadFiles: File[] = [];
  uploadProgress: number[] = [];

  currentFiles: string[] = [];
  @ViewChild('fileInput') fileInput: ElementRef;

  fileList: File[] = [];
  httpRequestHeaders = new HttpHeaders({});

  selectedFilename: string;

  files: FileListItem[] = [];
  fileData: any; //used for viewing files

  response: ImportResponse[] = [];
  responseCt: number;
  responseTotal: number;
  //deployment: DeploymentObject | undefined;

  rowsChecked: any[] = [];
  loadInProgress = false;
  expandSection:boolean = false;
  refreshToken$ = new BehaviorSubject<void>(undefined);
  
  tableConfig: TableConfig = {
    key: 'files-table',
    cssTableClass: 'table-class',
    cssTRClass: 'table-row',
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('name'),
    onRowClick: (row: any) => {
      row['select'] = !row['select'];
      try {
        this.tableConfig.columns
          .find(column => column.key === 'select')
          .cellDisplay.checkbox.onRowsChecked([row], row['select'], this.rowsChecked);
      } catch (e) {}
    },
    useSearch: true,
      "searchConfig": {
        "noEntriesFoundLabel": "No files found",
        "placeholderLabel": "Search",
        //"selectedRowHiddenLabel": "(hiddenRowCount: number) => string // TODO"
      },
    noDataMessage: 'No files have been added.  Click Upload or External transfer to add files.',
    //stickyHeaderRow: false,
    sort: {
      sortFunction: (event: Sort, data: any) => {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          switch (event.active) {
            case "name":
              return this.tableService.compareAlphaNumeric(a.name.toUpperCase(), b.name.toUpperCase(), isAsc);
            case "size":
              return this.tableService.compareAlphaNumeric(+a.size, +b.size, isAsc);

            case "lastModifiedTime":
              return this.tableService.compareAlphaNumeric(a.lastModifiedTime, b.lastModifiedTime, isAsc);
            default:
              return 0
              
          }
        });
        return sortedData;
      },
    },
    header: {
      title: 'Select file(s) to import',
    },
    rowIdentifierProperty: 'name',
    columns: [
      {
        sortable: false,
        id: 'select',
        key: 'select',
        cellDisplay: {
          //model: 'select',
          preset: EPresetOptions.CHECKBOX,
          checkbox: {
            //rowIdentifierProperty: 'name',
            rowsChecked: [],
            rowCheckedStatusField: 'select',
            onRowsChecked: (rowsChecked, isCheck, allRowsChecked) => {
              this.onFileSelect(rowsChecked, isCheck, allRowsChecked);
            }
           },
        },
      },

      {
        sortable: true,
        title: 'Name',
        id: 'name',
        key: 'name',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row.name;
          },
        },
      },
      {
        sortable: false,
        title: '',
        id: 'status',
        key: 'status',
        //cellDisplay: {
        //  getDisplay: (row: any, col: any) => {
        //    return (row.type=='transfer' ? 'Transferring...' : (row.type=='error' ? 'Error' : ''));
        //  },
        //},
        cellDisplay: {
          preset: EPresetOptions.LINK,
          link: {
            text: (row, col) => (row.type=='transfer' ? 'Transferring...' : (row.type=='error' ? 'Error' : '')),
            callback: (event, row, col) => {
              if (row.type=='error') { 
                this.viewErrorFile(row.name);
                  return;
              }
              if (row.type=='transfer') {
                this.viewTransferProgress(row.name, row.size, row.totalSize);
                return;
              }
            }
          }
        },
      },
      {
        sortable: true,
        title: 'Size',
        id: 'size',
        key: 'size',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.fileHandlerService.formatFileSize(row.size) + ((row.type=="transfer") ? "/" + this.fileHandlerService.formatFileSize(row.totalSize) : '');
          },
        },
      },
      {
        sortable: true,
        title: 'Date Modified',
        id: 'lastModifiedTime',
        key: 'lastModifiedTime',
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            if (!row?.lastModifiedTime) return '';
            //need to do this because safari doesn't support the - or . in the dateformat.
            var dateVal=row.lastModifiedTime;
            dateVal=dateVal.replace(/-/g, "/");
            //strip off decimal on the time if it exists.
            if (dateVal.indexOf('.')>0) {
              dateVal=dateVal.substr(0, dateVal.indexOf('.')); 
            }
            dateVal=dateVal.toLocaleString();
            return dateVal;   //test.toLocaleString(); //row.lastModifiedTime.toLocaleString();
          },
        },
      },

      {
        title: 'Actions',
        key: 'actionsIcons',
        cellDisplay: {
          preset: EPresetOptions.ACTIONS_ICONS,
          actionsIcons: {
            iconsOrder: ['view', 'download', 'delete'],
            view: {
              id: 'view',
              tooltip: {
                text: 'View File',
              },
              disabled: (row: any, column: Column) => {
                //currently only allow view/download for files <4MB
                return (row.size > 4194304 || row.type=='error' || row.type=='transfer');
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                //this.viewFile(row.name);
                this.viewFile(row.name);
                return;
              },
            },
            download: {
              id: 'download',
              tooltip: {
                text: 'Download File',
              },
              customSvgIcon: 'download',
              disabled: (row: any, column: Column) => {
                //currently only allow view/download for files <4MB
                return (row.size > 4194304 || row.type=='error' || row.type=='transfer');
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                this.downloadFile(row.name);
                return;
              },
            },
            delete: {
              id: 'delete',
              tooltip: {
                text: 'Delete File',
              },
              disabled: (row: any, column: Column) => {
                //currently only allow view/download for files <4MB
                return (row.type=='transfer');
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                this.deleteFile(row.name, row.type);
                return;
              },
            },
          },
        },
      },
    ],
  };

  // sync paginator
  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
  };

  selectedTabIndex = 0;
  fileUploadInputFor: any;
  fileAlias: string;

  file: string;
  id: string;

  httpUrl = '';

  info: any = {};

  
  deployment:DeploymentObject;
  deploymentId:string;
  

  constructor(
    private topbarControlService: TopbarControlService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private deploymentsService: DeploymentsService,
    private notificationService: NotificationService,
    private http: HttpClient,
    private tableService: TableService,
    private sqlQueryService: SQLQueryService,
    private fileHandlerService: FileHandlerService,
    private externalObjectStorageService: ExternalObjectStorageService,
    private spinnerService: SpinnerService,
    private sharedService: SharedService,
    private iccaCommonService: IccaCommonService,
  ) {
      if (this.route.snapshot.data.route=='filemanagement') {
        this.fileManagement=true;
        this.tableConfig.header= {
          title: 'Files added to the deployment',
          titleTooltip: {
            text: '',
          },
        };
      } else {
        this.fileManagement=false;
        this.tableConfig.header= {
          title: 'Select file(s) to import',
          
        };

      }
      
  }

  ngOnInit() {

    this.deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    //this.sub.add(this.deploymentsService.deployment$(this.deploymentId).subscribe(deployment => 
    this.deploymentsService.deployment$(this.deploymentId).pipe(take(1)).subscribe(deployment => 
        {
        if (deployment) {
          this.deployment=deployment;
          
          if (deployment.deploymenttype=='doc') {
            this.acceptFilter='.csv, .ddl, .dml, .json, .sql, .txt';
  
          }
          
          this.sub.add(this.iccaCommonService.loadDeploymentInfo$(deployment.deploymenttype,this.deploymentId, deployment.region).subscribe());
          this.getFiles();
          this.setInfoObject();
      
        }
      },
         //lambda will return 404 if deployment is deleted
      err =>{
        if (err?.status==404) {
          this.sharedService.showAlert('Deployment not found');
          this.router.navigate(['/deployments']);

        }
        else {
          this.sharedService.showAlert('Error getting deployment info: ' + err.error.error);
        }     
      }
    );
      //));

    

    
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }

  getFiles(): void {
    this.tableConfig.noDataMessage = 'Loading files...';
    this.files = [];
    this.selectedFilename='';
    this.sub.add(this.fileHandlerService.getFiles(this.deployment.deploymenttype,this.deploymentId, this.deployment.region)
    .pipe(
      repeatWhen(obs => obs.pipe(delay(5000))),
      takeWhile(
        files => {
          this.rowsChecked = [];
          if (this.fileManagement) {
            this.tableConfig.noDataMessage = 'No files have been added.  Click Upload or External transfer to add files.';
          } else {
            this.tableConfig.noDataMessage = 'No files have been added.';
          }
          files.files.map(function (obj) {return obj.type='file';});
          this.files=files.files;
          //if on import screen just load files that have been successfully transfered
          if (!this.fileManagement) return false;
         
          files?.transfers?.map(function (obj) {return obj.type='transfer';});
          files?.transferErrors?.map(function (obj) {return obj.type='error';});
          this.files.push.apply(this.files, files?.transfers);
          this.files.push.apply(this.files, files?.transferErrors);
          return (files?.transfers?.length>0);
      })
    ).subscribe());
  }

  updateLabel(): void {
    switch (this.importType) {
      case 'importDDL':
        this.tableConfig.header.title = 'Select file(s) to import';
        this.tableConfig.columns[0].cellDisplay.checkbox.hideCheckAllBoxes=false;
        break;
      case 'importCSV':
        this.tableConfig.header.title = 'Select a file to import';
        this.tableConfig.columns[0].cellDisplay.checkbox.hideCheckAllBoxes=true;
        break;
    }
  }

  onFileSelect(rows,isCheck, allRowsChecked) {
    switch (this.importType) {
      case 'importCSV':
        var row=rows[0];
        var select=row.select;
        
        this.files.forEach((item: any) => (item.select = false));
        row.select = select;
        if (select) {
          this.selectedFilename = row.name;
        } else {
          this.selectedFilename = '';
        }
        break;
      
      case 'importDDL': 
        //var select=row.select;

        //update the this.rowsChecked array with rows that are currently selected
        let index: number;
        //if (select) {
        if (isCheck) {
            rows.forEach(row => {
              index = this.rowsChecked.findIndex(checkedrow => checkedrow.name === row.name);
              if (index == -1) {
                this.rowsChecked.push(row);
            }
          });
        } else {
          rows.forEach(row => {
            index = this.rowsChecked.findIndex(checkedrow => checkedrow.name === row.name);
            if (index > -1) {
              this.rowsChecked.splice(index, 1);
            }
          });
        }
        break;
    } 

  }

  deleteFile(filename: string, type:'file' | 'error' | 'transfer'): void {
    // Create a dialogConfig
    const dialogConfig = new MatDialogConfig();

    //this.confirmationDialogConfig.primary="You are about to delete '" + filename + "' from the server. Are you sure?";
    dialogConfig.data = {
      title: 'Delete File',
      primary: "You are about to delete '" + filename + "' from the server. Are you sure?",
      buttons: {
        primary: {
          text: 'Delete File',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };

    dialogConfig.panelClass = 'fr-layout-wrapper-mat-dialog-panel';

    // Use the open() API to instantiate the modal
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    // Subscribe to the dialog's afterclosed() API to get the response
    this.sub.add(dialogRef.afterClosed().subscribe(response => {
      if (response && response.button) {
        switch (response.button) {
          case 'primary':
            const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
            const deployment: DeploymentObject = this.deploymentsService.findDeployment(
              this.deploymentsService.deployments,
              deploymentId,
            );
            this.fileHandlerService.deleteFile(deployment.deploymenttype,deployment.deploymentid, deployment.region, filename, type).subscribe((response: any) => {
              this.notificationService.showInfo(response.log, 2000);
              this.getFiles();
            });
            break;
          case 'secondary':
            console.log(`User confirmed secondary`);
            break;
          case 'tertiary':
            console.log(`User confirmed tertiary`);
            break;
        }
        if (response.checked) {
          console.log(`User checked the checkbox`);
        }
      }
    }));
  }

  downloadFile(filename): void {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    this.sub.add(this.fileHandlerService.getFile(deployment.deploymenttype, deployment.deploymentid, deployment.region, filename, 'uploads', 'download').subscribe());
  }

  importFiles() {
    //import all files
    let filename = '';

    const filelist: string[] = [];

    this.rowsChecked.forEach(function (value) {
      filename = value.name;
      filelist.push(filename);
    });
    this.responseTotal = filelist.length;
    this.responseCt = 0;

    // Create a dialogConfig
    const dialogConfig = new MatDialogConfig();

    const dialogText = `Are you sure you wish to import and run all selected ${this.ddlType} DDL/DML files?`;

    dialogConfig.data = {
      title: 'Loading',
      primary: dialogText,
      buttons: {
        primary: {
          text: 'Import',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };
    dialogConfig.panelClass = 'fr-layout-wrapper-mat-dialog-panel';

    // Use the open() API to instantiate the modal
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    // Subscribe to the dialog's afterclosed() API to get the response
    this.sub.add(dialogRef.afterClosed().subscribe(response => {
      if (response && response.button) {
        switch (response.button) {
          case 'primary':
            this.response = [];
            filelist.forEach(file => {
              this.importDDLFile(file);
            });

            break;
          case 'secondary':
            console.log(`User confirmed secondary`);
            break;
          case 'tertiary':
            console.log(`User confirmed tertiary`);
            break;
        }
        if (response.checked) {
          console.log(`User checked the checkbox`);
        }
      }
    }));
  }

  importDDLFile(filename) {
    //var filenameJSON = `{"filename": "` + filename + `"}`;
    this.loadInProgress = true;
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    const errorFile=`/irissys/data/errors/${filename.replace(/\.[^/.]+$/, "")}_Errors.log`;
    const sql = `CALL IRISCloud.SQLUtils_ImportDDL('/irissys/data/uploads/${filename}','${errorFile}','${this.ddlType}')`
    
    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment, sql).subscribe(
      response => {
        if (response['error']) {
          console.log(response);
          response['error'] = response['error'].split('java.sql.SQLException: ')[1];
        } else {
            response['error'] = "File imported";
        }
        
      this.response.push({
        fileName: filename,
        output: response['error'],
      });
      this.notificationService.showInfo('Import complete: ' + filename, 2000);

      this.responseCt++;
      if (this.responseCt == this.responseTotal) {
        this.loadInProgress = false;
        this.fileEvents.emit('imported');
      }
    }));
  }

  setInfoObject() {
    
    if (this.fileManagement) {
      this.info = {
        infoTitle: 'Add and manage files',
        htmlText: "The files on this page can be imported to your deployment on the Import Files page. \
        The currently listed files were previously added; to see recently added files, use <b>Refresh</b>. \
        To add more files, you can: \
        <br>\
        <ul> \
        <li> Use <b>Upload</b> to browse for and upload local files. Files larger than 4MB cannot be uploaded.</li> \
        <li> Use <b>External transfer</b> to list and select from the files stored in associated \
        <a href='https://docs.aws.amazon.com/AmazonS3/latest/userguide/Welcome.html'  target='_blank'>AWS S3 buckets</a> \
        and to configure access to additional buckets.</li>\
        </ul>\
        <a href='https://docs.intersystems.com/components/csp/docbook/DocBook.UI.Page.cls?KEY=ADRIVE'  target='_blank'>Programmatic connections</a> \
        to Cloud SQL can access uploaded and transferred files listed here using the path <b>/irissys/data/uploads/<i>filename</i></b>."
        };
      
      this.tableConfig.columns.splice(0,1);

    } else {
      this.info = {
        infoTitle: 'Select files',
        htmlText: "You can import multiple DDL/DML files or a single CSV file. The listed files were previously \
        added by users of this deployment. To see recently added files, use <b>Refresh</b>. To add files, go to the \
        Manage Files page. \
        <br><br>\
        <a href='https://docs.intersystems.com/components/csp/docbook/DocBook.UI.Page.cls?KEY=ADRIVE'  target='_blank'>Programmatic connections</a> \
        to Cloud SQL can access uploaded and transferred files listed here using the path <b>/irissys/data/uploads/<i>filename</i></b>."
        };
    }
  }
  

  openFileViewer(filename: string) {
    const modalData: IscFormModalData = {
      modalTitle: 'File Viewer',
      iscFormInputs: {
        Id: 'fileViewer',
        FDN: {
          //name: 'File',
          description: '',
          sectionLayout: { showSectionHeaders: false },
          sections: [
            {
              fields: [
                {
                  key: 'sample-textarea',
                  type: 'input', //"textarea",
                  hideExpression: '',
                  hide: null,
                  expressionProperties: null,
                  className: '',
                  data: {
                    content: this.fileData,
                    //"hint": ""
                  },
                  id: 'sampleText',
                  templateOptions: {
                    labelComponent: IccaFileViewerComponent,
                  },
                },
              ],
            },
          ],
        },
        formModel: {
          Name: '',
          Size: '',
        },
        mode: FormModes.VIEW,
        formConfig: {},
        buttons: [
          {
            id: 'close',
            text: 'Close',
            buttonClass: 'primary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
              dialogRef.close();
            },
          },
        ],
      },
    };

    modalData.modalTitle = `${filename}`;

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(IscFormModalComponent, {
      panelClass: 'isc-form-modal',
      data: modalData,
    });
  }

  viewFile(filename): void {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    this.fileHandlerService.getFile(deployment.deploymenttype, deployment.deploymentid, deployment.region, filename,'uploads', 'view').subscribe(fileData => {
      const reader = new FileReader();
      reader.onloadend = e => {
        this.fileData = reader.result;
        this.openFileViewerModal({ filename: filename, fileData: fileData }); //,this.onModalSubmit.bind(this));
      };
      reader.readAsText(fileData.body);
    });
  }
  viewErrorFile(filename): void {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    this.fileHandlerService.getFile(deployment.deploymenttype, deployment.deploymentid, deployment.region, filename,'transferError', 'view').subscribe(fileData => {
      const reader = new FileReader();
      reader.onloadend = e => {
        this.fileData = reader.result;
        this.openFileViewerModal({ filename: `Error transferring ${filename}`, fileData: fileData }); //,this.onModalSubmit.bind(this));
      };
      reader.readAsText(fileData.body);
    });
  }

  viewTransferProgress(filename, size, totalSize): void {
    var fileData = `File transfer in progress: ${filename} ${this.fileHandlerService.formatFileSize(size)}/${this.fileHandlerService.formatFileSize(totalSize)}`;
    this.openFileViewerModal({ filename: `File transfer in progress ${filename}`, fileData: fileData}); 
  }

  launchFileInput(): void {
    this.fileInput.nativeElement.click();
  }

  fileHandler(files: FileList): void {
    const uploadCount = files.length;
    let errorCount = 0;
    let completeCount = 0;
    this.loadInProgress = true;

    while (files.length > uploadCount) {
      this.uploadProgress.push(0);
    }

    // tslint:disable-next-line: prefer-for-of we need the index to update the file array.
    for (let i = 0; i < uploadCount; i++) {
      //check file size, only allow files <4MB
      if (files.item(i).size > 4194304) {
        ++errorCount;
        this.notificationService.showWarning(
          `Cannot upload files greater than 4MB. Use external transfer instead. ${files.item(i).name}`,
          7000,
        );

        if (++completeCount === uploadCount) {
          this.loadInProgress = false;
          this.notificationService.showInfo(
            `${uploadCount - errorCount} File${uploadCount - errorCount > 1 ? 's' : ''} Uploaded`,
            7000,
          );
          this.getFiles();
        }
      } else {
        const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
        const deployment: DeploymentObject = this.deploymentsService.findDeployment(
          this.deploymentsService.deployments,
          deploymentId,
        );
        this.fileHandlerService.uploadFile(deployment.deploymenttype,deployment.deploymentid, deployment.region, files.item(i)).subscribe(
          event => {
            if (event['type'] === HttpEventType.UploadProgress) {
              this.uploadProgress[i] = Math.round((100 * event['loaded']) / event['total']);
            } else if (event instanceof HttpResponse) {
              this.currentFiles = this.currentFiles.filter(file => file != files.item(i).name);
              this.currentFiles.push(files.item(i).name);
            }
          },
          error => {
            ++errorCount;
            this.notificationService.showWarning(`Error uploading file: ${files.item(i).name}`, 7000);
          },
          () => {
            if (++completeCount === uploadCount) {
              this.loadInProgress = false;
              this.notificationService.showInfo(
                `${uploadCount - errorCount} File${uploadCount - errorCount > 1 ? 's' : ''} Uploaded`,
                7000,
              );
              this.getFiles();
            }
          },
        );
      }
    }
  }

  
  expandTransferSection(element: HTMLElement):void {
    element.scrollIntoView();
    this.expandSection=true; 
  }


  launchExternalObjectInput() {
    //cloning so changes to modal doesn't change text in overview form until it's saved
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    const data: any = {
      bucketForm: deployment,
      objectsToTransfer:[],
    };
   
    this.openExternalObjectStorageModal(data, this.onExternalObjectStorageModalSubmit.bind(this));
  }

  async onExternalObjectStorageModalSubmit(formModel: any, form: any, dialogRef: MatDialogRef<any, any>) {

    formModel = form.value.bucketForm;
    //ensure form is valid
    if (!formModel || !formModel.objectsToTransfer ) {
      this.notificationService.showAlert('No objects selected to transfer', 7000);
      return;
    }
    if (!formModel.objectsToTransfer.rowsChecked || !formModel.objectsToTransfer.selectedBucket) {
      return;
    }
    if (form.valid) {
        //const objectsToTransfer:ExternalStorageObjectList;
        formModel.objectsToTransfer.rowsChecked.forEach(row => {
          var object:ExternalStorageObject = {
            deploymentId: this.deployment.deploymentid,
            objectKey: row.key,
            bucketArn: formModel.objectsToTransfer.selectedBucket.bucketArn,
            region: formModel.objectsToTransfer.selectedBucket.region
          }
          //keep when we support passing array of objects to transfer
          //objectsToTransfer.objects.push(object)
          //this.notificationService.showAlert(`TODO - Transfer object: ${JSON.stringify(object)}`, 7000);
          
          try {
            this.externalObjectStorageService.transferObject$(this.deployment.deploymenttype, 
              this.deployment.region,
              object).subscribe((response: any) => {
              this.spinnerService.popStack();
              this.sharedService.showSuccess(`${object.objectKey} transfer started.`);
              this.refreshToken$.next();
              this.getFiles();
            });
          } catch (error) {
            const msg = 'Something went wrong. Please contact InterSystems Support.';
            this.notificationService.showAlert(msg, 7000);
          }
          
        });
        dialogRef.close();
    }
  }

  openExternalObjectStorageModal(data:any, callback): void {
      const modalData: IscFormModalData = {
          modalTitle: 'Transfer files from external storage',
          iscFormInputs: {
          Id: 'external-storage-dialog',
          
          FDN: {
            name: '',
            validateOn: 'change',
            sectionLayout: { showSectionHeaders: false },
            sections: [
                {
                fields: [
                  {
                    id: 'bucketForm',
                    key: 'bucketForm',
                    //type: 'instructions',
                    type: 'input',
                    templateOptions: {
                      label: 'Transfer from external storage',
                    },
                    data: {
                      displayField: 'name',
                      uniqueValueField: 'name',
                      optionIdField: 'name',
                    },
                    wrappers: ['external-object-storage-wrapper'],
                  },
                ]
              }
            ]
          }, 
          formModel:  data,
          mode: FormModes.EDIT,
          formConfig: {},
          buttons: [
            {
            id: 'cancel',
            text: 'Cancel',
            buttonClass: 'tertiary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
            {
            id: 'transfer',
            text: 'Transfer',
            buttonClass: 'primary',
            disabledIfFormInvalid: true,
            //disabledIfFormPristine: true,
            
            type: 'submit',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
              callback(formModel, form, dialogRef);
              }
            },
          ]
          }
      }
      const dialogRef: MatDialogRef<any, any> = this.dialog.open(
        IccaExternalObjectStorageDialogComponent,
          {
              panelClass: 'isc-form-modal',
              data: modalData,
              autoFocus: false,
              position: {left: '250px'},
          }
      );
    }
    
    routeToFileManagement(deploymentId) {
      this.router.navigate(['/deployments', deploymentId, 'filemanagement']);
    }

    openFileViewerModal(data:any): void {
      const modalData: IscFormModalData = {
          modalTitle: data.filename, 
          iscFormInputs: {
            Id: 'fileViewer',
          
            FDN: {
              name: '',
              //description: 'Transfer files from S3 bucket. If bucket cannot be accessed, a policy will be provided to add to your Bucket policy',
              validateOn: 'change',
              sectionLayout: { showSectionHeaders: false },
              sections: [
                  {
                  fields: [
                    {
                      id: 'fileData',
                      key: 'fileData',
                      type: 'instructions',
                      templateOptions: {
                        label: '',
                      },
                      data: {
                        content: data.fileData,
                      },
                      wrappers: ['file-viewer-wrapper'],
                    },
                  ]
                }
              ]
            }, 
            formModel:  data,
            mode: FormModes.EDIT,
            formConfig: {},
            buttons: [
              {
              id: 'close',
              text: 'Close',
              buttonClass: 'primary',
              type: 'button',
              callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                  dialogRef.close();
              }
              },
            ]
          }
      }
  
      const dialogRef: MatDialogRef<any, any> = this.dialog.open(
        GenericDialogComponent,
          {
              panelClass: 'isc-form-modal',
              data: modalData,
              autoFocus: false
          }
      );
    }



}
