import { Component } from '@angular/core';
import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { SharedService } from 'src/app/shared/services/shared.service';
import { AdministrationService } from '../administration.service';

@Component({
  selector: 'app-sysinfo-page',
  templateUrl: './sysinfo-page.component.html',
  styleUrls: ['./sysinfo-page.component.scss'],
})
export class SysinfoPageComponent {
  constructor(private administrationService: AdministrationService, private sharedService: SharedService) {}
  versionData$ = this.administrationService.getVersionData$();
  launchResources$ = this.administrationService.getLaunchResources$();
  _codes;
}
