import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-tenant-switcher',
  templateUrl: './tenant-switcher.component.html',
  styleUrls: [`./tenant-switcher.component.scss`],
})
export class TenantSwitcherComponent {
  @Input() userTenantsOptions: any;
  @Input() currentTenantId: any;

  @Output() switchTenantId = new EventEmitter<string>();
}
