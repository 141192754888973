import { Component } from "@angular/core";
import { Field } from '@intersystems/isc-form';

@Component({
  selector: "app-isc-form-field-info-label-icon",
  templateUrl: './isc-form-field-info-label-icon.component.html',
  styleUrls: ['./isc-form-field-info-label-icon.component.scss'],
  })
export class IscFormFieldInfoLabelIconComponent {
  // supplied by the component resolver in isc-form at time of instantiation
  field: Field; // contains the reactive formControl object as well
  formState: any;
  model: any;

  constructor() {}
}
