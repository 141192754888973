import { Component } from '@angular/core';
import { Field } from '@intersystems/isc-form';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-isc-form-section-toggle-wrapper',
  templateUrl: './isc-form-section-toggle-wrapper.component.html',
  styleUrls: ['./isc-form-section-toggle-wrapper.component.scss'],
})
export class IscFormSectionToggleWrapperComponent extends FieldWrapper {
  field: Field;

  constructor() {
    super();
  }

  toggle(): void {
    const expanded = (this.field && this.field.formControl.value) || false;

    if (expanded) {
      this.field.formControl.setValue(false);
    } else if (!expanded) {
      this.field.formControl.setValue(true);
    }
  }
}
