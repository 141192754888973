import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeploymentObject } from 'api';

@Component({
  selector: 'app-tenant-deployments-list',
  templateUrl: './deployments-list.component.html',
  styleUrls: ['./deployments-list.component.scss'],
})
export class DeploymentsListComponent {
  @Input() deployments: DeploymentObject[] = [];
  @Input() canDeleteDeployment = true;
  @Input() currentTenantData: any;

  @Output() deleted = new EventEmitter<string>();
  @Output() selected = new EventEmitter<string>();
}
