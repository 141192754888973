<div class="wrapper">
  <mat-checkbox [id]="field.id" *ngIf="field.type=='binary-checkbox'" [(ngModel)]="model[field.key]"
    (change)="fieldChange($event,field.key)">
    {{field.templateOptions.label}}
    <app-info-button [htmlText]="field.hintText" [title]="infoBoxTitle" [buttonName]="field.hintId ?? 'hintId'"></app-info-button>
  </mat-checkbox>
  <mat-label *ngIf="field.type=='instructions'" class="instruction-label"> {{field.templateOptions.label}}
    <app-info-button [htmlText]="field.hintText" [title]="infoBoxTitle" [buttonName]="field.hintId ?? 'hintId'"></app-info-button>
  </mat-label>

  <div *ngIf="((field.type=='input')||(field.type=='number'))" class="input-field-container">
    <mat-label class="input-label">{{field.templateOptions.label}} <span style="color: red">*</span>
      <app-info-button [htmlText]="field.hintText" [title]="infoBoxTitle" [buttonName]="field.hintId ?? 'hintId'"></app-info-button>
    </mat-label>
    <mat-form-field id="col-separator-field">
      <input [id]="field.id" [(ngModel)]="model[field.key]" matInput [type]="inputType"
        (change)="fieldChange($event,field.key)" [value]="model[field.key]" required [formControl]="field.formControl"
        min="1" [placeholder]="inputPlaceholder">
      <mat-error *ngIf="field.formControl.invalid">{{getErrorMessage()}}</mat-error>
    </mat-form-field>
  </div>

  <div *ngIf="field.type=='radio'" class="radio-container">
    <mat-label class="input-label">{{fieldLabel}} <span style="color: red">*</span>
      <app-info-button [htmlText]="field.hintText" [title]="infoBoxTitle" [buttonName]="field.hintId ?? 'hintId'"></app-info-button>
    </mat-label>
    <ng-container #fieldComponent></ng-container>
  </div>

  <div *ngIf="field.type=='select'">
    <mat-label class="input-label">{{fieldLabel}} <span style="color: red">*</span>
      <app-info-button [htmlText]="field.hintText" [title]="infoBoxTitle" [buttonName]="field.hintId ?? 'hintId'"></app-info-button>
    </mat-label>
    <ng-container #fieldComponent></ng-container>
  </div>
</div>