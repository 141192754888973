import { Component, OnDestroy, OnInit } from '@angular/core';
import { AdministrationService } from '../administration.service';
import { SharedService } from '../../shared/services/shared.service';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { TenantsService } from 'api';
import { OffersService } from 'admin-api';
import { MatDialog } from '@angular/material/dialog';
import { ManageOffersDialogComponent } from './dialogs/manage-offers/manage-offers-dialog.component';

@Component({
  selector: 'app-tenants-page',
  templateUrl: './tenants-page.component.html',
  styleUrls: ['./tenants-page.component.scss'],
})
export class TenantsPageComponent implements OnInit, OnDestroy {
  tenants: Observable<{ tenantId: string; tenantName: string }[]>;

  private sub = new Subscription();

  constructor(
    private administrationService: AdministrationService,
    private tenantsService: TenantsService,
    private offersService: OffersService,
    private sharedService: SharedService,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getTenants();
  }

  getTenants(): void {
    this.tenants = this.administrationService.users$().pipe(
      map(users => {
        let tenants: { tenantId: string; tenantName: string }[] = [];
        // map users and fill array of tenants by all exists tenants
        users.map(user => {
          Object.keys(user.tenants).map(tenantId => {
            tenants.push({
              tenantId: tenantId,
              tenantName: user.tenants[tenantId].name,
            });
          });
        });

        // remove duplicate objects in array
        tenants = tenants.filter((value, index, self) => index === self.findIndex(t => t.tenantId === value.tenantId));
        return tenants;
      }),
    );
  }

  manageTenantOffers(tenantId: string): void {
    this.sub.add(
      this.tenantsService
        .getTenant(tenantId)
        .pipe(
          catchError(() => of({ offers: [] })), // TODO: remove when we have admin access to tenant data
          map(tenant => tenant.offers),
          switchMap(tenantOffers => {
            const dialogRef = this.dialog.open(ManageOffersDialogComponent, {
              data: {
                tenantOffers,
              },
            });

            return dialogRef.afterClosed().pipe(
              filter(result => result),
              switchMap(formData => {
                if (formData.action === 'disable') {
                  return this.offersService.disableOffer({}, tenantId, formData.offerId).pipe(
                    tap(
                      () => this.sharedService.showSuccess(`Success! Offer ${formData.offerId} disabled`),
                      () => this.sharedService.showAlert(`Something went wrong. Please, try again later.`),
                    ),
                  );
                }

                if (formData.action === 'enable') {
                  if (Object.keys(tenantOffers).map(offerId => offerId === formData)) {
                    return this.offersService.enableOffer({}, tenantId, formData.offerId).pipe(
                      tap(
                        () => this.sharedService.showSuccess(`Success! Offer ${formData.offerId} enabled`),
                        () => this.sharedService.showAlert(`Something went wrong. Please, try again later.`),
                      ),
                    );
                  }
                }
              }),
            );
          }),
        )
        .subscribe(),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
}
