import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { catchError, pluck, map, tap } from 'rxjs/operators';

import { NotificationService } from '@intersystems/notification';

import { ConnectionInfo, QueryHistory, QueryOptions, SQLResponse } from '../../icca-common/model/sql-response'; 
import { Schema } from '../model/schema';
import { NOTIFICATION_TYPE } from '@intersystems/notification';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { query } from '@angular/animations';
import { IRISAuthService } from '../../icca-common/components/iris-login/iris-auth.service'; 
import { DeploymentObject } from 'api';
import { IccaCommonService } from '../../icca-common/services/icca-common.service';


@Injectable({
  providedIn: 'root'
})
export class SQLQueryService {

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private route:ActivatedRoute,
    private irisAuthService: IRISAuthService,
    private iccaCommonService: IccaCommonService,
  ) {}

  
  httpOptions = {
    headers: new HttpHeaders({ 
      'accept': 'text/plain; charset=utf-8',
      'content-type': 'application/json'      
    }),
    //responseType: 'text'
  };

  public sQLQueryString:string = '';

  public sQLResponse:SQLResponse;

  public queryHistory:QueryHistory[] = [];
  public historyCursor:number=-1;
  
  //used to preserve paginator size after issuing another query
  public currentPageSize:number = 10;
    
  /** Call into JDBC Lambda container with SQL STATEMENT */
  executeSQLStatement(deployment:DeploymentObject, sql: string, connectionOptions?:ConnectionInfo): Observable<SQLResponse[]> {
    
    //var sqlObject={"sql": sql};
    //setting high values for non-user initiatied queries
    //if ((!showNotification)&&(!connectionOptions)) {
    //  connectionOptions={networkTimeout:"30000"};
    // }
    var connectionObject={
      ...this.irisAuthService.getIRISConnectionInfo(deployment.deploymentid, deployment.deploymenttype),
      ...connectionOptions,
    };
    var sqlObject;
    
    sqlObject = {
      deploymentId : deployment.deploymentid,
      "sql": {
        query: sql,
        maxRows:"100000",
        maxLength:"4000000"     
      },
      "connection": connectionObject
    };
      
       
    var strSqlValJSON=JSON.stringify(sqlObject);
    const url = `${this.iccaCommonService.getAPIURL(deployment.deploymenttype)}/sql/query`;
      
    
    return this.http.post<SQLResponse[]>(url,strSqlValJSON,this.httpOptions)
      .pipe(
        catchError(this.handleError<SQLResponse[]>(`SQL Query`, []))
      );
      
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`QueryService: ${operation} failed: ${error.message}`,NOTIFICATION_TYPE.ALERT);
      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a QueryService message with the NotificationService */
  public log(message: string, type:NOTIFICATION_TYPE, showNotification:boolean = true) {
    if (showNotification) {
      this.notificationService.show(type,`${message}`, 7000);
    }
  }

  setSQLQuery(sql:string)
  {
    this.sQLQueryString=sql;
  }
  getSQLQuery() {
    return this.sQLQueryString;
  }

  setSQLResponse(response:SQLResponse) {
    this.sQLResponse=response;
  }
  getSQLResponse(){
    return this.sQLResponse;
  }


  setqueryHistory(query:QueryHistory)
  {
    //search to see if it exists, if it does move it to the end
    const index=this.queryHistory.findIndex(item => item.sqlStatement===query.sqlStatement);
    if (index<0) {
      query.count=1;
      query.lastExecuted=new Date();
      this.queryHistory.push(query);
      this.historyCursor=this.queryHistory.length-1;
    } else {
      this.queryHistory[index].count++; 
      this.queryHistory[index].lastExecuted=new Date();
      this.historyCursor=index;
    }
  }

  getQueryHistory(direction:number):QueryHistory {
    var newindex=this.historyCursor+direction;
    if (newindex<=0) {
      newindex=0;
    } else if (newindex>(this.queryHistory.length-1)) {
      newindex=this.queryHistory.length-1;
    }
    this.historyCursor=newindex;
    
    return this.queryHistory[newindex];
  }

  

 





}
