import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  supportLinks = [
    {
      title: 'Notices/Terms',
      url: 'https://www.intersystems.com/noticesterms-conditions/',
    },
    {
      title: 'Privacy Statement',
      url: 'https://www.intersystems.com/privacy-policy/',
    },
    {
      title: 'Documentation',
      url: 'https://docs.intersystems.com/services/csp/docbook/DocBook.UI.Page.cls',
    },
    {
      title: 'Learning',
      url: 'https://learning.intersystems.com/?ssoPass=1',
    },
    { title: 'Community', url: 'https://community.intersystems.com/' },
    {
      title: 'Support',
      url: 'https://www.intersystems.com/support-learning/support/',
    },
  ];
}
