import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DeploymentsPageComponent } from './deployments/deployments-page.component';
import { SysinfoPageComponent } from './sysinfo/sysinfo-page.component';
import { UsersPageComponent } from './users/users-page.component';
import { TenantsPageComponent } from './tenants/tenants-page.component';
import { SitrepsPageComponent } from './sitreps/sitreps-page.component';
import { PerformanceProfilesPageComponent } from './performanceprofiles/performanceprofiles-page.component';

export const routes: Routes = [
  {
    path: 'sysinfo',
    component: SysinfoPageComponent,
    data: {
      caption: 'System Info',
    },
  },
  {
    path: 'sitreps',
    component: SitrepsPageComponent,
    data: {
      caption: 'Service Desk',
    },
  },
  {
    path: 'allusers',
    component: UsersPageComponent,
    data: {
      caption: 'Users',
    },
  },
  {
    path: 'alltenants',
    component: TenantsPageComponent,
    data: {
      caption: 'Tenants',
    },
  },
  {
    path: 'alldeployments',
    component: DeploymentsPageComponent,
    data: {
      caption: 'Deployments',
    },
  },
  {
    path: 'performanceprofiles',
    component: PerformanceProfilesPageComponent,
    data: {
      caption: 'Performance Profiles',
    },
  },
  {
    path: '',
    redirectTo: 'sysinfo',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministrationRoutingModule {}
