import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../shared/services/shared.service';
import { AuthService } from '../../core/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { filter, map, tap } from 'rxjs/operators';
import { ServiceSettingsComponent } from './dialogs/service-settings.component';
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from '@intersystems/confirmation-dialog';
import { SUBSCRIPTIONS_DATA } from 'src/app/shared/subscriptions-data';

@Component({
  selector: 'app-services-calculator',
  templateUrl: './services-calculator.component.html',
  styleUrls: ['./services-calculator.component.scss'],
})
export class ServicesCalculatorComponent implements OnInit {
  public user;
  public inactiveSubscriptions = [];
  public addedServices = [];
  public summaryCost = {
    perHour: 0,
    perMonth: 0,
    perYear: 0,
  };

  formatting_options = {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  };
  locale_string = 'en-US';

  constructor(private authService: AuthService, private sharedService: SharedService, private dialog: MatDialog) {}

  ngOnInit(): void {
    this.user = this.authService.user;
    //this.inactiveSubscriptions = [...Services];
    this.inactiveSubscriptions = [...SUBSCRIPTIONS_DATA]
    this.addService();
  }

  addService(): void {
    const dialogRef = this.dialog.open(ServiceSettingsComponent, {
      data: { inactiveSubscriptions: this.inactiveSubscriptions },
      maxHeight: 800,
      maxWidth: 1300,
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result),
        tap(service => {
          let calculatedCost;
          if (service.type === 'fhiraas') {
            calculatedCost = this.calculateFhirPrice(service);
          }

          if (service.type === 'hmts') {
            calculatedCost = this.calculateRequestsSummary(service.requests, service.unit, service.type);
          }

          if (service.type === 'hcc') {
            calculatedCost = this.calculateRequestsSummary(service.requests, service.unit, service.type);
          }

          if (service.type === 'sqlaas') {
            calculatedCost = this.calculateSqlaasPrice(service);
          }

          this.summaryCost.perHour += calculatedCost.perHour;
          this.summaryCost.perMonth += calculatedCost.perMonth;
          this.summaryCost.perYear += calculatedCost.perYear;

          calculatedCost.perHour = this.formatNumberToUsd(calculatedCost.perHour);
          calculatedCost.perMonth = this.formatNumberToUsd(calculatedCost.perMonth);
          calculatedCost.perYear = this.formatNumberToUsd(calculatedCost.perYear);

          const totalI = this.addedServices.findIndex(i => i.type === 'Total');
          if (totalI) {
            this.addedServices.splice(totalI, 1);
          }
          service.type=this.formatServiceType(service);
          this.addedServices = [
            ...this.addedServices,
            { ...service, ...calculatedCost },
            {
              type: 'Total',
              perHour: this.formatNumberToUsd(this.summaryCost.perHour),
              perMonth: this.formatNumberToUsd(this.summaryCost.perMonth),
              perYear: this.formatNumberToUsd(this.summaryCost.perYear),
            },
          ];
        }),
      )
      .subscribe();
  }

  calculateRequestsSummary(
    requests: number,
    unit: string,
    type: string,
  ): { perHour: number; perMonth: number; perYear: number } {
    const requestsSummary = {
      requestsByHour: 0,
      requestsByMonth: 0,
      requestsByYear: 0,
    };
    const costSummary = {
      perHour: 0,
      perMonth: 0,
      perYear: 0,
    };

    switch (unit) {
      case 'hour':
        requestsSummary.requestsByMonth = requests * 24 * 30;
        break;
      case 'day':
        requestsSummary.requestsByMonth = requests * 30;
        break;
      case 'month':
        requestsSummary.requestsByMonth = requests;
        break;
    }

    if (type === 'hmts') {
      if (requestsSummary.requestsByMonth > 1000000) {
        const requestsCost = (requestsSummary.requestsByMonth - 1000000) * 0.001;
        costSummary.perHour = requestsCost / 30 / 24;
        costSummary.perMonth = requestsCost;
        costSummary.perYear = requestsCost * 12;
      }
    }

    if (type === 'hcc') {
      if (requestsSummary.requestsByMonth > 80000000) {
        this.sharedService.showAlert('The amount of requests must not be more than 80 million per month');
      } else {
        if (requestsSummary.requestsByMonth <= 5000000) {
          costSummary.perMonth = 6600;
          costSummary.perHour = costSummary.perMonth / 30 / 24;
          costSummary.perYear = costSummary.perMonth * 12;
        }

        if (requestsSummary.requestsByMonth > 5000000 && requestsSummary.requestsByMonth <= 60000000) {
          costSummary.perMonth = (requestsSummary.requestsByMonth / 100000) * 131.97;
        }
        if (requestsSummary.requestsByMonth > 60000000 && requestsSummary.requestsByMonth <= 300000000) {
          costSummary.perMonth = (requestsSummary.requestsByMonth / 100000) * 72.94;
        }
        if (requestsSummary.requestsByMonth > 300000000 && requestsSummary.requestsByMonth <= 600000000) {
          costSummary.perMonth = (requestsSummary.requestsByMonth / 100000) * 56.41;
        }
        if (requestsSummary.requestsByMonth > 600000000 && requestsSummary.requestsByMonth <= 1200000000) {
          costSummary.perMonth = (requestsSummary.requestsByMonth / 100000) * 41.1;
        }
        if (requestsSummary.requestsByMonth > 1200000000 && requestsSummary.requestsByMonth <= 2400000000) {
          costSummary.perMonth = (requestsSummary.requestsByMonth / 100000) * 37.2;
        }

        costSummary.perHour = costSummary.perMonth / 30 / 24;
        costSummary.perYear = costSummary.perMonth * 12;
      }
    }

    costSummary.perHour = Number(costSummary.perHour.toFixed(2));
    costSummary.perMonth = Number(costSummary.perMonth.toFixed(2));
    costSummary.perYear = Number(costSummary.perYear.toFixed(2));

    return costSummary;
  }

  calculateFhirPrice(service): { perHour: number; perMonth: number; perYear: number } {
    let multiplier = 1;

    if (service.enableHA) {
      multiplier *= 2;
    }

    if (service.enableSqlbuilder) {
      multiplier *= 1.5;
    }

    const perHour = Number(
      ((service.diskSize / 10) * 0.005 +
        (service.coresFG.cores.value ? service.coresFG.cores.value : service.coresFG.cores) * 0.208) *
        multiplier,
    );
    const perMonth = perHour * 24 * 30;
    const perYear = perMonth * 12;

    return {
      perHour: perHour,
      perMonth: perMonth,
      perYear: perYear,
    };
  }

  calculateSqlaasPrice(service): { perHour: number; perMonth: number; perYear: number } {
    const perHour = Number(((service.tShirtSize.diskSize / 10) * 0.005 + service.tShirtSize.cores * 0.91 + (service.enableIML ? service.tShirtSize.cores * 0.228 : 0)));
    const perMonth = perHour * 24 * 30;
    const perYear = perMonth * 12;

    return {
      perHour: perHour,
      perMonth: perMonth,
      perYear: perYear,
    };
  }

  deleteService(service): void {
    const dialogConfig: ConfirmationDialogConfig = {
      title: 'Delete Calculation',
      primary: 'Are you sure you want to delete calculation?',
      hideCloseButton: true,
      buttons: {
        primary: {
          text: 'Confirm',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };
    this.dialog
      .open(ConfirmationDialogComponent, {
        panelClass: 'fr-layout-wrapper-mat-dialog-panel',
        data: dialogConfig,
      })
      .afterClosed()
      .pipe(
        filter(response => response?.button == 'primary'),
        map(() => {
          this.summaryCost.perHour -= Number(service.perHour.slice(1).replace(',', ''));
          this.summaryCost.perMonth -= Number(service.perMonth.slice(1).replace(',', ''));
          this.summaryCost.perYear -= Number(service.perYear.slice(1).replace(',', ''));

          this.addedServices.splice(
            this.addedServices.findIndex(s => s === service),
            1,
          );

          const totalI = this.addedServices.findIndex(i => i.type === 'Total');
          // if only 'total' field remains in array
          if (this.addedServices.length === 1) {
            this.addedServices.splice(totalI, 1);
            this.addedServices = [...this.addedServices];
          } else {
            this.addedServices.splice(totalI, 1);

            this.addedServices = [
              ...this.addedServices,
              {
                type: 'Total',
                perHour: this.formatNumberToUsd(this.summaryCost.perHour),
                perMonth: this.formatNumberToUsd(this.summaryCost.perMonth),
                perYear: this.formatNumberToUsd(this.summaryCost.perYear),
              },
            ];
          }
        }),
      )
      .subscribe();
  }

  formatNumberToUsd(number): string {
    return new Intl.NumberFormat(this.locale_string, this.formatting_options).format(number);
  }

  formatServiceType(service): string {
    if (service.type=='sqlaas') {

      return `Cloud SQL${service.enableIML ? ' with IntegratedML' : ''} - ${service.tShirtSize.name}`;
    }
    return service.type;
  }
}
