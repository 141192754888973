// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.trog.isccloud.io';
const STAGE = 'trog';

export const environment = {
  production: false, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_BQnSSsgzA',
  NG_APP_COGNITO_CLIENT_ID: '5k00vos85cqf9q1l5j76hpobih',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  SQLAAS_URL: 'https://use7jedz7i.execute-api.us-east-1.amazonaws.com/test',
};
