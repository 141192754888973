import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    // Only apply to API requests
    if (req.url.indexOf(environment.API_URL) != -1) {
      return this.authService.addAuthHeader$(req).pipe(switchMap(authReq => next.handle(authReq)));
    }
    
    //check to see if url matches icca API
    let regex = new RegExp(`https://([A-Za-z0-9]+(-[A-Za-z0-9]+)+)\\.${environment['ICCA_STAGE']}\\.${environment['ICCA_ACCOUNT']}\\.isccloud\\.io`,"i");
    if (regex.test(req.url)) {
      return this.authService.addAuthHeader$(req).pipe(switchMap(authReq => next.handle(authReq)));
      
    }
    
    for (const offer in environment['OFFER_API']) {
      if (req.url.indexOf(environment['OFFER_API'][offer]) != -1) {
        return this.authService.addAuthHeader$(req).pipe(switchMap(authReq => next.handle(authReq)));
      }
    }
    return next.handle(req);
  }
}
