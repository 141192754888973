import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

import { Observable, of, Subject } from 'rxjs';
import { catchError, pluck, map, tap } from 'rxjs/operators';

import { NotificationService } from '@intersystems/notification';

import { FileListObject } from '../model/file-list';
import { NOTIFICATION_TYPE } from '@intersystems/notification';
import { environment } from 'src/environments/environment';


import { AnyRecord } from 'dns';
import { env } from 'process';
import { ActivatedRoute } from '@angular/router';
import { table } from 'console';
import { DeploymentObject } from 'projects/api/src';
import { FDN, Field, IscFormModalData } from '@intersystems/isc-form';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogComponent } from '../dialogs/generic/generic-dialog.component'; 
import { EPresetOptions } from '@intersystems/table';
import { AbstractControl } from '@angular/forms';
import { IRISAuthService } from '../components/iris-login/iris-auth.service';
import { IrisConnection } from '../model/connection';
import { IccaCommonService } from './icca-common.service';
import { SharedService } from 'src/app/shared/services/shared.service';

export enum FormModes {
  VIEW = "view",
  EDIT = "edit"
}

@Injectable({ providedIn: 'root' })
export class FileHandlerService {
  
  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private irisAuthService: IRISAuthService,
    private iccaCommonService: IccaCommonService,
    private sharedService: SharedService,
  ) {}
  
  connectionInfo:IrisConnection;

  httpOptions = {
    headers: new HttpHeaders(
      { //'content-type': 'application/json',
      }
    )
  };
    
  /** GET File List from the server */
  getFiles(deploymentType:string, deploymentId:string, region:string): Observable<FileListObject> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/cluster/files/${deploymentId}`;
    return this.http.get(url).pipe(catchError(this.handleError<any>(`getFiles`, [])));
  }

  //
  downloadTLSCertificate(deploymentType:string, deploymentId:string, region:string): Observable<any> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/cluster/tls-certificate/${deploymentId}`;
    let filename:string;    

    return this.http.get<any>(url, {
      observe: 'response',
      responseType: 'blob' as 'json' // workaround: we want a blob, but http.get only allows json
    }).pipe(tap(res => {
      // strategy for downloading a blob adapted from stackoverflow conversation:
      // https://stackoverflow.com/questions/51960172/set-file-name-while-downloading-via-blob-in-angular-5
      const blob = new Blob([res.body], {type: res.body.type});

        try {
          //note that in order to access response headers you need the header property
          //added to "Access-Control-Expose-Headers" in the resonse from the server.
          filename = res.headers.get('content-disposition').split(';')
                      .map(x => x.trim()).filter(x => x.indexOf('filename') === 0)[0].split('=')[1];
        } catch (e) {}

        // download needs to use msSaveOrOpenBlob if using IE
        // https://stackoverflow.com/questions/20310688/blob-download-is-not-working-in-ie
        if (window.navigator && window.navigator["msSaveOrOpenBlob"]) {
          window.navigator["msSaveOrOpenBlob"](blob, filename);
        } else {
          const url = window.URL.createObjectURL(blob);
          const element = document.createElement('a');
          element.href = url;
          element.download = filename;
          document.body.appendChild(element);
          element.click();
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(element);
            window.URL.revokeObjectURL(url);
          }, 100);
        }
      }
    ),
    catchError((res) => {
      var reader = new FileReader();
      var that = this;
      reader.onload = function() {
        that.sharedService.showAlert(JSON.parse(reader.result.toString()).error.toString());
      }
      reader.readAsText(res.error);
      return EMPTY;
    }),
    );
  }

  getFile(deploymentType:string, deploymentId:string, region: string, filename: string, location: 'uploads' | 'errors' | 'transferError', type: 'view' | 'download' ): Observable<any> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/cluster/${location=='uploads' ? 'files' : (location=='errors' ? 'errors' : 'transfer/errors')}/download`;
    const data = `{"deploymentId": "${deploymentId}", "filename": "${filename}"}`;
    return this.http.post<any>(url, data, {
      observe: 'response',
      responseType: 'blob' as 'json' // workaround: we want a blob, but http.get only allows json
    }).pipe(tap(res => {
      // strategy for downloading a blob adapted from stackoverflow conversation:
      // https://stackoverflow.com/questions/51960172/set-file-name-while-downloading-via-blob-in-angular-5
      const blob = new Blob([res.body], {type: res.body.type});

      // if downloading, attempt to get the filename from content-disposition
      if (type=='download') {
        try {
          //note that in order to access response headers you need the header property
          //added to "Access-Control-Expose-Headers" in the resonse from the server.
          filename = res.headers.get('content-disposition').split(';')
                      .map(x => x.trim()).filter(x => x.indexOf('filename') === 0)[0].split('=')[1];
        } catch (e) {}

        // download needs to use msSaveOrOpenBlob if using IE
        // https://stackoverflow.com/questions/20310688/blob-download-is-not-working-in-ie
        if (window.navigator && window.navigator["msSaveOrOpenBlob"]) {
          window.navigator["msSaveOrOpenBlob"](blob, filename);
        } else {
          const url = window.URL.createObjectURL(blob);
          const element = document.createElement('a');
          element.href = url;
          element.download = filename;
          document.body.appendChild(element);
          element.click();
          setTimeout(() => {
            // For Firefox it is necessary to delay revoking the ObjectURL
            document.body.removeChild(element);
            window.URL.revokeObjectURL(url);
          }, 100);
        }
      }
    }),
    catchError((res) => {
      var reader = new FileReader();
      var that = this;
      reader.onload = function() {
        that.sharedService.showAlert(JSON.parse(reader.result.toString()).error.toString());
      }
      reader.readAsText(res.error);
      return EMPTY;
    }),
    );
  }

 /** Delete file from server */
  deleteFile(deploymentType, deploymentId: string, region:string, filename: string, type:'file' | 'error' | 'transfer'): Observable<any[]> {
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/cluster/${type=='file' ? 'files' : 'transfer/errors'}`;
    return this.http.request('delete', url, { body: {deploymentId:deploymentId, "filename": filename}}).pipe(
      //tap(_ => this.log(`deleting file filename=${filename}`,NOTIFICATION_TYPE.INFO)),
      catchError(this.handleError<any>(`deleteFile filename=${filename}`, []))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error('handleError'); // log to console instead
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`,NOTIFICATION_TYPE.ALERT);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a DeploymentService message with the NotificationService */
  private log(message: string, type:NOTIFICATION_TYPE) {
    this.notificationService.show(type,`ImportService: ${message}`,7000);
  }


  formatFileSize(size:number):string {
    var fileSize:number;
    if (size < 1024) {
      return (size + ' B');
    } else if (size < 1048576 ) {
      fileSize=size/1024;
      return (Math.round(fileSize) + ' KB')
    } else if (size < 1073741824) {
      fileSize=size/1048576;
      return (Math.round(fileSize) + ' MB')
    } else if (size < 1099511627776) { 
      fileSize=size/1073741824;
      return (Math.round(fileSize * 10)/10 + ' GB')
    } else {
      fileSize=size/1099511627776;
    }    
    return (Math.round(fileSize * 10)/10 + ' TB')
  } 
    
  uploadFile(deploymentType,deploymentId, region: string, file: File) {
    
    const formData: FormData = new FormData();
    const url = `${this.iccaCommonService.getAPIURL(deploymentType, region)}/cluster/files`;
    formData.append('deploymentId', deploymentId);
    formData.append('file', file);
    return this.http.post(url,formData,{reportProgress: true,responseType: 'json',headers:{},});
  }

  
  
 

  openFileViewerModal(data:any): void {
    const modalData: IscFormModalData = {
        modalTitle: data.filename, 
        iscFormInputs: {
          Id: 'fileViewer',
        
          FDN: {
            name: '',
            //description: 'Transfer files from S3 bucket. If bucket cannot be accessed, a policy will be provided to add to your Bucket policy',
            validateOn: 'change',
            sectionLayout: { showSectionHeaders: false },
            sections: [
                {
                fields: [
                  {
                    id: 'fileData',
                    key: 'fileData',
                    type: 'instructions',
                    templateOptions: {
                      label: '',
                    },
                    data: {
                      content: data.fileData,
                    },
                    wrappers: ['file-viewer-wrapper'],
                  },
                ]
              }
            ]
          }, 
          formModel:  data,
          mode: FormModes.EDIT,
          formConfig: {},
          buttons: [
            {
            id: 'close',
            text: 'Close',
            buttonClass: 'primary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
          ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }

}