import { Component, OnDestroy, OnInit } from '@angular/core';
import { FDN, Field } from '@intersystems/isc-form';
import { NotificationService } from '@intersystems/notification';
import { SpinnerService } from '@intersystems/spinner';
import { FieldWrapper } from '@ngx-formly/core';
import { DeploymentObject } from 'api';
import { BehaviorSubject, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ExternalObjectStorageConfiguration, ExternalObjectStorageConfigurationList } from 'src/app/deployments/icca-common/model/external-object-storage';
import { ExternalObjectStorageService } from 'src/app/deployments/icca-common/services/external-object-storage.service';
import { FileHandlerService } from 'src/app/deployments/icca-common/services/file-handler.service';
import { SharedService } from '../../services/shared.service';

@Component({
  selector: 'app-icca-server-file-select-wrapper',
  templateUrl: './isc-form-field-sfs-wrapper.component.html',
  styleUrls: ['./isc-form-field-sfs-wrapper.component.scss'],
})
export class IscFormFieldSFSWrapperComponent extends FieldWrapper implements OnInit, OnDestroy {
  field: Field;
  error = '';
  
  private sub = new Subscription();
  refreshToken$ = new BehaviorSubject<void>(undefined);
  public files$: Observable<any>;
  files:any;
  deployment:DeploymentObject;
  
  formModel: any = {
    fileToImport:''
  };
  loadInProgress:boolean = false;

  constructor(
    private notificationService: NotificationService,
    private fileHandlerService: FileHandlerService,
    private spinnerService: SpinnerService,
    private sharedService: SharedService,
    ) {
    super();
  }

  ngOnInit(): void {
    this.formModel = this.field.model;
    this.deployment= this.field.model.deployment;
    this.loadInProgress=true;
    this.files$ = this.refreshToken$.pipe(
    switchMap((data) => 
        this.fileHandlerService.getFiles(this.deployment.deploymenttype,
          this.deployment.deploymentid,
          this.deployment.region
      ).pipe(
        map(data => {
          this.loadInProgress=false;
          return data.files;
        })
      )
    ));

  }


  private _unsubscribeAll: Subject<any> = new Subject<any>();

  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }
 
  rowsChanged(event):void {
    this.formModel.fileToImport=event;
    this.field.formControl.patchValue(this.formModel.fileToImport);
    this.field.formControl.updateValueAndValidity;
  }
}
