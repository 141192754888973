<app-deployments-table
  [deployments]="deploymentsWithStatuses$ | async"
  (copyDeploymentId)="copyClick($event)"
  (refreshResources)="refreshResources($event)"
  (deleteDeployment)="deleteDeployment($event)"
  (downloadKey)="downloadKey($event)"
  (startDeployment)="toggleDeployment($event, 'start')"
  (stopDeployment)="toggleDeployment($event, 'stop')"
  (viewDeployment)="viewDeployment($event)"
  (hydrateDeployment)="hydrateDeployment($event)"
  (getPasswordforDeployment)="getPasswordforDeployment($event)"
  (loadDeleted)="loadDeleted()"
>
</app-deployments-table>
<app-deployment-view
  *ngIf="viewDeployment$ | async as deployment"
  [deployment]="deployment"
  (close)="viewDeployment$.next(null)"
  (copyClick)="copyClick($event)"
>
</app-deployment-view>
