import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { DeploymentObject } from 'api';
import { Observable } from 'rxjs';

import { DeploymentsService } from '../deployments.service';

@Injectable({
  providedIn: 'root',
})
export class DeploymentValidGuard implements CanActivate {
  constructor(
    protected deploymentsService: DeploymentsService,
    protected router: Router,
  ) { }

  /**
   * Override in a subclass to implement extra checks against deployment
   */
  protected canActivateDeployment(deployment:DeploymentObject) {
    return true;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
    if (!this.deploymentsService.deployments) {
      return true;
    }

    const deploymentId = route.paramMap.get('deploymentId');
    if (deploymentId == '') {
      return false;
    }

    const deployment = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments, deploymentId
    );

    if (!deployment) {
      this.router.navigate(['/']);
      return false;
    }

    if (!this.canActivateDeployment(deployment)) {
      return false;
    }

    return true;
  }

}
