import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ServicesCalculatorComponent } from './services-calculator/services-calculator.component';
import { CloudServicesPageComponent } from './cloud-services-page.component';
import { ServiceOverviewPageComponent } from './subscriptions/service-overview/service-overview-page.component';

export const routes: Routes = [
  {
    path: 'services',
    component: CloudServicesPageComponent,
    data: {
      caption: 'Services',
    },
  },
  {
    path: 'calculator',
    component: ServicesCalculatorComponent,
    data: {
      caption: 'Cost Calculator',
    },
  },
  {
    path: ':productCode',
    component: ServiceOverviewPageComponent,
  },
  {
    path: '',
    redirectTo: 'services',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CloudServicesRoutingModule {}
