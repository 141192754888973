<div class="main-div">
  <mat-card>
    <div class="header-container">
      <h1>
        <mat-icon svgIcon="InterSystemsIcon"></mat-icon>
        IRIS Cloud Deployment Login
      </h1>
    </div>

    <fr-isc-form Id="irisLoginForm" [FDN]="irisLoginFDN" [formModel]="formModel" [mode]="'edit'" [buttons]="Buttons"
      [formConfig]="">
    </fr-isc-form>
  </mat-card>
</div>