import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';


import { QueryHistory, SQLResponse } from '../../icca-common/model/sql-response'; 
import { TopbarControlService } from '@intersystems/header';
import { NotificationService, NOTIFICATION_TYPE } from '@intersystems/notification';
import { SQLQueryService } from './sql-query.service';
import * as _ from "lodash";


import { TableConfig, EPresetOptions, CheckboxConfig } from '@intersystems/table';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatorConfig } from "@intersystems/table";
import { AbstractControl, ControlContainer, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { SchemaBrowserComponent } from './schema-browser/schema-browser.component';
import { MatDialogRef } from '@angular/material/dialog';
import { SqlQueryHistoryComponent } from './sql-query-history/sql-query-history.component';
import { FDN, Field, VALIDATORS } from '@intersystems/isc-form';
import { DeploymentObject } from 'api';
import { DeploymentsService } from '../../deployments.service';
import { SqlQueryTableComponent } from './sql-query-table/sql-query-table.component';

@Component({
  selector: 'app-sql-query',
  templateUrl: './sql-query.component.html',
  styleUrls: [ './sql-query.component.scss' ]
})

export class SQLQueryComponent implements OnInit, OnDestroy {
  @ViewChild(SchemaBrowserComponent) private schemaBrowser:SchemaBrowserComponent;
  @ViewChild(SqlQueryHistoryComponent) private queryHistoryComponent:SqlQueryHistoryComponent;
  @ViewChild('historyDrawer') historyDrawer: any;
  
  @ViewChild(SqlQueryTableComponent) private sqlQueryTable:SqlQueryTableComponent;
  
  historyButtonClicked=false;
  
  private sub = new Subscription();
  opened: any=false;

  reloader = new BehaviorSubject<boolean>(true);
  sqlQuery: string='';  
  loadInProgress:boolean = false;
  
  queryFormData = {networkTimeout:"25000"};
  queryFDN: FDN = {
    
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            "key": "networkTimeout",
            "type": "number",
            "hideExpression": "",
            "hide": null,
            "expressionProperties": null,
            "className": "",
            "data": {
              "content": "",
              "hint": ""
            },
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Response timeout is a required field',
              [VALIDATORS.NUMBER]: 'Must be a number between 0 and 25000',
              [VALIDATORS.MAX_LENGTH]:'Must be a number between 0 and 25000'
            },
            validators: {
              inRange : {
                expression: (control: AbstractControl) => {
                  if (control.value=='') {
                    control.setValue(25000);
                  } 
                  return ((control.value>=0)&&(control.value<=25000))
                },
                message: (error: any, field: Field) => 'Must be a number between 0 and 25000'
              }
            },
            "id": "networkTimeout",
            "templateOptions": {
              "label": "", //"Response timeout (ms)",
              "required": false,
              //maxLength:2,
              //iscMaxLength:2

            },
          },

        ]
      }
    ]
  }





  infoObject= {
    sqlEditor: {
      infoTitle:'SQL Editor',
      htmlText: "You can enter and execute any valid SQL query, DDL, or DML using the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_COMMANDS' target='_blank'>commands</a> and \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_FUNCTIONS' target='_blank'>functions</a> (including \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_AGGREGATE_FUNCTIONS' target='_blank'>aggregate functions</a>) \
      listed in the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL' target='_blank'>InterSystems SQL Reference</a>. \
      However, a limited number of operations may require InterSystems SQL privileges that are not available to users of this service. \
      <br><br>\
      If <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Intro' \
      target='_blank'>IntegratedML</a> is enabled, you can use this page to edit \
      CREATE MODEL, TRAIN MODEL, VALIDATE MODEL, and PREDICT commands generated on the \
      <b>IntegratedML Tools</b> page before executing them."
    },
    responseTimeout: {
      infoTitle:'Response timeout',
      htmlText: "If results are not returned before this timeout is reached, query or statement processing is canceled. \
      You can change it to anything between 0 and 25000 milliseconds. Bear in mind that latency in connections to cloud \
      resources may vary from one day or time to another.",
    }
  };



  constructor(
  public sqlQueryService: SQLQueryService,
    private location: Location,
    private topbarControlService: TopbarControlService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private deploymentsService: DeploymentsService,
  ) {
    this.sqlQuery = this.sqlQueryService.getSQLQuery();

   }
  
  saveQuery(): void {
    this.sqlQueryService.setSQLQuery(this.sqlQuery);
  }
  
  ngOnInit(): void {
    this.sqlQuery=this.sqlQueryService.getSQLQuery();
  }
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }

 closeHistory(): void {
  if (this.historyDrawer.opened && !this.historyButtonClicked) {
    this.historyDrawer.close()
  }
  this.historyButtonClicked=false;

 }
 trimQuery(query) {
  //used to determine if Execute button should be enabled
  //remove whitespace
  //remove any comment lines (start with --)
  //remove any whitespace left over from comment line removal
  return query.trim().replace(/^--.*$/gm, '').trim();
 }

  runQuery(): void {
    this.loadInProgress = true;
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment:DeploymentObject = this.deploymentsService.findDeployment(this.deploymentsService.deployments,deploymentId);
   
    var query: QueryHistory = {
      sqlStatement: this.sqlQuery
    }
    //preserve paginator config
    this.sqlQueryService.currentPageSize=this.sqlQueryTable.sqlTable["paginator"]._pageSize;
    
    this.historyDrawer.opened=false;  //hide history drawer if opened
    this.sqlQueryService.setqueryHistory(query);
    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment,this.sqlQuery,this.queryFormData)
    .subscribe((ret:any) => {
        if (!ret.error) {
          this.notificationService.showInfo(`SQL Query Executed`, 2000)
        }
        
        this.sqlQueryService.setSQLResponse(ret);
        this.reloader.next(true);
        //refresh schema after query is executed
        this.schemaBrowser.getSchemas();
        this.updateHistory();
        this.loadInProgress = false;
    }));
  }
  
  clearQuery():void {
    this.sqlQuery='';
    this.sqlQueryService.setSQLResponse(undefined);
    this.reloader.next(true);
  }
  clearButtonDisabled():boolean {
    return (
      ( (this.sqlQueryTable?.tableConfig?.noDataMessage=='No records found.')&&
        (this.sqlQueryTable?.sqlResultSetData?.length==0) &&
        (this.sqlQuery=='')
      ) ||
      (this.loadInProgress));
  }

  getQuery(direction):void {
    //save off current query into list
    this.sqlQueryService.setSQLQuery(this.sqlQuery);
    this.sqlQuery=this.sqlQueryService.getQueryHistory(direction).sqlStatement;
  }
  
  

  updateHistory(): void {
    this.queryHistoryComponent.updateQueryHistory(this.sqlQueryService.queryHistory);
  }

  eventFromQueryHistory($event) {
      this.sqlQuery=$event;
  }

  onQueryEdit($event): void {
    this.sqlQuery=$event;
    

  }

}





