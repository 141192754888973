import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { MainMenuService } from 'src/app/core/main-menu.service';
import { DeploymentsService } from 'src/app/deployments/deployments.service'; 
import { IRISAuthService } from './iris-auth.service'; 
import { filter, map, takeUntil, switchMap, tap, mergeMap, take, catchError } from 'rxjs/operators';
import { IccaCommonService } from '../../services/icca-common.service';
import { SharedService } from 'src/app/shared/services/shared.service';
import { BehaviorSubject, Observable, Subscription, forkJoin, of } from 'rxjs';
import { IRISCredential } from '../../model/iris-credential';
import { DeploymentObject } from 'api';


@Component({
  selector: 'app-iris-login-page',
  templateUrl: './iris-login-page.component.html',
  styleUrls: ['./iris-login-page.component.scss'],
})
export class IRISLoginPageComponent {
  private sub = new Subscription();
  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    private irisAuthService: IRISAuthService,
    private notificationService: NotificationService,
    private mainMenuService: MainMenuService,
    private deploymentsService: DeploymentsService,
    private iccaCommonService: IccaCommonService,
    private sharedService: SharedService,
    ) {
        
      const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
      this.deploymentsService.deployment$(deploymentId).pipe(take(1)).subscribe(deployment => 
        {
          if (deployment) {
            this.iccaCommonService.loadDeploymentInfo$(deployment.deploymenttype,deploymentId, deployment.region).subscribe(()=>{},
               //lambda will return 404 if deployment is deleted
              err =>{
                if (err?.status==404) {
                  this.sharedService.showAlert('Deployment not found');
                  this.router.navigate(['/deployments']);

                }
                else {
                  this.sharedService.showAlert('Error getting deployment info: ' + err.error.error);
                }     
              });
          }    
        }
      ); 

    }

    onLogin(formData: any): void {
    //getdeployment type

    //check formData
    if (formData.password=='') {
      this.notificationService.showAlert("Enter password and try again.",7000);
    }
    const deployment = this.deploymentsService.findDeployment(this.deploymentsService.deployments,formData.deploymentId);
    const deploymentType = deployment.deploymenttype;

    //add IRIS login check
    this.irisAuthService
    .authorise(deploymentType,formData.deploymentId,deployment.region, formData.username, formData.password)
    .pipe(
      tap(response => {
        if (response.log=='Operation Successfully Completed' || response.return) {
          this.irisAuthService.setCredential({deploymentId:formData.deploymentId, username:formData.username, password:formData.password});
          this.iccaCommonService.updateIML(deploymentType, formData.deploymentId);
          var redirectUrl=this.route.snapshot.queryParams['redirectUrl'];
          if (redirectUrl) {
            redirectUrl = /[^/]*$/.exec(redirectUrl)[0];
          } else {
            redirectUrl='overview';
          }
          this.router.navigate(['/deployments', formData.deploymentId, redirectUrl])
            .then(data => {
             if (!data) {
              //routing failed
              this.router.navigate(['/deployments', formData.deploymentId, 'overview']);
             }
            })
            .catch(e => {
              this.router.navigate(['/deployments', formData.deploymentId, 'overview']);
            });
        } else {
          if (response['error']) {
            var err = response['error'].split('class java.sql.SQLException: [InterSystems IRIS JDBC] Communication link failure: ')[1];
            if (err!="") {
              this.notificationService.showAlert(`Sign in failed: ${err}`,7000);
            } else {
              this.notificationService.showAlert(`Sign in failed: ${response['error']}`,7000);
            }
          }
        }
      }),
      catchError(error => {
        let message = "";
        if (error?.status==401) {
          message='Incorrect username or password.';
        } else if (error?.error?.error) {
          message=error.error.error;
        } else if (error?.error) {
          message=error.error;
        } else {
          message="Unable to log in";
        }
        return of(this.notificationService.showAlert(`Sign in failed: ${message}`,7000))
      })
    ).subscribe();
  
  
  }

  navigate(action: any[]) {
    this.router.navigate(action);
  }
}
