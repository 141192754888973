import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { TableEnhancedIdService } from '../../../core/table-enhanced-id.service';
import { Sort } from '@angular/material/sort';
import { SharedUtils } from '../../../shared/shared-utils';

@Component({
  selector: 'app-tenants-table',
  templateUrl: './tenants-table.component.html',
  styleUrls: ['./tenants-table.component.scss'],
})
export class TenantsTableComponent {
  @Input() tenants: { tenantId: string; tenantName: string }[];

  @Output() manageTenantOffers = new EventEmitter<string>();

  constructor(public tableService: TableService) {}

  tableConfig: TableConfig = {
    key: 'tenants-table',
    header: {
      title: 'Tenants Overview',
    },
    noDataMessage: 'No tenants',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('tenantId'),
    sort: {
      sortFunction: (event: Sort, data: any) => {
        return data.sort((a: any, b: any) => {
          const isAsc = event.direction === 'asc';
          switch (event.active) {
            case 'tenantId':
              return this.tableService.compareAlphaNumeric(a.tenantId, b.tenantId, isAsc);
            default:
              return 0;
          }
        });
      },
    },
    columns: [
      SharedUtils.getTableColumn('tenantId', 'Tenant ID'),
      SharedUtils.getTableColumn('tenantName', 'Tenant Name'),
      {
        key: 'action',
        id: 'action',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            edit: {
              id: 'edit',
              tooltip: { text: 'Manage offers' },
              callback: (_event, row) => this.manageTenantOffers.emit(row.tenantId),
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };
}
