import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NotificationService } from '@intersystems/notification';
import { AuthService } from './core/auth.service';
import { AuthInterceptor } from './core/auth.interceptor';
import { CoreModule } from './core/core.module';
import { ApiModule } from 'api';
import { ApiModule as AdminApiModule } from 'admin-api';

/** Http interceptor providers in outside-in order */
export const httpInterceptorProviders = [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }];

/** App Initialisation providers -- Observable support is only added in Angular v12
 *  so use Promise here for now
 */
function initializeAppFactory(authService: AuthService): () => Promise<any> {
  return () => authService.authorise(null, null).toPromise();
}
export const appInitProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeAppFactory,
    deps: [AuthService],
    multi: true,
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    CoreModule,
    ApiModule.forRoot(AuthService.getApiConfiguration),
    AdminApiModule.forRoot(AuthService.getApiConfiguration),
  ],
  providers: [NotificationService, httpInterceptorProviders, appInitProviders],
  bootstrap: [AppComponent],
})
export class AppModule {}
