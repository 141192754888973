import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, from, Observable, of } from 'rxjs';
import { NotificationService, NOTIFICATION_TYPE } from '@intersystems/notification';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';
import { IRISCredential } from '../../model/iris-credential'; 
import { IrisConnection } from '../../model/connection';
import { AuthService } from 'src/app/core/auth.service';
import { DeploymentsService } from 'src/app/deployments/deployments.service';

@Injectable({
  providedIn: 'root',
})
export class IRISAuthService {
  constructor(
    private notificationService: NotificationService,
    private http: HttpClient,
    private authService: AuthService,
    private deploymentsService: DeploymentsService,
  ) { 
      //clear credentials
      this.authService.user$.subscribe(user => {
        if (!user) this.irisCredentials=[];
      });
  }


  private _credentials = new BehaviorSubject<IRISCredential[]>(null);
  private credentials$ = this._credentials.asObservable();
  get credentials(): IRISCredential[] {
    return this._credentials.getValue();
  }

  private _credential = new BehaviorSubject<IRISCredential>(null);
  //private credential$ = this._credential.asObservable();
  get credential(): IRISCredential {
    return this._credential.getValue();
  }

  //array to store credentials for session
  irisCredentials: IRISCredential[] = [];

  authorise(deploymentType, deploymentId: string, region: string, username: string, password: string): Observable<any> {
    const url = `${this.deploymentsService.getAPIURL(deploymentType,region)}/sql/connect`;
    const offerDefinition = this.deploymentsService.getOfferDefinition(deploymentType);
    const namespace = offerDefinition.ns ? offerDefinition.ns : "USER";
    const data: any = {
      deploymentId: deploymentId,
      connection: {
        port: '1972',
        namespace: namespace,
        username: username,
        networkTimeout: '25000',
        password: password,
      },
    };

    return this.http.post(url, JSON.stringify(data), {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    }).pipe(
      //catchError(this.handleError<any>(`IRIS Authorize`, []))
    );
  }

  /// Check if user is authenticated for current deployment
  check$(deploymentId: string): Observable<boolean> {
    return of(!!this.findCredential(this.irisCredentials, deploymentId));
  }

  

  // iris credential management

  credential$(deploymentId: string): Observable<IRISCredential> {
    return this.credentials$.pipe(
      map(data => {
        return this.findCredential(data, deploymentId);
      }),
      catchError(() => {
        return null;
      }),
    );
  }

  findCredential(credentials: IRISCredential[], deploymentId: string): IRISCredential {
    if (!credentials) credentials = this.irisCredentials;

    if (!credentials) return null;
    let foundCredential = null;
    credentials.forEach(credential => {
      if (credential.deploymentId == deploymentId) {
        foundCredential = credential;
      }
    });
    return foundCredential;
  }

  setCredential(credential: IRISCredential) {
    let bFound:boolean=false;
    //find existing credential if it exsits
    this.irisCredentials.forEach(function (cred, index) {
      if (cred.deploymentId == credential.deploymentId) {
        bFound=true;
        this[index] = credential;
        return;
      }
    }, this.irisCredentials);
    if (!bFound) {
      this.irisCredentials.push(credential);
    }
    
  }

  getIRISConnectionInfo(deploymentId: string, deploymentType:string): IrisConnection {
    const cred = this.findCredential(this.irisCredentials, deploymentId);
    const namespace = this.deploymentsService.getOfferNamespace(deploymentType);
    if (!cred) {
      return {
        port: '1972',
        namespace: namespace,
        username: '',
        networkTimeout: '25000',
        password: '',
      };
    }

    return {
      port: '1972',
      namespace: namespace,
      username: cred.username,
      networkTimeout: '25000',
      password: cred.password,
    };
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      this.log(`${operation} failed: ${error.message}`,NOTIFICATION_TYPE.ALERT);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

  /** Log a DeploymentService message with the NotificationService */
  private log(message: string, type:NOTIFICATION_TYPE) {
    this.notificationService.show(type,`IRISAuthService: ${message}`,7000);
  }


}
