import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormButton, FDN, VALIDATORS } from '@intersystems/isc-form';
import { DeploymentObject } from 'api';
import { Observable, Subscription } from 'rxjs';
import { DeploymentsService } from 'src/app/deployments/deployments.service'; 
import { IccaDeploymentObject } from '../../model/deployment-info'; 
import { IRISCredential } from '../../model/iris-credential';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-iris-login-form',
  templateUrl: './iris-login-form.component.html',
  styleUrls: ['./iris-login-form.component.scss'],
})
export class IRISLoginFormComponent implements OnInit, OnDestroy {
  @Input() formModel: IRISCredential = {};
  @Output() irisLogin = new EventEmitter<any>();
  
  constructor(
    private route: ActivatedRoute,
    private deploymentsService: DeploymentsService,
  ) {}
  private sub = new Subscription();

  irisLoginFDN: FDN = {
    name: 'Log In',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'deploymentName',
            key: 'deploymentName',
            type: 'input',
            templateOptions: {
              label: 'Deployment name',
            },
            viewOnly: true,
          },
          {
            id: 'deploymentId',
            key: 'deploymentId',
            type: 'input',
            templateOptions: {
              label: 'Deployment Id',
            },
            viewOnly: true,
          },
          {
            id: 'username',
            key: 'username',
            type: 'input',
            //overrideValidatorMessage: {
            //  [VALIDATORS.ISC_REQUIRED]: 'Username is a required field',
            //},
            templateOptions: {
              //required: true,
              label: 'Username',
            },
            viewOnly: true,
          },
          {
            id: 'password',
            key: 'password',
            type: 'input',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Password is a required field',
            },
            templateOptions: {
              type: 'password',
              label: 'Password',
              required: true,
            },
            data: {
              displayField: 'name',
              hint: 'Enter the IRIS password you provided when you created the deployment.',
            
            },
          },
        ],
      },
    ],
  };
  // Empty Buttons array to assign
  Buttons: FormButton[] = [
    {
      id: 'signInSubmitButton',
      text: 'Log In',
      buttonClass: 'primary',
      type: 'submit',
      disabled: (_formModel: any, _formOptions: any, form: any) => form.valueChanges.pipe(map(() => {
        //console.log('disabled-formchanges');
        //console.log(form);
        return !form.valid || form.pristine;
      })),
      callback: (clickEvent: any, button: any, formModel: any) => {
        //console.log('callback');
        //console.log(formModel);
        this.irisLogin.emit(formModel);
      },
    },
  ];

  deployment: IccaDeploymentObject | undefined;
  deployment$: Observable<DeploymentObject>;  
  

  ngOnInit(): void {
     this.formModel.username='SQLAdmin';
     this.formModel.password='';
    
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    this.sub.add(this.deploymentsService.deployment$(deploymentId).subscribe(deployment => {
      this.deployment=deployment;

      this.formModel.deploymentName=this.deployment?.deploymentname;
      this.formModel.deploymentId = (this.deployment?.deploymentname ? deploymentId : '');
    }));
  }
  
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this.sub.unsubscribe();
 }
}
