<div class="wrapper">
  <div class="field">
    <ng-container #fieldComponent></ng-container>

    <div class="wrapper-buttons" *ngIf="options?.formState?._mode === 'edit'">
      <button
        *ngFor="let button of field.buttons"
        mat-stroked-button
        [disabled]="isButtonDisabled(button)"
        [attr.color]="button.color || 'primary'"
        [type]="button.type"
        [title]="button.tooltip"
        [ngClass]="button.className || 'fr-primary'"
        [attr.id]="button.id ? (formState?.Id ? formState?.Id + '-' : '') + button.id + '-button' : null"
        (click)="button.callback()"
      >
        <mat-icon *ngIf="button.matIcon" class="icon">{{ button.matIcon }}</mat-icon
        >{{ button.text }}
      </button>
    </div>
  </div>
</div>
