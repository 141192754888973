import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig, TableConfig, TableService } from '@intersystems/table';
import { Sitrep } from 'admin-api';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { Sort } from '@angular/material/sort';
import { SharedService } from '../../../shared/services/shared.service';

@Component({
  selector: 'app-sitreps-table',
  templateUrl: './sitreps-table.component.html',
  styleUrls: ['./sitreps-table.component.scss'],
})
export class SitrepsTableComponent {
  @Input() sitreps!: Sitrep[];

  @Output() ackSitrep = new EventEmitter<string>();
  @Output() doneSitrep = new EventEmitter<string>();
  @Output() openBody = new EventEmitter<string>();

  constructor(public tableService: TableService, public sharedService: SharedService) {}

  tableConfig: TableConfig = {
    key: 'sitreps-table',
    header: {
      title: 'Situation Reports',
    },
    noDataMessage: 'No situation reports available.',
    useSearch: true,
    searchConfig: {
      placeholderLabel: 'Search',
    },
    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('username'),
    columns: [
      {
        id: 'created_at',
        key: 'created_at',
        title: 'Created At',
        sortable: true,
        cellDisplay: {
          model: 'created_at',
        },
      },
      SharedUtils.getTableColumn('status'),
      SharedUtils.getTableColumn('source'),
      SharedUtils.getTableColumn('tenantid'),
      SharedUtils.getTableColumn('deploymentid'),
      SharedUtils.getTableColumn('subject'),
      //SharedUtils.getTableColumn('ack_at', 'ACK date'),
      //SharedUtils.getTableColumn('ack_duration', 'ACK dur'),
      //SharedUtils.getTableColumn('ack_iscsupport', 'ACK user'),
      //SharedUtils.getTableColumn('rto_at', 'RTO date'),
      //SharedUtils.getTableColumn('rto_duration', 'RTO dur'),
      //SharedUtils.getTableColumn('rto_iscsupport', 'RTO user'),
      {
        id: 'actions',
        key: 'actions',
        title: 'Actions',
        cellDisplay: {
          preset: 'actionsIcons',
          actionsIcons: {
            iconsOrder: ['view', 'done', 'body'],
            view: {
              id: 'ack',
              tooltip: { text: 'Acknowledge' },
              callback: (_event, item: Sitrep) => this.ackSitrep.emit(item.sitrepid),
              hidden: (row: Sitrep) => row.ack_iscsupport != 'False',
            },
            done: {
              id: 'done',
              tooltip: { text: 'Done' },
              customSvgIcon: 'done-green',
              callback: (_event, item: Sitrep) => this.doneSitrep.emit(item.sitrepid),
              hidden: (row: Sitrep) => row.rto_iscsupport != 'False',
            },
            body: {
              id: 'body',
              tooltip: { text: 'Body' },
              customSvgIcon: 'download',
              callback: (_event, item: Sitrep) => this.openBody.emit(item.body),
            },
          },
        },
      },
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
    pageSizeOptions: [10, 20, 50],
  };
}
