<fr-table *ngIf="type=='createdModels'" [config]="createdModelsConfig" [(data)]="createdModelsData" [safeMode]="true"
  [paginatorConfig]="paginatorConfig" Id="create-models-table">

  <div class="custom-table-title" table-title-text-space>
    <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
  </div>

  <div class="custom-table-header-space" table-header-space title="Hide">
    <button id="btn-training-model-hide" mat-flat-button class="fr-primary button-padding" color="primary"
      (click)="hide()">
      Hide
    </button>

  </div>
</fr-table>

<fr-table *ngIf="type=='trainedModels'" [config]="trainedModelsConfig" [(data)]="trainedModelsData" [safeMode]="true"
  [paginatorConfig]="paginatorConfig" Id="trained-models-table">
  <div class="custom-table-title" table-title-text-space>
    <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
  </div>

  <div class="custom-table-header-space" table-header-space title="Hide">
    <button id="btn-training-model-hide" mat-flat-button class="fr-primary button-padding" color="primary"
      (click)="hide()">
      Hide
    </button>
  </div>
</fr-table>


<fr-table *ngIf="type=='trainingRuns'" [config]="trainingRunsConfig" [(data)]="trainingRunsData" [safeMode]="true"
  [paginatorConfig]="paginatorConfig" Id="training-runs-table">
  <div class="custom-table-title" table-title-text-space>
    <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
  </div>

  <div class="custom-table-header-space" table-header-space title="Hide">
    <button id="btn-training-run-hide" mat-flat-button class="fr-primary button-padding" color="primary"
      (click)="hide()">
      Hide
    </button>
  </div>
</fr-table>


<fr-table *ngIf="type=='validationRuns'" [config]="validationRunsConfig" [(data)]="validationRunsData" [safeMode]="true"
  [paginatorConfig]="paginatorConfig" Id="validation-runs-table">
  <div class="custom-table-title" table-title-text-space>
    <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
  </div>

  <div class="custom-table-header-space" table-header-space title="Hide">
    <button id="btn-validation-run-hide" mat-flat-button class="fr-primary button-padding" color="primary"
      (click)="hide()">
      Hide
    </button>
  </div>
</fr-table>


<fr-table *ngIf="type=='validationMetrics'" [config]="validationRegressionMetricsConfig"
  [(data)]="validationRegressionMetricsData" [safeMode]="true" [paginatorConfig]="paginatorConfig"
  Id="validation-regression-metrics-table">
  <div class="custom-table-title" table-title-text-space>
    <app-info-button [htmlText]="infoObject.validationMetrics.htmlText" [title]="infoObject.validationMetrics.infoTitle"
      [tabIndex]="-1"></app-info-button>
    <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
  </div>

  <div class="custom-table-header-space" table-header-space title="Hide">
    <button id="btn-validation-metrics-hide" mat-flat-button class="fr-primary button-padding" color="primary"
      (click)="hide()" tabindex="1">
      Hide
    </button>
  </div>
</fr-table>


<fr-table *ngIf="type=='validationMetrics'" [config]="validationClassificationMetricsConfig"
  [(data)]="validationClassificationMetricsData" [safeMode]="true" [paginatorConfig]="paginatorConfig"
  Id="validation-classification-metrics-table">
</fr-table>