<h2>Deployments</h2>
<ng-container *ngIf='deployments.length == 0'>
  <p>Current tenant has no deployments at this moment.</p>
</ng-container>
<ng-container *ngIf='deployments.length > 0'>
  <div class='depContainer'>
    <div *ngFor='let deployment of deployments; let i=index'>
      <app-deployment-card [deployment]='deployment'
                           [currentTenantData]='currentTenantData'
                           (deleted)='canDeleteDeployment
                         ? deleted.emit(deployment.deploymentid)
                         : null' (selected)='selected.emit(deployment.deploymentid)'>
      </app-deployment-card>
    </div>
  </div>
</ng-container>
