<mat-card>
  <div class="form-header">
    <span>
      <h1>IntegratedML Orientation</h1>
    </span>
    <mat-icon id="form-header-x-button" title="{{ 'Close' | translate }}" (click)="closeDrawer.emit()">close</mat-icon>
  </div>
  <div class="instructions">
    This introduction to InterSystems IRIS Cloud IntegratedML shows how you can use the service to derive insights
    from your data through machine learning. In it, you will create a model that predicts the tip amount for a given
    New York City taxi ride. Steps 1 and 2 help you create a deployment equipped with IntegratedML and load sample
    data into its database before the remaining steps guide you through the basic machine learning tasks in the
    IntegratedML workflow:
    <ul>
      <li><i>Create</i> a model</li>
      <li><i>Train</i> it as a predictive model</li>
      <li><i>Validate</i> the trained model</li>
      <li><i>Predict</i> values using the trained model</li>
    </ul>
    <br>

    As you proceed, click the information <mat-icon class="info-icon">info_outline</mat-icon> icons for more information
    about these
    tasks.


  </div>

  <mat-accordion id="mat-acc-create-panel" multi>
    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-create-deployment-panel">
        <mat-panel-title id="mat-exp-title-create-deployment-panel">

          1. Create a Cloud SQL IntegratedML deployment
        </mat-panel-title>
      </mat-expansion-panel-header>

      <b>Note:</b>If you want to use a Cloud IntegratedML deployment you have already created, skip to Step 2.
      <br><br>
      <span class="additional-title-text">
        <button id="btn-route-to-create" class="button-link" color="primary" (click)="preloadCreatePage()">Click
          here</button>
        to open the Create InterSystems IRIS Deployment page with most of the fields prefilled.
        <ul>
          <li>Enter your password at the <b>Password</b> and <b>Confirm Password</b> prompts</li>
          <li>Scroll to and expand <b>Deployment Name</b> so you can name your deployment.</li>
          <li>Finally, expand <b>Review</b> and click <b>Create</b>. Deployment creation takes a minute or two; when the
            status on the deployment's tile changes to Running, click the tile to display the deployment's Overview
            page. </li>
        </ul>
      </span>

    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-create-tables-panel">
        <mat-panel-title id="mat-exp-title-create-tables-panel">
          2. Create tables and load them with data
        </mat-panel-title>
      </mat-expansion-panel-header>
      Choose <b>Add and Manage Files</b> on the left-hand menu and log into the deployment using the password you
      provided. On the Add and Manage Files page:

      <ul>
        <li>Click <b>External Transfer</b>, select the pre-loaded samples
          external storage location, and click <b>List Files</b>.
        </li>

        <li>Select <i>yellow_tripdata.sql</i>, <i>yellow_tripdata_15k-train.csv</i>, and
          <i>yellow_tripdata_15k-validate.csv</i> then click <b>Transfer</b> to add these files to the <b>Files added to
            the deployment</b> list.
        </li>

      </ul>
      Choose <b>Import Files</b> from the left-hand menu. On the Import Files page:
      <ul>
        <li>Select <b>DDL or DML statement(s)</b> as the file type and click <b>Next</b>.</li>
        <li>Click <b>Next</b> again (with <i>InterSystems IRIS</i> selected as the platform)
          to display the available files.</li>
        <li>Select <i>yellow_tripdata.sql</i>, then click <b>Import Files</b> and confirm. This creates two tables:
          one for data to train your model on, and one to validate and make predictions.</li>

        <li>Click <b>Done</b>.</li>
      </ul>

      Import two separate CSV files to populate the training and validation tables with data using these steps:
      <ul>
        <li>Select <b>CSV data</b> as the file type and click <b>Next</b>.</li>
        <li>Select the <i>yellow_tripdata_15k-train.csv</i> file and click <b>Next</b>.</li>
        <li>Select the <i>SQLUser.yellow_tripdata_train</i> table from the dropdown list. This loads the contents of the
          CSV file into the training data table.</li>
        <li>Select the <b>Import file has header row</b> checkbox and click <b>Import File</b>. The <b>Result</b> column
          shows 15,276 rows updated.</li>
        <li>Repeat the CSV import process for the other CSV file (<i>yellow_tripdata_15k-validate.csv</i>), selecting
          the corresponding table and confirming successful import of 15,637 rows.</li>
      </ul>
      <b>Note:</b> The files <i>starexportddl.sql</i>, <i>starclassification_train.csv</i>, and
      <i>starclassification_validate.csv</i> in the S3 bucket represent a different data set you can work with after you
      have completed this exercise. For more information, see step 8.


    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-create-model-panel">
        <mat-panel-title id="mat-exp-title-create-model-panel">
          3. Create your model
        </mat-panel-title>
      </mat-expansion-panel-header>
      Now that your data is imported, you can create a model that targets a specific field for predictions. Choose
      <b>IntegratedML Tools</b> on the left-hand menu, then expand the <b>Create</b> section and complete the following
      steps:
      <ul>
        <li>Enter <i>TipPrediction</i> as the name of your model.</li>
        <li>Select <i>SQLUser.yellow_tripdata_train</i> as the table to train on.</li>
        <li>Select <i>tip_amount NUMERIC</i> as the field to predict. The <i>Create model</i> SQL statement displays.
        </li>
        <li>Click <b>Create model</b> to execute the generated SQL and create the model.</li>
      </ul>
      You have now created a model that you can train on the <i>yellow_tripdata_train</i> data to generate predictions
      for the <i>tip_amount</i> field.

    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-train-model-panel">
        <mat-panel-title id="mat-exp-title-train-model-panel">
          4. Train your model
        </mat-panel-title>
      </mat-expansion-panel-header>

      Collapse the <b>Create</b> panel and expand <b>Train</b>. To train the model on the relationships between the
      fields in the <i>yellow_tripdata_train</i> data set, ultimately enabling it to predict the <i>tip_amount</i>
      field, complete the following steps:
      <ul>
        <li>Select <i>TipPrediction predicting tip_amount</i> for training. The <i>Train model</i> SQL statement
          displays.</li>
        <li>Click <b>Train model</b> to begin training the model. The <b>Training Runs</b> panel opens, showing the
          run's status.
          Training time varies with deployment size, up to 4-5 minutes on the smallest. Once the <b>Run Status</b>
          changes to completed, you can hide the panel.</li>
      </ul>
      Your model has now been trained using the <i>yellow_tripdata_train</i> data set and is ready to be validated.
    </mat-expansion-panel>


    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-validate-model-panel">
        <mat-panel-title id="mat-exp-title-validate-model-panel">
          5. Validate your model
        </mat-panel-title>
      </mat-expansion-panel-header>
      Collapse the <b>Train</b> panel and expand <b>Validate</b>. To validate your trained model and see metrics
      confirming its accuracy and validity, complete the following steps:
      <ul>
        <li>Select your trained model, <i>TipPrediction_t1 using TipPrediction</i> for validation. (You may need to
          refresh the dropdown.)</li>
        <li>Select <i>SQLUser.yellow_tripdata_validate</i> as the table to validate from. The <i>Validate model</i> SQL
          statement displays.</li>
        <li>Click <b>Validate model</b> to begin validating the model. The <b>Validation runs</b> panel opens, showing
          the run's status.</li>
        <li>When the run is completed, examine the validation metrics by hiding the panel and clicking <b>Show
            validation metrics</b>. Note that the <i>R2</i> value is very close to 1, which indicates a strong
          predictive
          model.</li>
        <li>Click the eye icon under <b>Chart</b> to see a visualization of your validation results.</li>
      </ul>
      With your model validated, you can now test out some predictions.
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-predict-panel">
        <mat-panel-title id="mat-exp-title-predict-panel">
          6. Make predictions
        </mat-panel-title>
      </mat-expansion-panel-header>
      Collapse the <b>Validate</b> panel and expand <b>Predict</b>. The validation subset of the taxi data was not
      included in your model's training, making it ideal for testing predictions. To predict the <i>tip_amount</i>
      field, complete the following steps:
      <ul>
        <li>Select your trained model, <i>TipPrediction_t1 using TipPrediction</i>, for prediction.
          (You may need to refresh the dropdown.)</li>
        <li>Select <i>SQLUser.yellow_tripdata_validate</i> as the table to predict on.</li>
        <li>Enter <i>100</i> as the number of rows to predict.</li>
        <li>Click <b>Generate SQL</b> to generate the SQL prediction statement.</li>
        <li>Click <b>Execute on SQL Query Tools</b> to proceed to the SQL Query Tools page and execute the generated
          SQL.</li>
      </ul>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-results-panel">
        <mat-panel-title id="mat-exp-title-results-panel">
          7. Examine your results
        </mat-panel-title>
      </mat-expansion-panel-header>
      On the SQL Query Tools page, click <b>Execute</b> to execute the generated SQL statement, which creates
      predictions for the <i>tip_amount</i> field. The displayed results include both the predicted and actual tip
      amounts for each row, so you can compare your model's predictions with the actual tip amount for each NYC taxi
      ride.
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header id="mat-exp-header-explore-more-panel">
        <mat-panel-title id="mat-exp-title-explore-more-panel">
          8. Explore more
        </mat-panel-title>
      </mat-expansion-panel-header>

      Continue learning about IRIS Cloud IntegratedML by
      <ul>
        <li>Transferring and importing the <i>starexportddl.sql</i>, <i>starclassification_train.csv</i>, and
          <i>starclassification_validate.csv</i> files from external storage (see step 2) and using them to create,
          train and validate a model that uses the <i>class</i> field as label. This model predicts whether the
          astronomical object described by each row is a star, quasar, or galaxy and is therefore a
          <a href="https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Intro#GIML_Intro_Background"
            target='_blank'>classification model</a>, which predicts discrete values, rather than a regression model
          like <i>TipPrediction</i>, which predicts continuous numeric values.
        </li>
        <li>Exploring <a href="https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML"
            target='_blank'>Using IntegratedML</a> and the
          <a href="https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GAUTOML"
            target='_blank'>AutoML Reference</a>.
        </li>
        <li>Watching <a href="https://learning.intersystems.com/course/view.php?name=What%20is%20ML"
            target='_blank'>What is Machine Learning?</a> and
          <a href="https://learning.intersystems.com/course/view.php?name=What%20is%20IntegratedML" target='_blank'>What
            is IntegratedML?</a>.
        </li>
      </ul>
    </mat-expansion-panel>

  </mat-accordion>
</mat-card>