<div class="breadcrumbContainer mat-small">
    <span *ngFor="let breadcrumb of breadcrumbs;let lastBreadcrumb = last">
        <span *ngIf="!lastBreadcrumb" class="breadcrumbLink"><a [routerLink]="breadcrumb.url">{{breadcrumb.caption}}</a>
            &gt;</span>
        <span *ngIf="lastBreadcrumb">{{breadcrumb.caption}}</span>
    </span>
</div>
<div fxLayout="row" fxLayout.lt-md="column">
    <ng-content></ng-content>
    <button *ngIf="(breadcrumbs.length && sqlaasEnabled)" mat-stroked-button class="fr-secondary button-pad"
        (click)="performanceSupportDrawerToggle.emit()" id="btn-open-performance-support">
        {{performanceSupportDrawerOpened ? 'Close IntegratedML Orientation' : 'Open
        IntegratedML Orientation'}}</button>

    <button *ngIf="breadcrumbs.length" mat-button id="btn-refresh" color="primary" (click)="onRefresh()">
        <mat-icon>refresh</mat-icon> Refresh
    </button>
</div>