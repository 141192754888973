import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

import { MatDialog,MatDialogRef } from '@angular/material/dialog';

import { Field, IscFormModalData, VALIDATORS, FDN } from '@intersystems/isc-form';
import { SharedService } from 'src/app/shared/services/shared.service';

import { BackupList, DeploymentAddOns, DeploymentEvents, DeploymentInfo, ExternalConnection } from '../model/deployment-info';
import { ExternalConnectionDialogComponent } from '../dialogs/external-connection/external-connection-dialog.component';
import { AbstractControl } from '@angular/forms';
import { IrisConnection } from '../model/connection';
import { Deployment } from 'projects/api/src';
import { DeploymentObject } from 'api';
import { IscFormFieldInfoLabelIconComponent } from 'src/app/shared/components/isc-form-field-info-label-icon/isc-form-field-info-label-icon.component';
import { GenericDialogComponent } from '../dialogs/generic/generic-dialog.component';
import { MainMenuService } from 'src/app/core/main-menu.service';
import { SQLResponse } from '../model/sql-response';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SharedUtils } from 'src/app/shared/shared-utils';
import { DIR_DOCUMENT } from '@angular/cdk/bidi';
import { map, tap } from 'rxjs/operators';
import { IP_OR_CIDR_BLOCK } from 'regex';
import { BillingInfo } from '../model/billing-info';

export enum FormModes {
  VIEW = "view",
  EDIT = "edit"
}

@Injectable({ providedIn: 'root' })
export class IccaCommonService {


  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private sharedService: SharedService,
    private mainMenuService: MainMenuService,
  ) {}


  externalConnectionsFDN(origin):FDN {
    return {
      name: '',
      description: '',
      validateOn: 'change',
      sectionLayout: { showSectionHeaders: true, showSectionCount:false },
      sections: [
          {
          fields: [
            {
              "key": "enableExternalConnections",
              "type": "binary-checkbox",
              "hideExpression": "",
              "hide": null,
              "expressionProperties": null,
              "className": "",
              "data": {
                //"displayField": data.status,
                "content": '', //data.status,
                "hint": ''
              },
              "id": "binary-checkbox",
              hintText: this.getExternalConnectionsHintText(origin),
              hintId: 'enableExternalConnectionsHintId', // make this ID unique
              wrappers: ['generic-info-wrapper'],
              "overrideValidatorMessage": {},
              "templateOptions": {
                "label": "Enable external connections",
                "required": false,
              },
            },
            {
              key: 'infoText',
              type: 'instructions',
              id: 'infoText',
              data: {
              },
              hintText: 'Enter one or more IP addresses or CIDR ranges from which connections are \
              allowed. These can be modified at any time. If you do not specify at least one address \
              or range, all IP addresses can connect.',
              hintId: 'allowedIPAddrRangeHintId', // make this ID unique
              wrappers: ['generic-info-wrapper'],
              templateOptions: {
                label: 'Allowed IP address ranges',
              },
              className: "instruction-label"
            },
            {
            "key": "allowedCIDRs",
            "type": "repeat",
            "hideExpression": "",
            "hide": null,
            "expressionProperties": null,
            "className": "",
            "data": {
              "content": "",
              "buttons": {
                "hideAddButton": false,
                "hideRemoveButton": false,
                "addButtonText": 'Add',
                "removeButtonText": 'Remove'
              },
              "primitiveArray": true
            },
            "id": "repeat",
            "lengthControl": null,
            "templateOptions": {
            },
            "fields": [
            {
              key: 'cidrRange',
              type: 'input',
              id: 'cidrRange',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Required field',
                [VALIDATORS.PATTERN]: 'Field must be an IP (e.g. 192.168.1.0) or CIDR (e.g. 192.168.1.0/24)'
              },
              templateOptions: {
                label: 'IP address or CIDR range',
                required: true,
                pattern: IP_OR_CIDR_BLOCK,
              },
            },
          ]},

        ]
        }
      ]
    };
  }
  getExternalConnectionsHintText(origin) {
    var hintText = "Enabling external connections allows users to \
    <a href='https://learning.intersystems.com/course/view.php?name=IRISCloudConnect' target='_blank'>\
    programmatically connect an application</a> to Cloud SQL on this deployment \
    using InterSystems client drivers for \
    <a href='https://docs.intersystems.com/components/csp/docbook/DocBook.UI.Page.cls?KEY=ADRIVE' target='_blank'>\
    JDBC (Java), ODBC (C++), ADO.NET (.NET), and DB-API (Python)</a>. ";

    if (origin=='create') {
      hintText=hintText + "You can also enable connections once the deployment is running. ";
    }
    hintText=hintText + "You can limit external connections by requiring TLS encryption and specifying the \
    IP addresses and ranges from which connections can be made.";

    return hintText;
  }

  changePwdFDN(): FDN {
    return {
      name: '',
      description: '',
      validateOn: 'change',
      sectionLayout: { showSectionHeaders: false },
      sections: [
        {
          fields: [
            {
              key: 'infoText',
              type: 'instructions',
              id: 'infoText',
              data: {
                content: 'The IRIS password you provide here will be required for access to the deployment in the Cloud Services Portal and by programmatic connections. Be sure to store it securely so you do not lose access to the deployment.',
              },
            },
            {
              key: 'oldPassword',
              type: 'input',
              id: 'oldPassword',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Old password is a Required Field',
              },
              templateOptions: {
                type: 'password',
                label: 'Old password',
              },
              data: {
                displayField: 'name'
              },
            },
            {
              key: 'newPassword',
              type: 'input',
              id: 'newPassword',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Required field',
              },
              templateOptions: {
                type: 'password',
                label: 'New password',
                requirements: this.sharedService.passwordRequirements(),
              },
              data: {
                displayField: 'name'
              }
            },
            {
              key: 'confirmNewPassword',
              type: 'input',
              id: 'confirmNewPassword',
              overrideValidatorMessage: {
                [VALIDATORS.ISC_REQUIRED]: 'Required field',
              },
              validators: {
                ['matching'] : {
                  expression: (control: AbstractControl) => {
                    if (control.value){
                      return (control.value === control.root.value['newPassword']);
                    }
                  },
                  message: (error: any, field: Field) => 'Passwords do not match'
                }
              },
              templateOptions: {
                type: 'password',
                label: 'Confirm new password',
                required: true,
              }
            }
          ]
        }
      ]
    };
  }

  openExternalConnectionsModal(data:ExternalConnection, callback): void {
    const modalData: IscFormModalData = {
        modalTitle: 'External Connections',
        iscFormInputs: {
        Id: 'externalConnections',

        FDN: this.externalConnectionsFDN('update'),
        formModel:  data,
        mode: FormModes.EDIT,
        formConfig: {},
        buttons: [
            {
            id: 'cancel',
            text: 'Cancel',
            buttonClass: 'tertiary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
            {
            id: 'save',
            text: 'Save',
            buttonClass: 'primary',
            disabledIfFormInvalid: true,
            disabledIfFormPristine: true,
            type: 'submit',
            callback: (clickEvent: any, button: any, formModel: ExternalConnection, formOptions: any, form: any) => {
                callback(formModel, form, dialogRef);
            }
            },
        ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
        ExternalConnectionDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }

  openPasswordChangeModal(data:ExternalConnection, callback): void {
    const modalData: IscFormModalData = {
        modalTitle: 'Change IRIS Password',
        iscFormInputs: {
        Id: 'changePassword',

        FDN: this.changePwdFDN(),
        formModel:  data,
        mode: FormModes.EDIT,
        formConfig: {},
        buttons: [
            {
            id: 'cancel',
            text: 'Cancel',
            buttonClass: 'tertiary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
            {
            id: 'save',
            text: 'Confirm Password Change',
            buttonClass: 'primary',
            disabledIfFormInvalid: true,
            disabledIfFormPristine: true,
            type: 'submit',
            callback: (clickEvent: any, button: any, formModel: ExternalConnection, formOptions: any, form: any) => {
                callback(formModel, form, dialogRef);
            }
            },
        ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
        ExternalConnectionDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }

  openEnableIntegratedMLModal(data:DeploymentAddOns, callback): void {
    const modalData: IscFormModalData = {
        modalTitle: 'Enable IntegratedML',
        iscFormInputs: {
        Id: 'frm-enable-iml',
        FDN: {
          name: '',
          description: '',
          validateOn: 'change',
          sectionLayout: { showSectionHeaders: false },
          sections: [
            {
              fields: [
                {
                  "key": "integratedMLEnabled",
                  "type": "binary-checkbox",
                  "hideExpression": "",
                  "hide": null,
                  "expressionProperties": null,
                  "className": "",
                  "data": {
                    //"displayField": data.status,
                    "content": '', //data.status,
                    "hint": ''
                  },
                  "id": "chk-integratedMLEnabled",
                  hintText: "Enable <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=GIML_Intro' \
                  target='_blank'>IntegratedML</a>, which lets users without extensive ML experience apply \
                  automated machine learning functions directly from SQL to create and use predictive models. \
                  (Enabling this feature is permanent, and incurs additional cost.)",
                  wrappers: ['generic-info-wrapper'],
                  "overrideValidatorMessage": {},
                  "templateOptions": {
                    "label": "Enable IntegratedML",
                    "required": false,
                  },
                },

              ]
            }
          ]
        },
        formModel:  data,
        mode: FormModes.EDIT,
        formConfig: {},
        buttons: [
            {
            id: 'cancel',
            text: 'Cancel',
            buttonClass: 'tertiary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
            {
            id: 'save',
            text: 'Enable IntegratedML',
            buttonClass: 'primary',
            disabledIfFormInvalid: true,
            disabledIfFormPristine: true,
            type: 'submit',
            callback: (clickEvent: any, button: any, formModel: DeploymentAddOns, formOptions: any, form: any) => {
                callback(formModel, form, dialogRef);
            }
            },
        ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }




  changePassword$(deployment:DeploymentObject, connectionInfo:IrisConnection, username,newPassword): Observable<any> {
    const url = `${this.getAPIURL(deployment.deploymenttype, deployment.region)}/sql/query`;
    var data = {
      deploymentId: deployment.deploymentid,
      "sql": {
        query: `ALTER USER ${username} IDENTIFIED BY '${newPassword}'`,
        maxRows:"100000",
        maxLength:"4000000"
      },
      "connection": connectionInfo
    };
    return this.http.post(url,JSON.stringify(data),{headers: new HttpHeaders({ 'Content-Type': 'application/json' })})

  }


  private _deploymentInfo = new BehaviorSubject<DeploymentInfo>(null);
  private _reloadDeploymentInfo$ = new BehaviorSubject<void>(null);

  get deploymentInfo$(): Observable<any> {
    return this._deploymentInfo.asObservable();
  }

  get deploymentInfo(): DeploymentInfo {
    return this._deploymentInfo.value;
  }

  loadDeploymentInfo$(deploymentType:string, deploymentId:string, region:string): Observable<DeploymentInfo> {
    const url = `${this.getAPIURL(deploymentType, region)}/cluster/info/${deploymentId}`;
    return this.http.get<any>(url).pipe(
      map(deploymentInfo => {
        return deploymentInfo;
      }),
      tap(deploymentInfo => this._deploymentInfo.next(deploymentInfo))
    );
  }

  private _deploymentEvents = new BehaviorSubject<DeploymentInfo>(null);
  private _reloadDeploymentEvents$ = new BehaviorSubject<void>(null);

  get deploymentEvents$(): Observable<any> {
    return this._deploymentEvents.asObservable();
  }

  get deploymentEvents(): DeploymentInfo {
    return this._deploymentEvents.value;
  }

  loadDeploymentEvents$(deploymentType:string, deploymentId:string, region: string): Observable<DeploymentEvents> {
    const url = `${this.getAPIURL(deploymentType, region)}/cluster/events/${deploymentId}`;
    return this.http.get<any>(url).pipe(
      tap(deploymentEvents => this._deploymentEvents.next(deploymentEvents))
    );
  }

  private _billingInfo = new BehaviorSubject<BillingInfo>(null);
  private _reloadBillingInfo$ = new BehaviorSubject<void>(null);

  get billingInfo$(): Observable<any> {
    return this._billingInfo.asObservable();
  }

  get billingInfo(): BillingInfo {
    return this._billingInfo.value;
  }

  loadBillingInfo$(deploymentType:string): Observable<BillingInfo> {
    const url = `${this.getAPIURL(deploymentType)}/billing`;
    return this.http.get<any>(url).pipe(
      map(billingInfo => {
        return billingInfo;
      }),
      tap(billingInfo => this._billingInfo.next(billingInfo))
    );
  }
  reloadBillingInfo() {
    this._reloadBillingInfo$.next();
  }

  updateIML(deploymentType, deploymentId) {
    this.mainMenuService.imlEnabled = (this.deploymentInfo?.info.deploymentType=='iml');
    this.mainMenuService.updateMenu();

  }

  callObjScript(deploymentType:string, deploymentid:string, region:string, connectionInfo:IrisConnection,classname:string,method:string,type:string,args:string[]): Observable<any> {

    const url = `${this.getAPIURL(deploymentType, region)}/sql/objscript`;

    var data:any = {
      "deploymentId": deploymentid,
      "classmethod": {
        "type": type,
        "class": classname,
        "method": method,
        "args": args,
      },
      connection: connectionInfo
    };

    return this.http.post(url,JSON.stringify(data),{headers: new HttpHeaders({ 'Content-Type': 'application/json' })})

  }




  openScalingModal(data:any, callback): void {
    const modalData: IscFormModalData = {
        modalTitle: 'Resize deployment',
        iscFormInputs: {
        Id: 'resizeInput',

        FDN: this.scalingInputFDN(),
        formModel:  data,
        mode: FormModes.EDIT,
        formConfig: {},
        buttons: [
          {
          id: 'cancel',
          text: 'Cancel',
          buttonClass: 'tertiary',
          type: 'button',
          callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
              dialogRef.close();
          }
          },
          {
          id: 'resize',
          text: 'Resize',
          buttonClass: 'primary',
          disabledIfFormInvalid: true,
          //disabledIfFormPristine: true,

          type: 'submit',
          callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
            callback(formModel, form, dialogRef);
            }
          },
        ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }

  scalingInputFDN():FDN {
    return {
      name: '',
      validateOn: 'change',
      sectionLayout: { showSectionHeaders: false },
      sections: [
          {
          fields: [
            {
              id: 'scalingForm',
              key: 'scalingForm',
              //type: 'instructions',
              type: 'input',
              //templateOptions: {
                //label: 'Resize deployment',
              //},
              data: {
                displayField: 'name',
                uniqueValueField: 'name',
                optionIdField: 'name',
              },
              wrappers: ['scaling-wrapper'],
            },
          ]
        }
      ]
    };
  }

  openBackupRestoreModal(data:any, callback): void {
    const modalData: IscFormModalData = {
        modalTitle: 'Restore deployment from backup',
        iscFormInputs: {
        Id: 'restoreInput',

        FDN: this.backupRestoreInputFDN(),
        formModel:  data,
        mode: FormModes.EDIT,
        formConfig: {},
        buttons: [
          {
          id: 'cancel',
          text: 'Cancel',
          buttonClass: 'tertiary',
          type: 'button',
          callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
              dialogRef.close();
          }
          },
          {
          id: 'restore',
          text: 'Restore',
          buttonClass: 'primary',
          disabledIfFormInvalid: true,
          //disabledIfFormPristine: true,

          type: 'submit',
          callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
            callback(formModel, form, dialogRef);
            }
          },
        ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }

  backupRestoreInputFDN():FDN {
    return {
      name: '',
      validateOn: 'change',
      sectionLayout: { showSectionHeaders: false },
      sections: [
          {
          fields: [
            {
              id: 'restoreForm',
              key: 'restoreForm',
              //type: 'instructions',
              type: 'input',
              //templateOptions: {
                //label: 'Resize deployment',
              //},
              data: {
                displayField: 'name',
                uniqueValueField: 'name',
                optionIdField: 'name',
              },
              wrappers: ['scaling-wrapper'],
            },
          ]
        }
      ]
    };
  }

  toggleDeployment(deploymentType: string, deploymentId: string, region:string, action):Observable<any> {
    const url = `${this.getAPIURL(deploymentType, region)}/cluster/${action=='shut down' ? 'stop' : 'start'}/${deploymentId}`;
    return this.http.post(url, {});
  }


  formatDate(dateFromIRIS,dateOnly=false) {
    //if date is a string contain the text 'null' which is returned from the query, then return an empty string
    if (dateFromIRIS=='null') {
      return '';
    }
    var date = new Date(dateFromIRIS);
    //try formatting the date replacing - with / for safari
    if (!(date instanceof Date && !isNaN(date.valueOf()))) {
      date = new Date(dateFromIRIS.split(".")["0"].replace(/-/g, "/"));
    }
    return dateOnly ? date.toDateString() : date.toLocaleString();
  }

  getAPIURL(deploymentType, region?) {
    if (typeof region == "undefined") {
        //default to us-east-1 if no region is specified
        region = environment['ICCA_REGIONS'] ? environment['ICCA_REGIONS'][0] : 'us-east-1';
    }
    if (deploymentType=='sds') {
      return environment['OFFER_API'][deploymentType];
    }

    return `https://${region}.${environment['ICCA_STAGE']}.${environment['ICCA_ACCOUNT']}.isccloud.io`;
    //return `https://${environment['ICCA_STAGE']}.${this.getAWSRegionAcronym(region)}.ic.${environment['ICCA_ACCOUNT']}.isccloud.io`;

  }

  getAWSRegionAcronym(region) {
    switch (region) {
      case 'us-east-1':
        return 'use1';
      case 'us-east-2':
        return 'use2';
      case 'us-west-1':
        return 'usw1';
      case 'us-west-2':
        return 'usw2';
      case 'eu-central-1':
        return 'euc1'
      default:
        console.error("Unknown region: " + region);
        return ''
    }
  }
}


