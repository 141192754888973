import { TranslateLoader } from '@ngx-translate/core';
import { Observable, from, of } from 'rxjs';

export class WebpackTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    // This assumes a public folder containing translation files as LOCALE.json
    // in the directory src/app/assets/i18n
    return of(''); //from(import('../../assets/i18n/translate.json'));
  }
}
