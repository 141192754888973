<fr-topbar #topbar [appNameConfig]="appNameConfig" [headerConfig]="headerConfig" [menusConfig]="menusConfig">
  <div class="main">
    <ng-progress></ng-progress>
    <lib-spinner></lib-spinner>
    <fr-notification></fr-notification>

    <div class="columns">
      <div class="left-column">
        <fr-left-menu #leftMenu [config]="treeConfig" *ngIf="showMenu$ | async">
        </fr-left-menu>
      </div>
      <div class="right-column" [ngClass.xs]="['right-column-mobile']">
        <mat-drawer-container class="drawer-container" autosize=true hasBackdrop="false">

          <mat-drawer-content class="drawer-content">
            <app-breadcrumbs [performanceSupportDrawerOpened]="performanceSupportDrawer.opened"
              (performanceSupportDrawerToggle)="performanceSupportDrawer.toggle()">
              <app-tenant-switcher [currentTenantId]='currentTenantId$ | async'
                [userTenantsOptions]='userTenantsOptions' (switchTenantId)='onSwitchTenantId($event)'
                *ngIf='user$ | async'></app-tenant-switcher>
            </app-breadcrumbs>

            <router-outlet></router-outlet>
          </mat-drawer-content>


          <mat-drawer #performanceSupportDrawer class="right-drawer" mode="side" position="end"
            (keydown.escape)="performanceSupportDrawer.close()">

            <app-performance-support (closeDrawer)="performanceSupportDrawer.close()"></app-performance-support>

          </mat-drawer>


        </mat-drawer-container>
      </div>
    </div>
    <app-footer></app-footer>
  </div>
</fr-topbar>