import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SQLQueryService } from '../../sql-query/sql-query.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent, ConfirmationDialogConfig } from '@intersystems/confirmation-dialog';
import { ImportResponse, ImportTableConfig } from 'src/app/deployments/icca-common/model/sql-response'; 
import { NotificationService } from '@intersystems/notification';
import { ImportService } from '../import.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { DeploymentObject } from 'api';
import { DeploymentsService } from 'src/app/deployments/deployments.service';

@Component({
  selector: 'app-import-csv-details',
  templateUrl: './import-csv-details.component.html',
  styleUrls: ['./import-csv-details.component.scss'],
})
export class ImportCsvDetailsComponent implements OnInit {
  @Input() filename = ''; // decorate the property with @Input()
  @Output() fileEvents = new EventEmitter<string>();

  tableData = [];
  hasHeaders = false;
  headersMatch = false;
  selectedTable = '';
  loadInProgress = false;
  response: ImportResponse[] = [];

  lineSeparatorData = ['\\n', '\\r', '\\r\\n'];
  lineSeparator = '\\n';
  isColSeparatorInvalid: boolean = false;
  skipRows = 0;
  showColumns=false;
  colSeparatorControl: FormControl;

  colList = [];

  infoObject = {
    table: {
      infoTitle: 'Select table',
      //infoText: 'Select any existing table in the namespace you are connected to.'
      htmlText: "Select any existing table in the namespace you are connected to. You can review these in the Schema Tree \
      pane on the SQL Query Tools page."
    },
    headers: {
      infoTitle: 'Import file has header row',
      htmlText: "Select if the first row of the CSV file contains field names instead of data. If there any \
      additional rows between the header row and the first data row, be sure to indicate this below. \
      For details see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_loaddata'  target='_blank'>LOAD DATA</a>."
    },
    headersMatch: {
      infoTitle: 'Field names in header row match column names in selected table',
      htmlText: "Select if the field names in the import file's header row exactly match the column names \
      in the target table (case excepted). When this is true, the fields in the file and the columns in the \
      table are automatically linked, regardless of the order of field names or columns. \
      For details see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_loaddata'  target='_blank'>LOAD DATA</a>."
    },
    columns: {
      infoTitle: 'Column order in file',
      htmlText: "If the import file has no header row or the field names in the header row don't exactly match \
      the column names, expand the column list, reorder the table column icons to match the field order in the \
      CSV file, and remove any column icons with no corresponding field in the file. \
      The leftmost field in the file corresponds to the topmost column icon.  To return the column icons to their \
      order in the table, press <b>Reset Columns</b>. For details see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_loaddata'  target='_blank'>LOAD DATA</a>."
    },
    options: {
      infoTitle: 'LOAD DATA options',
      htmlText: "Indicate how many empty rows there are after the header so import can begin with the first populated row, \
      and identify the line and column separators used in the CSV file; for details, see \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_loaddata'  target='_blank'>LOAD DATA</a>."
    },
  };

  constructor(
    private sqlQueryService: SQLQueryService,
    private dialog: MatDialog,
    private importService: ImportService,
    private notificationSvc: NotificationService,
    private route: ActivatedRoute,
    private deploymentsService: DeploymentsService,
  ) {}

  ngOnInit(): void {
    this.loadTables();
    this.colSeparatorControl = new FormControl(',', [this.validateColSeparator.bind(this)]);
  }

  loadTables() {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    //load tables into dropdown
    const schemaQuery =
      "SELECT TABLE_SCHEMA,TABLE_NAME FROM INFORMATION_SCHEMA.TABLES WHERE NOT TABLE_SCHEMA %STARTSWITH '%' AND NOT TABLE_SCHEMA %STARTSWITH 'ENS' AND TABLE_SCHEMA <> 'INFORMATION_SCHEMA'";

    this.tableData = [];

    this.sqlQueryService.executeSQLStatement(deployment, schemaQuery).subscribe(sqlResponse => {
      this.tableData = sqlResponse['resultSet'].data;
    });
    return;
  }

  tableSelected($event) {
    //event will fire twice when selection is changed.
    //The _selected property will be true for the item that was selected.
    if ($event.source._selected) {
      //load fields into an input box
      const selected = $event.source.value.toString();
      this.selectedTable = selected;
      this.loadColumns();
    }
  }

  loadColumns() {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    const tableName = this.selectedTable;
    const split = tableName.split('.');
    this.colList = [];

    const schemaQuery = `SELECT COLUMN_NAME, DATA_TYPE, CHARACTER_MAXIMUM_LENGTH, IS_NULLABLE, IS_IDENTITY  FROM INFORMATION_SCHEMA.COLUMNS WHERE TABLE_SCHEMA='${split[0]}' AND TABLE_NAME='${split[1]}'`;

    this.sqlQueryService.executeSQLStatement(deployment, schemaQuery).subscribe(sqlResponse => {
      sqlResponse['resultSet'].data.forEach(col => {
        let display: string;
        display = col[0]; //name

        if (col[4] == 'YES') {
          display = display + ' IDENTITY ';
        } else {
          display = display + ` ${col[1].toUpperCase()}`; //name and datatype
          //max length
          if (col[2] != 'null') {
            display = display + `(${col[2]})`;
          }
          //is nullable
          if (col[3] == 'NO') {
            //display=display + ' NOT NULL ';
          }
        }
        this.colList.push({
          name: col[0],
          dataType: col[1],
          maxLength: col[2],
          isNullable: col[3],
          isIdentity: col[4],
          display: display,
        });
      });
    });
    return;
  }

  getColumnLabel() {
    if (this.showColumns) {
      return 'Drag and drop to reorder columns.'; 
    } else {
      if (this.selectedTable=='') {
          return 'You must first select a table to load columns.';
      } else {
        return 'Click to expand column list.';
      }
    }
  }
  drop(event: CdkDragDrop<any[]>) {
    moveItemInArray(this.colList, event.previousIndex, event.currentIndex);
  }
  remove(col: any): void {
    const index = this.colList.indexOf(col);

    if (index >= 0) {
      this.colList.splice(index, 1);
    }
  }

  importFile() {
    const dialogConfig = new MatDialogConfig();

    const dialogText = 'Are you sure you wish to import the selected CSV file?';

    dialogConfig.data = {
      title: 'Loading',
      primary: dialogText,
      buttons: {
        primary: {
          text: 'Import',
        },
        secondary: {
          text: 'Cancel',
        },
      },
    };
    dialogConfig.panelClass = 'fr-layout-wrapper-mat-dialog-panel';

    // Use the open() API to instantiate the modal
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
    // Subscribe to the dialog's afterclosed() API to get the response
    dialogRef.afterClosed().subscribe(response => {
      if (response && response.button) {
        switch (response.button) {
          case 'primary':
            const tableConfig: ImportTableConfig = {
              table: this.selectedTable,
              hasHeaders: this.hasHeaders,
              useHeaders: this.headersMatch,
              colList: this.colList,
              colSeparator: this.colSeparatorControl.value,
              lineSeparator: this.lineSeparator,
              skipRows: this.skipRows,
            };
            this.importCSVFile(this.filename, tableConfig);
            break;
          case 'secondary':
            console.log(`User confirmed secondary`);
            break;
          case 'tertiary':
            console.log(`User confirmed tertiary`);
            break;
        }
        if (response.checked) {
          console.log(`User checked the checkbox`);
        }
      }
    });
  }

  importCSVFile(filename, tableConfig: ImportTableConfig) {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );

    //var filenameJSON = `{"filename": "` + filename + `"}`;
    this.loadInProgress = true;
    var inProgressData;
    var sql:string = this.importService.getLOADDATAStatement(filename,tableConfig);
    this.sqlQueryService.executeSQLStatement(deployment, `CALL IRISCloud.SQLUtils_ExecuteSQL('${sql}','1','')`)
    .subscribe((response: any) => {
      let status:string;
      this.response = [];
      status= 'Load in progress';
      inProgressData={
        query:`SELECT TOP 1 resultID,status,inputRecordCount,errorCount FROM \
        %SQL_DIAG.Result WHERE $piece($piece(statement,' FILE ',2),'''',2) \
        = '/irissys/data/uploads/${filename}' ORDER BY resultID DESC`,
        deployment: deployment
      };
      
      this.response.push({
        fileName: filename,
        output: status,
        table: tableConfig.table,
        inProgressData:inProgressData
      });
      this.notificationSvc.showInfo('Importing file: ' + filename, 2000);
      this.loadInProgress = false;
      this.fileEvents.emit('imported');
    });
  }

  validateColSeparator(control: FormControl): {[key: string]: any} | null {
    const input = control.value;
    let interpretedInput: string;

    try {
      // This will interpret escaped characters
      interpretedInput = JSON.parse(`"${input}"`);
    } catch (e) {
      interpretedInput = input;
    }

    const asciiCharacterRegex = /^[\x00-\x7F]$/;
    return asciiCharacterRegex.test(interpretedInput) ? null : { 'invalidColSeparator': true };
  }
}
