<app-section header="Version Data">
  <p><b>Commit ID:</b> {{versionData.commit}}</p>
  <p><b>Built at:</b> {{versionData.timestamp}}</p>
  <p><b>Branch:</b> {{versionData.branch}}</p>
</app-section>
<app-section header="Launch Resources">
  <h3> Base Images </h3>
  <p><b>MAM:</b> {{launchResources?.mam}}</p>
  <p><b>Arbiter:</b> {{launchResources?.arbiter}}</p>
  <h3> FHIRaaS </h3>
  <p><b>IRIS:</b> {{launchResources?.fhiraas}}</p>
</app-section>
<app-section header="Reference Architecture">
  <mat-tab-group animationDuration="0ms">
    <mat-tab label="Database">
      <img src="/assets/images/deployments/dbaas.png">
    </mat-tab>
    <mat-tab label="FHIR">
      <img src="/assets/images/deployments/fhiraas.png">
    </mat-tab>
    <mat-tab label="FTS">
      <img src="/assets/images/deployments/fts.png">
      Currently processing for an individual file consists of these steps.
      <ol dir="auto">
        <li>User uploads file into a source S3 bucket.</li>
        <li>
          S3 Event notification picks a file and sends it to SQS
          queue <code>&lt;deploymentid&gt;-transform</code> owned by InterSystems.
        </li>
        <li>Transform Task (IRIS) picks up messages from
          <code>&lt;deploymentid&gt;-transform</code> queue.
        </li>
        <li>Transform Task (IRIS) gets
          file contents from the source bucket</li>
        <li>Transform Task:
          <ul>
            <li>Converts file to FHIR</li>
            <li>Writes result meta-info into DynamoDB
              <code>deploymentid</code> table
            </li>
            <li>Writes the result into <code>deploymentid-buffer</code> bucket.
            </li>
          </ul>
        </li>
        <li>
          Post-processing dispatcher lambda gets called every minute.
        </li>
        <li>Post-processing dispatcher lambda queues DynamoDB for all partitions
          (hash of patientid and faculty) which are in <code>TRANSFORM_COMPLETE</code> state (meaning successfully
          transformed by transformed lambda) and sends each partition to <code>deploymentid-postprocessing</code>
          queue.
        </li>
        <li>
          Post-processing lambda receives events from <code>deploymentid-postprocessing</code> queue.
        </li>
        <li>Post processing lambda gets partition from the event and gets
          all files from the partition.</li>
        <li>
          For each file the post processing lambda:
          <ul>
            <li>Picks file from <code>deploymentid-buffer</code> s3 bucket (based on
              partition from the event and DynamoDB info).</li>
            <li>Sends file to the destination bucket (in S3 mode) or HealthLake or FHIR
              server (in FHIR mode, using PostProcessing Task)</li>
            <li>Marks record in DynamoDB as <code>POST_PROCESS_COMPLETE</code>.</li>
            <li>Deletes file from <code>deploymentid-buffer</code> s3 bucket.</li>
          </ul>
        </li>
      </ol>
    </mat-tab>
    <mat-tab label="HCC">
      <img src="/assets/images/deployments/hcc.png">
    </mat-tab>
    <mat-tab label="SCC">
      <img src="/assets/images/deployments/hcc.png">
    </mat-tab>
    <mat-tab label="Platform">
      <img src="/assets/images/deployments/paas.png">
    </mat-tab>
    <mat-tab label="Portal">
      <img src="/assets/images/deployments/portal.png">
    </mat-tab>
    <mat-tab label="IRIS Build">
      <img src="/assets/images/deployments/packer.png">
    </mat-tab>
    <mat-tab label="Management and Alerting">
      <img src="/assets/images/deployments/mam.png">
    </mat-tab>
  </mat-tab-group>
</app-section>