<h1>Deployments</h1>

<button mat-raised-button color="primary" (click)="create.emit()" id="btn-create-deployment">
  Create New Deployment
</button>

<div class="depContainer">
  <div *ngFor="let deployment of getDeployments(true); let i = index">
    <app-deployment-card
      [deployment]="deployment"
      [currentTenantData]="currentTenantData"
      (deleted)="deleted.emit(deployment.deploymentid)"
      (selected)="selected.emit(deployment.deploymentid)"
      (servicelevel)="servicelevel.emit(deployment.deploymentid)"
      (update)="update.emit(deployment.deploymentid)"
    >
    </app-deployment-card>
  </div>
</div>
<div class="depContainer">
  <div *ngFor="let deployment of getDeployments(false); let i = index">
    <app-deployment-card
      [deployment]="deployment"
      [currentTenantData]="currentTenantData"
      (deleted)="deleted.emit(deployment.deploymentid)"
      (selected)="selected.emit(deployment.deploymentid)"
      (servicelevel)="servicelevel.emit(deployment.deploymentid)"
      (update)="update.emit(deployment.deploymentid)"
    >
    </app-deployment-card>
  </div>
</div>