import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../../shared/services/shared.service';
import { OffersService, TrialOffer } from 'api';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';
import { Subscription } from '../../../core/interfaces/subscription';
import { SUBSCRIPTIONS_DATA } from '../../../shared/subscriptions-data';
import { of } from 'rxjs';
import { AuthService } from 'src/app/core/auth.service';
import { RequestComponent } from '../../dialogs/request.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-service-overview-page',
  templateUrl: './service-overview-page.component.html',
  styleUrls: ['./service-overview-page.component.scss'],
})
export class ServiceOverviewPageComponent implements OnInit {
  public subscription: Subscription;
  public trialOfferType: string;
  public trialOfferData: any;

  private subscriptions = SUBSCRIPTIONS_DATA;

  constructor(
    private activatedRoute: ActivatedRoute,
    private offersService: OffersService,
    private sharedService: SharedService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.sharedService.refresh$
      .pipe(
        switchMap(() => this.activatedRoute.queryParams),
        map(params => (params.trial ? (this.trialOfferType = params.trial) : null)),
        switchMap(() => {
          if (this.trialOfferType)
            return this.offersService.getTrialOffers(this.trialOfferType).pipe(
              map((data: TrialOffer) => {
                this.trialOfferData = data;

                this.trialOfferData.terms = Object.entries(this.trialOfferData.terms)
                  .filter(([key]) =>
                    ['max_deployments', 'authorized_cloudproviders', 'offer_capabilities'].includes(key),
                  )
                  .map(([key, value]) => {
                    if (key === 'offer_capabilities') {
                      const flattened = this.sharedService.flattenNestedObj(value);
                      const flattenedValues = flattened.map(({ key, value }) => `${key}: ${value}`);
                      return { key: 'Offer Capabilities', value: flattenedValues.join(', ') };
                    } else {
                      return key === 'max_deployments'
                        ? { key: 'Max deployments allowed', value: String(value) }
                        : { key: 'Cloud providers allowed', value: String(value) };
                    }
                  });
              }),
            );
          return of('');
        }),
      )
      .subscribe();

    this.subscription = this.subscriptions.find(el => {
      if (el.productCode === this.activatedRoute.snapshot.paramMap.get('productCode')) {
        return el;
      }
    });
  }

  subscribeTrial(tenantid: string): void {
    this.offersService
      .subscribeUser('isc', {
        cloudprovider: this.trialOfferData.cloudprovider,
        tenantid: tenantid,
        terms: 'trial',
        offerid: this.trialOfferData.offer,
      })
      .pipe(
        tap(() => this.sharedService.showSuccess('Successfully subscribed')),
        switchMap(() => this.authService.authorise('', '')),
        tap(() => this.router.navigate(['/cloudservices'])),
        this.sharedService.catchError(),
      )
      .subscribe();
  }
  onRequestAccess(data: { name: string; request: string }): void {
    const dialogRef = this.dialog.open(RequestComponent, {
      data,
    });
    dialogRef
      .afterClosed()
      .pipe(
        filter(result => result),
        switchMap(feedback => this.authService.submitFeedback(data.name, feedback)),
        tap(() => this.sharedService.showSuccess('Thank you for your request, our team will contact you.')),
        catchError(() =>
          of(
            this.sharedService.showAlert(
              'Error submitting request. Please send an email to portal-dev@isccloud.io detailing your case.',
            ),
          ),
        ),
      )
      .subscribe();
  }
}
