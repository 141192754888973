<mat-card [ngClass]="{'loading-data': loadInProgress}">
    <mat-card-header *ngIf="fileManagement">
        <mat-card-title>Add and Manage Files
            <app-info-button [htmlText]="info.htmlText" [title]="info.infoTitle"></app-info-button>
        </mat-card-title>

    </mat-card-header>

    <fr-table [config]="tableConfig" [(data)]="files" [safeMode]="true" [paginatorConfig]="paginatorConfig"
        Id="import-files-table">
        <div class="custom-table-title" table-title-text-space>
            <app-info-button *ngIf="!fileManagement" [htmlText]="info.htmlText"
                [title]="info.infoTitle"></app-info-button>
            <span *ngIf="!fileManagement" class="additional-title-text">
                Add files you want to import on the
                <button id="btn-file-management-link" class="button-link" color="primary"
                    (click)="routeToFileManagement(deploymentId)" [disabled]='loadInProgress'>Manage Files</button>
                page
            </span>
        </div>

        <div class="custom-table-header-space" table-header-space>
            <button *ngIf="fileManagement" id="btn-upload" mat-flat-button class="fr-primary" color="primary"
                (click)="launchFileInput()" [disabled]='loadInProgress'>
                Upload
                <mat-icon>file_upload</mat-icon>
            </button>
            <button *ngIf="fileManagement" id="btn-s3-transfer" mat-flat-button class="fr-primary" color="primary"
                (click)="launchExternalObjectInput()" [disabled]='loadInProgress'>
                <mat-icon style="transform: rotate(270deg)">file_upload</mat-icon>
                External transfer
            </button>

            <button id="btn-import-refresh" mat-flat-button class="fr-primary" color="primary" (click)="getFiles()"
                [disabled]='loadInProgress'>
                <mat-icon>refresh</mat-icon>
                Refresh
            </button>

            <input #fileInput type="file" class="hide" multiple (change)="fileHandler($event.target.files)"
                style="display:none;" [accept]="acceptFilter" id='input-upload-files' />

        </div>

        <div class="table-sub-header" above-table-body-space>
            <mat-spinner *ngIf="loadInProgress" [diameter]="30"></mat-spinner>
        </div>
    </fr-table>

    <mat-card-actions *ngIf="!fileManagement">
        <button id="upload-step-before" mat-stroked-button matStepperPrevious class="fr-tertiary button-padding"
            color="primary">
            <mat-icon>navigate_before</mat-icon>
            Back
        </button>
        <button id="upload-step-next" mat-flat-button #nextButton2 class="fr-primary button-padding" color="primary"
            matStepperNext [disabled]="!selectedFilename" *ngIf="importType=='importCSV'">
            Next
            <mat-icon>navigate_next</mat-icon>
        </button>

        <button id="upload-import-files" mat-flat-button class="fr-primary" color="primary" (click)="importFiles()"
            [disabled]='loadInProgress || rowsChecked.length === 0' *ngIf="importType=='importDDL'">
            Import Files
            <mat-icon>navigate_next</mat-icon>
        </button>

    </mat-card-actions>

    <!--
    <div #externalTransferSection>
        <app-sqlaas-external-object-storage-table [configs]="configs$ | async" [objects]="objects$ | async"
            [showField]="showField" (deleteExternalObjectConfig)="deleteExternalObjectConfig($event)"
            (addExternalObjectConfig)="addExternalObjectConfig($event)"
            (transferExternalObjects)="transferExternalObjects($event)"
            (loadExternalObjects)="loadExternalObjects($event)">
        </app-sqlaas-external-object-storage-table>
    </div>
    -->
</mat-card>