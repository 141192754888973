import { Component } from '@angular/core';
import { FormButton, Field } from '@intersystems/isc-form';
import { FieldWrapper } from '@ngx-formly/core';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-isc-form-field-inline-buttons-wrapper',
  templateUrl: './isc-form-field-inline-buttons-wrapper.component.html',
  styleUrls: ['./isc-form-field-inline-buttons-wrapper.component.scss'],
})
export class IscFormFieldInlineButtonsWrapperComponent extends FieldWrapper {
  field: Field;

  constructor() {
    super();
  }

  isButtonDisabled(button: FormButton): boolean | Observable<boolean> {
    const formInvalidCondition = button?.disabledIfFormInvalid && this.form.invalid;
    const formPristineCondition = button?.disabledIfFormPristine && this.form.pristine;

    // default to not be disabled
    let formExpressionCondition: boolean | Observable<boolean> = false;

    if (button && typeof button.disabled === 'function') {
      formExpressionCondition = button.disabled(this.model, this.options, this.form);
    }

    return formInvalidCondition || formPristineCondition || formExpressionCondition;
  }
}
