import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DeploymentObject } from 'api';

@Component({
  selector: 'app-deployments-list',
  templateUrl: './deployments-list.component.html',
  styleUrls: ['./deployments-list.component.scss'],
})
export class DeploymentsListComponent {
  @Input() deployments: DeploymentObject[] = [];
  @Input() currentTenantData: any;

  @Output() deleted = new EventEmitter<string>();
  @Output() selected = new EventEmitter<string>();
  @Output() create = new EventEmitter<any>();
  @Output() servicelevel = new EventEmitter<any>();
  @Output() update = new EventEmitter<any>();

  isActive(deployment) {
    if (!deployment) return false;
    
    if (deployment.deploymenttype == 'sqlaas') return true;
    if (deployment.deploymenttype == 'sds') return true;
    if (deployment.deploymenttype == 'doc') return true;
    if (deployment.deploymenttype == 'fhirserver') return true;
    
    // if (deployment.deploymenttype == 'fhiraas')
    //   return deployment.status == 'COMPLETE' && deployment.service_status == 'RUNNING';
    return deployment.status == 'COMPLETE';
  }

  getDeployments(isActive: boolean) {
    return this.deployments.filter(d => this.isActive(d) == isActive);
  }

  // Improve rendering performance since you are calling the get deployments list on an interval.
  deploymentTrackBy(index: number, deployment: DeploymentObject) {
    return deployment.deploymentid
  }
}
