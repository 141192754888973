export * from './admin.service';
import { AdminService } from './admin.service';
export * from './adminKey.service';
import { AdminKeyService } from './adminKey.service';
export * from './deployment.service';
import { DeploymentService } from './deployment.service';
export * from './offers.service';
import { OffersService } from './offers.service';
export * from './operations.service';
import { OperationsService } from './operations.service';
export * from './sitreps.service';
import { SitrepsService } from './sitreps.service';
export * from './updateSystem.service';
import { UpdateSystemService } from './updateSystem.service';
export * from './users.service';
import { UsersService } from './users.service';
export const APIS = [AdminService, AdminKeyService, DeploymentService, OffersService, OperationsService, SitrepsService, UpdateSystemService, UsersService];
