import { Component, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';

import { SQLResponse } from 'src/app/deployments/icca-common/model/sql-response'; 
import { TopbarControlService } from '@intersystems/header';
import { NotificationService, NOTIFICATION_TYPE } from '@intersystems/notification';
import { SQLQueryService } from '../sql-query.service';
import * as _ from 'lodash';

import { TableConfig, EPresetOptions, CheckboxConfig, TableService } from '@intersystems/table';
import { MatPaginator } from '@angular/material/paginator';
import { PaginatorConfig } from '@intersystems/table';
import { Sort } from '@angular/material/sort';
import { IccaCommonService } from 'src/app/deployments/icca-common/services/icca-common.service';

@Component({
  selector: 'app-sql-query-table',
  templateUrl: './sql-query-table.component.html',
  styleUrls: ['./sql-query-table.component.scss'],
})
export class SqlQueryTableComponent implements OnInit {
  @ViewChild('sqlTable') sqlTable: ElementRef;


  constructor(
    private sqlQueryService: SQLQueryService,
    private location: Location,
    private topbarControlService: TopbarControlService,
    private route: ActivatedRoute,
    private notificationSvc: NotificationService,
    public tableService: TableService,
    private iccaCommonService: IccaCommonService,
  ) {
    this.sqlResponse = this.sqlQueryService.getSQLResponse();
  }

  sqlQuery = '';

  sqlResponse: SQLResponse;

  sqlResultSetData: any;

  tableConfig: TableConfig = this.getTableConfig();

  paginatorConfig: PaginatorConfig= {pageSize: 10,};
  
  infoObject = {
    results: {
      infoTitle: 'Results',
      htmlText: "Displayed results are limited to the first 64K rows or 4MB, whichever is reached first. Other than testing, the recommended way \
      to process queries is through a \
      <a href='https://learning.intersystems.com/course/view.php?name=IRISCloudConnect' target='_blank'>\
      programmatic connection</a> to Cloud SQL using InterSystems client drivers for \
      <a href='https://docs.intersystems.com/components/csp/docbook/DocBook.UI.Page.cls?KEY=ADRIVE' target='_blank'>\
      JDBC (Java), ODBC (C++), ADO.NET (.NET), and DB-API (Python)</a>."
    },
  };

  


  
  ngOnInit(): void {
    this.paginatorConfig= {pageSize: this.sqlQueryService.currentPageSize};
    this.sqlResultSetData = [];

    this.sqlResponse = this.sqlQueryService.getSQLResponse();

    if (typeof this.sqlResponse != 'undefined') {
      if (typeof this.sqlResponse['resultSet'] != 'undefined') {
        this.sqlResultSetData = this.sqlResponse['resultSet'].data;

        //check for errors in resultset
        if (+this.sqlResponse['resultSet'].messageLength > +this.sqlResponse['resultSet'].maxLength) {
          const msg = 
            'Length of query response (' + 
            this.sqlResponse['resultSet'].messageLength + 
            ') exceeds maximum allowed length (' + 
            this.sqlResponse['resultSet'].maxLength + 
            ').  Resultset cannot be returned.';
          this.notificationSvc.showAlert(msg,7000);
          this.tableConfig.noDataMessage = msg;
       
        } else {
          if (+this.sqlResponse['resultSet'].returnedRows < +this.sqlResponse['resultSet'].totalRows) {
            this.notificationSvc.showAlert(
              'Rows in query response (' +
                this.sqlResponse['resultSet'].totalRows +
                ') exceeds maximum number of rows allowed (' +
                this.sqlResponse['resultSet'].maxRows +
                ').  Resultset returned is incomplete.',
            );
          }
        }
      }

      if (typeof this.sqlResponse['error'] != 'undefined') {
        var error:string = '';
        try {
          error = this.sqlResponse.error.split('[%msg: <')[1].split('>]')[0];
        } catch (e) {}
        
        if (error=='') {
          try {
            error=this.sqlResponse.error.split('java.sql.SQLException:')[1];
          } catch (e) {}
        }

        if (error=='') {
          error=this.sqlResponse.error;
        }
        
        error='Error executing statement: ' + error;
        this.tableConfig.noDataMessage = error; 
        this.notificationSvc.showAlert(error, 7000);
      }
      if (typeof this.sqlResponse['errorMessage'] != 'undefined') {
        this.tableConfig.noDataMessage = this.sqlResponse.errorMessage;
        this.notificationSvc.showAlert(this.sqlResponse.errorMessage, 7000);
      }
      if (typeof this.sqlResponse['rowsUpdated'] != 'undefined') {
        this.tableConfig.noDataMessage = 'Rows Updated: ' + this.sqlResponse['rowsUpdated'];
      }
    }
  }

  getTableConfig(): TableConfig {
    this.sqlResponse = this.sqlQueryService.getSQLResponse();

    const newTableConfig: TableConfig = {
      key: 'sql-query-table',
      cssTableClass: 'table-class',
      cssTRClass: 'table-row',
      useSearch: true,
      "searchConfig": {
        "noEntriesFoundLabel": "No entries found",
        "placeholderLabel": "Search",
        //"selectedRowHiddenLabel": "(hiddenRowCount: number) => string // TODO"
      },
      header: {
        title: 'Results',
        titleTooltip: {
          text: 'Results',
        },
      },
      noDataMessage: 'No records found.',
    };

    newTableConfig.columns = [];

    if (typeof this.sqlResponse != 'undefined') {
      if (typeof this.sqlResponse['resultSet'] != 'undefined') {
        if (typeof this.sqlResponse['resultSet'].name != 'undefined') {
          for (let i = 0; i < this.sqlResponse['resultSet'].name.length; i++) {
            newTableConfig.columns.push({
              sortable: true,
              title: this.sqlResponse['resultSet'].name[i],
              key: i.toString(),
              cellDisplay: this.getCellDisplay(i, this.sqlResponse['resultSet'].type[i]),
            });
          }
        }
      }
      if (typeof this.sqlResponse.error != 'undefined') {
        //error is handled on NgOnInit
      }
      if (typeof this.sqlResponse['rowsUpdated'] != 'undefined') {
        //rows updated is handled on NgOnInit
      }
    }

    newTableConfig.sort={
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      }
    };

    return newTableConfig;
  }

  getCellDisplay(i, type: string): any {
    switch (type) {
      case 'TIMESTAMP': {
        return {
          getDisplay: (row: any, col: any) => {
            if (row[i]) {
              return this.iccaCommonService.formatDate(row[i]);
            } else {
              return row[i];
            }
          },
        };
      }
      case 'DATE': {
        return {
          getDisplay: (row: any, col: any) => {
            if (row[i]) {
              const datestring=row[i] + ' 12:00:00';
              return this.iccaCommonService.formatDate(datestring,true);
            } else {
              return row[i];
            }
          },
        };
      }
      default:
        {
        }
        return {
          getDisplay: (row: any, col: any) => {
            return row[i];
          },
        };
    }
  }
}
