<fr-table Id="configs" [config]="externalObjectStorageTableConfig" [paginatorConfig]="paginatorConfig"
  [data]="configs?.buckets">


  <div class="custom-table-title" table-title-text-space>
    <app-info-button [htmlText]="infoObject.title.htmlText" [title]="infoObject.title.infoTitle"></app-info-button>
  </div>
  <div class="table-sub-header" above-table-body-space>
    <app-loading-spinner *ngIf="!configs"></app-loading-spinner>
  </div>
  <div class="custom-table-header-space" table-header-space>
    <button id="btn-new-object-storage" mat-flat-button class="fr-primary" color="primary"
      (click)="launchObjectStorageInput()">
      <mat-icon>add_circle_outline</mat-icon>
      Add external storage
    </button>
    <button id="btn-list-files" mat-flat-button class="fr-primary" color="primary" (click)="listFiles()"
      [disabled]='!configs || !selectedBucket'>
      List files
    </button>
  </div>
</fr-table>



<!--<div *ngIf="showField=='tableData'">-->
<div *ngIf="objects?.files">
  <fr-table [config]="fileTableConfig" [data]="objects.files" Id="s3-file-table" [paginatorConfig]="paginatorConfig">
  </fr-table>
</div>

<!--<div *ngIf="showField=='bucketPolicy'">-->
<div *ngIf="objects?.bucketPolicy?.Statement">
  <div class="name">Bucket policy for {{objectLoaded}}
    <app-info-button [htmlText]="infoObject.bucketPolicy.htmlText" [title]="infoObject.bucketPolicy.infoTitle">
    </app-info-button>

    <app-copy-button [copyText]="getBucketPolicy(objects.bucketPolicy)" buttonName="btn-copy-bucket-policy">
    </app-copy-button>
  </div>
  <p class="mat-caption">After you have associated this bucket policy with your bucket on AWS, press <b>List
      Files</b>
    again.</p>
  <div>
    <textarea id="bucket-policy" value={{getBucketPolicy(objects.bucketPolicy)}} [disabled]="true"></textarea>
  </div>
</div>

<!--<div *ngIf="showField=='error'">-->
<div *ngIf="objects?.error">
  <div class="name">Error listing files
    <p class="mat-caption"> {{objects.error}}</p>
  </div>
</div>