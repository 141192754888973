import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AccessLogsService } from './api/accessLogs.service';
import { BackupService } from './api/backup.service';
import { BanksService } from './api/banks.service';
import { BundleOperationsService } from './api/bundleOperations.service';
import { ConfigurationsService } from './api/configurations.service';
import { ConnectUsersService } from './api/connectUsers.service';
import { CredentialsService } from './api/credentials.service';
import { DeploymentService } from './api/deployment.service';
import { FHIRDashboardService } from './api/fHIRDashboard.service';
import { FilesBucketService } from './api/filesBucket.service';
import { FirewallRulesService } from './api/firewallRules.service';
import { HMTSMetadataService } from './api/hMTSMetadata.service';
import { HmtsErrorsService } from './api/hmtsErrors.service';
import { INCRouteSyncService } from './api/iNCRouteSync.service';
import { ISNCAttachmentHooksService } from './api/iSNCAttachmentHooks.service';
import { ImportLogsService } from './api/importLogs.service';
import { NetworkConnectService } from './api/networkConnect.service';
import { OAuth20Service } from './api/oAuth20.service';
import { OffersService } from './api/offers.service';
import { PackagesService } from './api/packages.service';
import { ProductionsService } from './api/productions.service';
import { ResourcesService } from './api/resources.service';
import { SQLGatewayService } from './api/sQLGateway.service';
import { SSLTLSConfigurationsService } from './api/sSLTLSConfigurations.service';
import { TenantsService } from './api/tenants.service';
import { TenantsSFTPService } from './api/tenantsSFTP.service';
import { UsageRecordsService } from './api/usageRecords.service';
import { UsersService } from './api/users.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
