import { Pipe, PipeTransform } from '@angular/core';
import { DeploymentObject, Deployments } from 'api';
import { SERVICES_DATA } from 'src/app/deployments/common/create/servicesData';

const deletedStatuses = ['DELETED', 'FAILED_DELETE'];
@Pipe({ name: 'deploymentsListFilter' })
export class DeploymentsListFilterPipe implements PipeTransform {
  transform(value: Deployments): DeploymentObject[] {
    return value?.deployments?.filter(item => !deletedStatuses.includes(item.status));
  }
}


@Pipe({ name: 'nonIccaDeploymentsListFilter' })
export class NonIccaDeploymentsListFilterPipe implements PipeTransform {
  transform(value: Deployments): DeploymentObject[] {
    return value?.deployments?.filter(item => {
      
      var offerDefinition;
      SERVICES_DATA.some(function(section) {
        section.offers.some(function(o) {
          if (o.name === item.deploymenttype) {
            offerDefinition=o;
            return true;
          };
        });
        if (offerDefinition) return true;
      });

      return (!deletedStatuses.includes(item.status) && 
        offerDefinition.architecture!='icca');
      });
  }
}
