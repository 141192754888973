import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormButton, FDN } from '@intersystems/isc-form';
import { TableConfig } from '@intersystems/table';
import { map, startWith } from 'rxjs/operators';
import { TableEnhancedIdService } from 'src/app/core/table-enhanced-id.service';
import { SERVICES_DATA } from 'src/app/deployments/common/create/servicesData';
import { SharedUtils } from 'src/app/shared/shared-utils';

@Component({
  selector: 'app-manage-offers-dialog',
  templateUrl: './manage-offers-dialog.component.html',
})
export class ManageOffersDialogComponent implements OnInit {
  tenantOffers: any[] = [];

  tableConfig: TableConfig = {
    key: 'offers-table',
    header: {
      title: 'Offers List',
    },
    noDataMessage: 'No offers',

    cssTRClassFromRow: TableEnhancedIdService.setTableRowIdColumn('tenantId'),
    columns: [
      SharedUtils.getTableColumn('offerId', 'Offer ID'),
      SharedUtils.getTableColumn('offerDesc', 'Description'),
      SharedUtils.getTableColumn('enabled', 'Enabled', row => (row.enabled ? '√' : '')),
    ],
  };

  FDN: FDN = {
    name: 'Manage Tenant Offers',
    description: `Select an offer whose status you want to change`,
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            id: 'offers',
            key: 'offers',
            type: 'select',
            overrideValidatorMessage: {},
            templateOptions: {
              label: 'Offer Id',
              required: true,
              options: [],
            },
            data: {
              displayField: 'name',
            },
          },
        ],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      id: 'btn-enable',
      text: 'Enable',
      buttonClass: 'primary',
      type: 'submit',
      disabledIfFormInvalid: true,
      disabledIfFormPristine: true,
      disabled: (_formModel: any, _formOptions: any, form: any) =>
        form.valueChanges.pipe(
          startWith(true),
          map((formModel: any) => {
            if (!formModel.offers) {
              return true;
            } else {
              return this.tenantOffers.find(offer => {
                return formModel.offers.name === offer.offerId && offer.enabled === true;
              });
            }
          }),
        ),
      callback: (clickEvent, button, formModel) =>
        this.dialogRef.close({
          offerId: formModel.offers.value,
          action: 'enable',
        }),
    },
    {
      id: 'btn-disable',
      text: 'Disable',
      buttonClass: 'tertiary',
      type: 'reset',
      disabledIfFormInvalid: true,
      disabledIfFormPristine: true,
      disabled: (_formModel: any, _formOptions: any, form: any) =>
        form.valueChanges.pipe(
          startWith(true),
          map((formModel: any) => {
            if (!formModel.offers) {
              return true;
            } else {
              return this.tenantOffers.find(offer => {
                return formModel.offers.name === offer.offerId && offer.enabled !== true;
              });
            }
          }),
        ),
      callback: (clickEvent, button, formModel) =>
        this.dialogRef.close({
          offerId: formModel.offers.value,
          action: 'disable',
        }),
    },
    {
      id: 'btn-cancel',
      text: 'Cancel',
      buttonClass: 'secondary',
      type: 'reset',
      callback: () => this.dialogRef.close(false),
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<ManageOffersDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}

  ngOnInit(): void {
    SERVICES_DATA.forEach(section =>
      section.offers.forEach(offer =>
        this.tenantOffers.push({
          offerId: offer.name,
          offerDesc: offer.caption,
          enabled: this.data.tenantOffers[offer.name]?.enabled,
        }),
      ),
    );

    this.FDN.sections[0].fields.find(item => item.id == 'offers').templateOptions.options = this.getOffers();
  }

  getOffers(): { name: string; value: string }[] {
    return this.tenantOffers.map(offerId => ({ name: offerId.offerId, value: offerId.offerId }));
  }
}
