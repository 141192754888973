import { Component } from '@angular/core';
import { SharedService } from 'src/app/shared/services/shared.service';
import { SitrepsService } from 'admin-api';
import { tap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '@intersystems/confirmation-dialog';

@Component({
  selector: 'app-sitreps-page',
  templateUrl: './sitreps-page.component.html',
  styleUrls: ['./sitreps-page.component.scss'],
})
export class SitrepsPageComponent {
  constructor(
    private sitrepsService: SitrepsService,
    private dialog: MatDialog,
    private sharedService: SharedService,
  ) {}
  sitreps$ = this.sitrepsService.getSitreps();

  ackSitrep(sitrepid: string): void {
    this.sitrepsService
      .ackSitrep(sitrepid)
      .pipe(
        tap(() => this.sharedService.showSuccess('Acknowledged')),
        tap(() => this.sharedService.reloadCurrentRoute()),
        this.sharedService.catchError(),
      )
      .subscribe();
  }

  doneSitrep(sitrepid: string): void {
    this.sitrepsService
      .doneSitrep(sitrepid)
      .pipe(
        tap(() => this.sharedService.showSuccess('Makred as done')),
        tap(() => this.sharedService.reloadCurrentRoute()),
        this.sharedService.catchError(),
      )
      .subscribe();
  }

  openBody(body: string): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      title: 'Signal Body',
      primary: body,
      buttons: {
        primary: {
          text: 'OK',
        },
      },
    };
    dialogConfig.panelClass = 'fr-layout-wrapper-mat-dialog-panel';

    // Use the open() API to instantiate the modal
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, dialogConfig);
  }
}
