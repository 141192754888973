import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FDN, VALIDATORS, FormButton } from '@intersystems/isc-form';
import { map } from 'rxjs/operators';
import { CAPITAL, LOWER, NUMBER, PASSWORD_LENGTH, SPECIAL } from 'regex';
import { BehaviorSubject } from 'rxjs';
import { User } from 'api';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss'],
})
export class AccountFormComponent implements OnChanges {
  @Input() user: User = {};
  @Input() totpSecret: string;
  @Input() isTotpEnabled: boolean;
  @Output() detailsEdit = new EventEmitter<any>();
  @Output() passwordEdit = new EventEmitter<any>();
  @Output() verifyTotp = new EventEmitter<string>();
  @Output() disableTotp = new EventEmitter<void>();

  qrdata = '';

  detailsModel: any = {};
  detailsInitialModel: any = {};
  detailsMode$: BehaviorSubject<'edit' | 'view'> = new BehaviorSubject('view');

  detailsFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            key: 'username',
            type: 'input',
            id: 'username',
            viewOnly: true,
            templateOptions: {
              required: true,
              label: 'Username',
            },
          },
          {
            key: 'email',
            type: 'input',
            id: 'email',
            viewOnly: true,
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Email is a required field',
            },
            templateOptions: {
              label: 'Email',
              required: true,
            },
            data: {
              displayField: 'name',
            },
          },
          {
            key: 'firstName',
            type: 'input',
            id: 'txt-first-name',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'First Name is a required field',
            },
            templateOptions: {
              label: 'First Name',
              required: true,
            },
            data: {
              displayField: 'name',
            },
          },
          {
            key: 'lastName',
            type: 'input',
            id: 'txt-last-name',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Last Name is a required field',
            },
            templateOptions: {
              label: 'Last Name',
              required: true,
            },
            data: {
              displayField: 'name',
            },
          },
        ],
      },
    ],
  };

  detailsButtons: FormButton[] = [
    {
      text: 'Edit',
      buttonClass: 'primary',
      id: 'btn-edit-account',
      type: 'button',
      hidden: this.detailsMode$.asObservable().pipe(map(mode => mode == 'edit')),
      callback: () => this.detailsMode$.next('edit'),
    },
    {
      text: 'Cancel',
      buttonClass: 'secondary',
      id: 'btn-cancel-account',
      type: 'button',
      hideInViewMode: true,
      callback: () => {
        this.detailsModel = { ...this.detailsInitialModel };
        this.detailsMode$.next('view');
      },
    },
    {
      text: 'Save',
      buttonClass: 'primary',
      id: 'btn-save-account',
      type: 'submit',
      hideInViewMode: true,
      disabledIfFormInvalid: true,
      callback: (event, button, formModel) => {
        this.detailsEdit.emit(formModel);
        this.detailsMode$.next('view');
      },
    },
  ];

  passwordModel: any = { curentPasswordViewOnly: '******' };
  passwordMode$: BehaviorSubject<'edit' | 'view'> = new BehaviorSubject('view');

  editPasswordFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            key: 'currentPassword',
            type: 'input',
            id: 'txt-account-current-password',
            templateOptions: {
              type: 'password',
              required: true,
              label: 'Current Password',
            },
          },
          {
            key: 'password',
            type: 'input',
            id: 'txt-account-new-password',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Password is a required field',
            },
            validators: {
              ['matching']: {
                expression: (_control: AbstractControl, field) => {
                  field.form.controls['confirmPassword'].updateValueAndValidity();
                  return true;
                },
                message: () => 'Passwords do not match',
              },
            },
            templateOptions: {
              type: 'password',
              label: 'Password',
              required: true,
              requirements: [
                {
                  key: 'length',
                  regEx: PASSWORD_LENGTH,
                  id: 'length',
                  message: 'Use more than 12 characters',
                },
                {
                  key: 'capital',
                  regEx: CAPITAL,
                  id: 'capital',
                  message: 'Use an uppercase letter (e.g., ABC)',
                },
                {
                  key: 'lower',
                  regEx: LOWER,
                  id: 'lower',
                  message: 'Use a lowercase letter (e.g., abc)',
                },
                {
                  key: 'number',
                  id: 'number',
                  regEx: NUMBER,
                  message: 'Use a number (e.g., 1234)',
                },
                {
                  key: 'special',
                  regEx: SPECIAL,
                  id: 'special',
                  message: 'Use a symbol (e.g., !#$%&?@)',
                },
              ],
            },
            data: {
              displayField: 'name',
            },
          },
          {
            key: 'confirmPassword',
            type: 'input',
            id: 'txt-account-new-password-confirmation',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Password is a required field',
            },
            validators: {
              ['matching']: {
                expression: (control: AbstractControl, field) => {
                  if (control.value) {
                    return control.value === field.form.controls['password'].value;
                  }
                },
                message: () => 'Passwords do not match',
              },
            },
            templateOptions: {
              type: 'password',
              label: 'Confirm Password',
              required: true,
            },
            data: {
              displayField: 'name',
            },
          },
        ],
      },
    ],
  };

  viewPasswordFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            key: 'curentPasswordViewOnly',
            type: 'input',
            id: 'username',
            viewOnly: true,
            templateOptions: {
              required: true,
              label: 'Current Password',
            },
          },
        ],
      },
    ],
  };

  viewPasswordButtons: FormButton[] = [
    {
      text: 'Edit',
      buttonClass: 'primary',
      id: 'btn-edit-password',
      type: 'button',
      hidden: () => this.passwordMode$.asObservable().pipe(map(passwordMode => passwordMode == 'edit')),
      callback: (_event, _button, _formModel, _formOptions, form) => {
        this.passwordMode$.next('edit');
        form.markAsPristine();
      },
    },
  ];

  editPasswordButtons: FormButton[] = [
    {
      text: 'Cancel',
      buttonClass: 'secondary',
      id: 'btn-cancel-password',
      type: 'button',
      hideInViewMode: true,
      callback: () => {
        this.passwordModel = { curentPasswordViewOnly: '******' };
        this.passwordMode$.next('view');
      },
    },
    {
      text: 'Save',
      buttonClass: 'primary',
      id: 'btn-save-password',
      type: 'submit',
      hideInViewMode: true,
      disabled: (_formModel: any, _formOptions: any, form: any) => form.valueChanges.pipe(map(() => !form.valid)),
      callback: (event, button, formModel) => {
        this.passwordEdit.emit(formModel);
        this.passwordModel = { curentPasswordViewOnly: '******' };
        this.passwordMode$.next('view');
      },
    },
  ];

  mfaModel: any = { totpSecret: '', verificationCode: '' };

  mfaFDN: FDN = {
    name: '',
    description: '',
    validateOn: 'change',
    sectionLayout: { showSectionHeaders: false },
    sections: [
      {
        fields: [
          {
            key: 'totpSecret',
            type: 'input',
            id: 'txt-account-totpSecret',
            model: 'totpSecret',
            viewOnly: true,
            templateOptions: {
              label: 'TOTP Code',
            },
            hideExpression: () => this.isTotpEnabled,
          },
          {
            key: 'verificationCode',
            type: 'input',
            id: 'txt-account-verificationCode',
            overrideValidatorMessage: {
              [VALIDATORS.ISC_REQUIRED]: 'Verification Code is a required field',
            },
            templateOptions: {
              label: 'Enter one-time password from Authenticator app using code above',
            },
            hideExpression: () => this.isTotpEnabled,
          },
        ],
      },
    ],
  };

  mfaEnabledButtons: FormButton[] = [
    {
      text: 'Disable MFA',
      buttonClass: 'primary',
      id: 'btn-disable-mfa',
      type: 'submit',
      callback: () => this.disableTotp.emit(),
    },
  ];

  mfaDisabledButtons: FormButton[] = [
    {
      text: 'Enable MFA',
      buttonClass: 'primary',
      id: 'btn-enable-mfa',
      type: 'submit',
      hideInViewMode: true,
      callback: (event, button, formModel) => this.verifyTotp.emit(formModel.verificationCode),
    },
  ];

  ngOnChanges(changes) {
    this.detailsModel.username = this.user.username;
    this.detailsModel.email = this.user.email;
    this.detailsModel.firstName = this.user.first_name;
    this.detailsModel.lastName = this.user.last_name;
    this.mfaModel = { totpSecret: this.totpSecret, isTotpEnabled: this.isTotpEnabled };
    this.qrdata =
      'otpauth://totp/InterSystems%20Cloud%20' +
      environment.STAGE +
      ':' +
      this.user.username +
      '?secret=' +
      this.totpSecret +
      '&issuer=intersystems';
    this.detailsInitialModel = { ...this.detailsModel };
  }
}
