import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormButton, FDN } from '@intersystems/isc-form';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user-dialog.component.html',
})
export class DeleteUserDialogComponent {
  FDN: FDN = {
    name: 'Are you sure you want to remove this user?',
    description: `${this.data.name} will no longer have access to deployments in ${this.data.tenant}.`,
    sections: [
      {
        hide: true,
        fields: [],
      },
    ],
  };

  buttons: FormButton[] = [
    {
      id: 'btn-delete',
      text: 'Remove',
      buttonClass: 'primary',
      type: 'submit',
      callback: () => {
        this.dialogRef.close(true);
      },
    },
    {
      id: 'btn-cancel',
      text: 'Cancel',
      buttonClass: 'secondary',
      type: 'reset',
      callback: () => this.dialogRef.close(false),
    },
  ];

  constructor(
    public dialogRef: MatDialogRef<DeleteUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
  ) {}
}
