import { Component, OnInit, EventEmitter, Output, OnDestroy, Input } from '@angular/core';

import { TableConfig, PaginatorConfig, EPresetOptions, TableService } from '@intersystems/table';
import * as _ from 'lodash';
import { DeploymentObject } from 'api';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '@intersystems/notification';
import { SQLQueryService } from '../../sql-query/sql-query.service';
import { DeploymentsService } from 'src/app/deployments/deployments.service';
import { IRISAuthService } from 'src/app/deployments/icca-common/components/iris-login/iris-auth.service'; 
import { MatPaginator } from '@angular/material/paginator';
import { FormModes, IscFormModalData } from '@intersystems/isc-form';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenericDialogComponent } from 'src/app/deployments/icca-common/dialogs/generic/generic-dialog.component'; 
import { Sort } from '@angular/material/sort';
import { IccaCommonService } from 'src/app/deployments/icca-common/services/icca-common.service';
import { delay, repeatWhen, take, takeWhile, tap } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-iml-training-info',
  templateUrl: './iml-training-info.component.html',
  styleUrls: ['./iml-training-info.component.scss'],
})
export class IMLTrainingInfoComponent implements OnDestroy {
  @Output() hideDrawer = new EventEmitter<boolean>();
  @Input() drawerOpened:boolean = false;
  constructor(
    private sqlQueryService: SQLQueryService,
    private notificationSvc: NotificationService,
    private route: ActivatedRoute,
    private deploymentsService: DeploymentsService,
    private irisAuthService: IRISAuthService,
    private dialog: MatDialog,
    private tableService: TableService,
    private iccaCommonService: IccaCommonService,
  ) {}

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  private sub = new Subscription();

 

  type:"createdModels"|"trainingRuns"|"trainedModels"|"validationRuns"|"validationMetrics" = 'trainingRuns';
  
  createdModelColumnIds={
    MODEL_NAME:0,
    DESCRIPTION:1,
    CREATE_TIMESTAMP:2,
    PREDICTING_COLUMN_NAME:3,
    PREDICTING_COLUMN_TYPE:4,
    DEFAULT_TRAINING_QUERY:5,
  };

  trainedModelColumnIds={
    MODEL_NAME:0,
    TRAINED_MODEL_NAME:1,
    PROVIDER:2,
    TRAINED_TIMESTAMP:3,
  };

  trainingRunsColumnIds={
    MODEL_NAME:0,
    TRAINING_RUN_NAME:1,
    RUN_STATUS:2,
    PROVIDER:3,
    START_TIMESTAMP:4,
    COMPLETED_TIMESTAMP:5,
    TRAINING_DURATION:6
  };

  validationRunsColumnIds={
    MODEL_NAME:0,
    TRAINED_MODEL_NAME:1,
    VALIDATION_RUN_NAME:2,
    RUN_STATUS:3,
    START_TIMESTAMP:4,
    COMPLETED_TIMESTAMP:5,
    VALIDATION_DURATION:6,
  };

  validationMetricsColumnIds={
    MODEL_NAME:0,
    VALIDATION_RUN_NAME:1,
    RUN_STATUS:2,
    PREDICTING_COLUMN_TYPE:3,
    VARIANCE:4,
    R2:5,
    MSE:6,
    RMSE:7,
    PRECISION:8,
    RECALL:9,
    FMEASURE:10,
    ACCURACY:11
  };

  loadInProgress:boolean = false;

  createdModelsConfig: TableConfig = {
    key: 'created-models-table',
    header: {
      title: 'ML Models',
      titleTooltip: {
        text: 'ML Models',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      //this.queryRowSelected.emit(row.sqlStatement);
    },
    sort: {
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      },
      initialSortArrowDirection: {
        direction: "desc",
        doInitialSort: true,
        columnKey: this.createdModelColumnIds.CREATE_TIMESTAMP.toString()
      }
    },
    noDataMessage: 'No models to display.',
    columns: [
      {
        title: 'Model name',
        key: this.createdModelColumnIds.MODEL_NAME.toString(),
        id: 'MODEL_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainedModelColumnIds.MODEL_NAME];
          },
        },
      },
      {
        title: 'Description',
        key: this.createdModelColumnIds.DESCRIPTION.toString(),
        id: 'DESCRIPTION',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.createdModelColumnIds.DESCRIPTION];
          },
        },
      },
      {
        title: 'Created',
        key: this.createdModelColumnIds.CREATE_TIMESTAMP.toString(),
        id: 'CREATE_TIMESTAMP',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.iccaCommonService.formatDate(row[this.createdModelColumnIds.CREATE_TIMESTAMP]);
          },
        },
      },
      {
        title: 'Predicting column name',
        key: this.createdModelColumnIds.PREDICTING_COLUMN_NAME.toString(),
        id: 'PREDICTING_COLUMN_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.createdModelColumnIds.PREDICTING_COLUMN_NAME];
          },
        },
      },
      {
        title: 'Predicting column type',
        key: this.createdModelColumnIds.PREDICTING_COLUMN_TYPE.toString(),
        id: 'PREDICTING_COLUMN_TYPE',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.createdModelColumnIds.PREDICTING_COLUMN_TYPE];
          },
        },
      },
      {
        title: 'Training query',
        key: this.createdModelColumnIds.DEFAULT_TRAINING_QUERY.toString(),
        id: 'DEFAULT_TRAINING_QUERY',
        sortable:false,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.createdModelColumnIds.DEFAULT_TRAINING_QUERY];
          },
        },
      },
    ],
  };

  trainedModelsConfig: TableConfig = {
    key: 'trained-models-table',
    header: {
      title: 'Trained Models',
      titleTooltip: {
        text: 'Trained Models',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      //this.queryRowSelected.emit(row.sqlStatement);
    },
    sort: {
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      },
      initialSortArrowDirection: {
        direction: "desc",
        doInitialSort: true,
        columnKey: this.trainedModelColumnIds.TRAINED_TIMESTAMP.toString()
      }
    },
    noDataMessage: 'No trained models to display.',
    columns: [
      {
        title: 'Model name',
        key: this.trainedModelColumnIds.MODEL_NAME.toString(),
        id: 'MODEL_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainedModelColumnIds.MODEL_NAME];
          },
        },
      },
      {
        title: 'Trained model name',
        key: this.trainedModelColumnIds.TRAINED_MODEL_NAME.toString(),
        id: 'TRAINED_MODEL_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainedModelColumnIds.TRAINED_MODEL_NAME];
          },
        },
      },
      {
        title: 'Provider',
        key: this.trainedModelColumnIds.PROVIDER.toString(),
        id: 'PROVIDER',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainedModelColumnIds.PROVIDER];
          },
        },
      },
      {
        title: 'Trained',
        key: this.trainedModelColumnIds.TRAINED_TIMESTAMP.toString(),
        id: 'TRAINED_TIMESTAMP',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.iccaCommonService.formatDate(row[this.trainedModelColumnIds.TRAINED_TIMESTAMP]);
          },
        },
      },
    ],
  };

 
  trainingRunsConfig: TableConfig = {
    key: 'training-runs-table',
    header: {
      title: 'Training Runs',
      titleTooltip: {
        text: 'Training Runs',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      //this.queryRowSelected.emit(row.sqlStatement);
    },
    sort: {
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      },
      initialSortArrowDirection: {
        direction: "desc",
        doInitialSort: true,
        columnKey: this.trainingRunsColumnIds.START_TIMESTAMP.toString()
      }
    },
    noDataMessage: 'No training runs to display.',
    columns: [
      {
        title: 'Model name',
        key: this.trainingRunsColumnIds.MODEL_NAME.toString(),
        id: 'MODEL_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainingRunsColumnIds.MODEL_NAME];
          },
        },
      },
      {
        title: 'Training run name',
        key: this.trainingRunsColumnIds.TRAINING_RUN_NAME.toString(),
        id: 'TRAINING_RUN_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainingRunsColumnIds.TRAINING_RUN_NAME];
          },
        },
      },
      {
        title: 'Run status',
        key: this.trainingRunsColumnIds.RUN_STATUS.toString(),
        id: 'RUN_STATUS',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainingRunsColumnIds.RUN_STATUS];
          },
        },
      },
      {
        title: 'Provider',
        key: this.trainingRunsColumnIds.PROVIDER.toString(),
        id: 'PROVIDER',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.trainingRunsColumnIds.PROVIDER];
          },
        },
      },
      {
        title: 'Started',
        key: this.trainingRunsColumnIds.START_TIMESTAMP.toString(),
        id: 'START_TIMESTAMP',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.iccaCommonService.formatDate(row[this.trainingRunsColumnIds.START_TIMESTAMP]);
          },
        },
      },
      {
        title: 'Completed',
        key: this.trainingRunsColumnIds.COMPLETED_TIMESTAMP.toString(),
        id: 'COMPLETED_TIMESTAMP',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.iccaCommonService.formatDate(row[this.trainingRunsColumnIds.COMPLETED_TIMESTAMP]);
          },
        },
      },
      {
        title: 'Duration',
        key: this.trainingRunsColumnIds.TRAINING_DURATION.toString(),
        id: 'TRAINING_DURATION',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return (row[this.trainingRunsColumnIds.TRAINING_DURATION]=='null') ? '' : row[this.trainingRunsColumnIds.TRAINING_DURATION];
          },
        },
      },
      {
        title: 'Log File',
        key: 'actionsIcons',
        sortable:false,
        cellDisplay: {
          preset: EPresetOptions.ACTIONS_ICONS,
          actionsIcons: {
            iconsOrder: ['view'],
            view: {
              id: 'view',
              tooltip: {
                text: 'View Log',
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                //this.viewFile(row.name);
                this.viewLog(row[this.trainingRunsColumnIds.TRAINING_RUN_NAME]);
                return;
              },
            },
          },
        },
      },
    ],
  };


  validationRunsConfig: TableConfig = {
    key: 'validation-runs-table',
    header: {
      title: 'Validation Runs',
      titleTooltip: {
        text: 'Validation Runs',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      //this.queryRowSelected.emit(row.sqlStatement);
    },
    sort: {
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      },
      initialSortArrowDirection: {
        direction: "desc",
        doInitialSort: true,
        columnKey: this.validationRunsColumnIds.START_TIMESTAMP.toString()
      }
    },
    noDataMessage: 'No validation runs to display.',
    columns: [
      {
        title: 'Model name',
        key: this.validationRunsColumnIds.MODEL_NAME.toString(),
        id: 'MODEL_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationRunsColumnIds.MODEL_NAME];
          },
        },
      },
      {
        title: 'Validation run name',
        key: this.validationRunsColumnIds.VALIDATION_RUN_NAME.toString(),
        id: 'VALIDATION_RUN_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationRunsColumnIds.VALIDATION_RUN_NAME];
          },
        },
      },
      {
        title: 'Run status',
        key: this.validationRunsColumnIds.RUN_STATUS.toString(),
        id: 'RUN_STATUS',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationRunsColumnIds.RUN_STATUS];
          },
        },
      },
      {
        title: 'Started',
        key: this.validationRunsColumnIds.START_TIMESTAMP.toString(),
        id: 'START_TIMESTAMP',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.iccaCommonService.formatDate(row[this.validationRunsColumnIds.START_TIMESTAMP]);
          },
        },
      },
      {
        title: 'Completed',
        key: this.validationRunsColumnIds.COMPLETED_TIMESTAMP.toString(),
        id: 'COMPLETED_TIMESTAMP',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return this.iccaCommonService.formatDate(row[this.validationRunsColumnIds.COMPLETED_TIMESTAMP]);
          },
        },
      },
      {
        title: 'Duration',
        key: this.validationRunsColumnIds.VALIDATION_DURATION.toString(),
        id: 'VALIDATION_DURATION',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return (row[this.validationRunsColumnIds.VALIDATION_DURATION]=='null') ? '' : row[this.validationRunsColumnIds.VALIDATION_DURATION];
          
          },
        },
      },
      {
        title: 'Log File',
        key: 'actionsIcons',
        sortable:false,
        cellDisplay: {
          preset: EPresetOptions.ACTIONS_ICONS,
          actionsIcons: {
            iconsOrder: ['view'],
            view: {
              id: 'view',
              tooltip: {
                text: 'View Log',
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                //this.viewFile(row.name);
                this.viewLog(row[this.validationRunsColumnIds.VALIDATION_RUN_NAME]);
                return;
              },
            },
          },
        },
      },
    ],
  };


  validationRegressionMetricsConfig: TableConfig = {
    key: 'validation-regression-metrics-table',
    header: {
      title: 'Validation Metrics for Regression Models',
      titleTooltip: {
        text: 'Validation Metrics',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      //this.queryRowSelected.emit(row.sqlStatement);
    },
    sort: {
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      },
    },
    noDataMessage: 'No validation metrics to display.',
    columns: [
      {
        title: 'Model name',
        key: this.validationMetricsColumnIds.MODEL_NAME.toString(),
        id: 'MODEL_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.MODEL_NAME];
          },
        },
      },
      {
        title: 'Validation run name',
        key: this.validationMetricsColumnIds.VALIDATION_RUN_NAME.toString(),
        id: 'VALIDATION_RUN_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.VALIDATION_RUN_NAME];
          },
        },
      },
      {
        title: 'Variance',
        key: this.validationMetricsColumnIds.VARIANCE.toString(),
        id: 'VARIANCE',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.VARIANCE];
          },
        },
      },
      {
        title: 'R2',
        key: this.validationMetricsColumnIds.R2.toString(),
        id: 'R2',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.R2];
          },
        },
      },
      {
        title: 'MSE',
        key: this.validationMetricsColumnIds.MSE.toString(),
        id: 'MSE',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.MSE];
          },
        },
      },
      {
        title: 'RMSE',
        key: this.validationMetricsColumnIds.RMSE.toString(),
        id: 'RMSE',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.RMSE];
          },
        },
      },
      {
        title: 'Chart',
        key: 'actionsIcons',
        sortable:false,
        cellDisplay: {
          preset: EPresetOptions.ACTIONS_ICONS,
          actionsIcons: {
            iconsOrder: ['view'],
            view: {
              id: 'view',
              tooltip: {
                text: 'Show chart',
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                //this.viewFile(row.name);
                this.showChart(row[this.validationMetricsColumnIds.VALIDATION_RUN_NAME]);
                return;
              },
            },
          },
        },
      },
    ],
  };


  validationClassificationMetricsConfig: TableConfig = {
    key: 'validation-classification-metrics-table',
    header: {
      title: 'Validation Metrics for Classification Models',
      titleTooltip: {
        text: 'Validation Metrics',
      },
    },
    cssTRClass: 'tr-hotspot',
    onRowClick: (row: any) => {
      //this.queryRowSelected.emit(row.sqlStatement);
    },
    sort: {
      sortFunction: (event: Sort, data: any) =>  {
        let sortedData = data.sort((a: any, b: any) => {
          const isAsc = event.direction === "asc";
          
          return this.tableService.compareAlphaNumeric(
            a[event.active],
            b[event.active],
            isAsc
          );
        });
        return sortedData;
      },
    },
    noDataMessage: 'No validation metrics to display.',
    columns: [
      {
        title: 'Model name',
        key: this.validationMetricsColumnIds.MODEL_NAME.toString(),
        id: 'MODEL_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.MODEL_NAME];
          },
        },
      },
      {
        title: 'Validation run name',
        key: this.validationMetricsColumnIds.VALIDATION_RUN_NAME.toString(),
        id: 'VALIDATION_RUN_NAME',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.VALIDATION_RUN_NAME];
          },
        },
      },
      {
        title: 'Precision',
        key: this.validationMetricsColumnIds.PRECISION.toString(),
        id: 'PRECISION',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.PRECISION];
          },
        },
      },
      {
        title: 'Recall',
        key: this.validationMetricsColumnIds.RECALL.toString(),
        id: 'RECALL',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.RECALL];
          },
        },
      },
      {
        title: 'F-Measure',
        key: this.validationMetricsColumnIds.FMEASURE.toString(),
        id: 'FMEASURE',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.FMEASURE];
          },
        },
      },
      {
        title: 'Accuracy',
        key: this.validationMetricsColumnIds.ACCURACY.toString(),
        id: 'ACCURACY',
        sortable:true,
        cellDisplay: {
          getDisplay: (row: any, col: any) => {
            return row[this.validationMetricsColumnIds.ACCURACY];
          },
        },
      },
      /*
      {
        title: 'Chart',
        key: 'actionsIcons',
        sortable:false,
        cellDisplay: {
          preset: EPresetOptions.ACTIONS_ICONS,
          actionsIcons: {
            iconsOrder: ['view'],
            view: {
              id: 'view',
              tooltip: {
                text: 'Show chart',
              },
              callback: (event: any, row: any, col: any, rowIndex: number, paginator: MatPaginator) => {
                //this.viewFile(row.name);
                this.showChart(row[this.validationMetricsColumnIds.VALIDATION_RUN_NAME]);
                return;
              },
            },
          },
        },
      },
      */
    ],
  };

  paginatorConfig: PaginatorConfig = {
    pageSize: 10,
  };

  createdModelsData: [] = [];
  trainedModelsData: [] = [];
  trainingRunsData: [] = [];
  validationRunsData:[] = [];
  validationRegressionMetricsData:[]=[];
  validationClassificationMetricsData:[]=[];
  refreshTimer:number = 5000;

  infoObject = {
    validationMetrics: {
      infoTitle: 'Validation Metrics for Regression and Classification Models',
      htmlText: "For information about the validation metrics listed in these tables, see the \
      <a href='https://docs.intersystems.com/irislatest/csp/docbook/DocBook.UI.Page.cls?KEY=RSQL_validatemodel_Metrics'  target='_blank'>InterSystems SQL Reference</a>."
      },
  }

  getCreatedModels(): void {
    this.loadInProgress = true;
    
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    const sql =
      `select model_name, description, create_timestamp, predicting_column_name, predicting_column_type, 
      default_training_query from information_schema.ml_models 
      ORDER BY create_timestamp DESC`;

    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment, sql).pipe(
      tap(() => this.loadInProgress=true),
      repeatWhen(obs => obs.pipe(
        delay(this.refreshTimer),
        tap(() => this.loadInProgress=true))),
      takeWhile(
        () => (this.type=='createdModels' && this.drawerOpened)
      )
    ).subscribe(sqlResponse => {
      this.createdModelsData=sqlResponse['resultSet'].data;
      this.loadInProgress=false;
    
    }));
  }

  getTrainedModels(): void {
    this.loadInProgress = true;
    
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    const sql =
      `SELECT MODEL_NAME, TRAINED_MODEL_NAME, PROVIDER, TRAINED_TIMESTAMP 
    FROM INFORMATION_SCHEMA.ML_TRAINED_MODELS
    ORDER BY TRAINED_TIMESTAMP DESC`;

    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment, sql).pipe(
      tap(() => this.loadInProgress=true),
      repeatWhen(obs => obs.pipe(
        delay(this.refreshTimer),
        tap(() => this.loadInProgress=true))),
      takeWhile(
        () => (this.type=='trainedModels' && this.drawerOpened)
      )
    ).subscribe(sqlResponse => {
      this.trainedModelsData=sqlResponse['resultSet'].data;
      this.loadInProgress=false;
    
    }));
  }

  hide(): void {
    this.hideDrawer.emit(true);
  }

  getTrainingRuns(): void {
    this.loadInProgress = true;
    
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    const sql =
      `SELECT MODEL_NAME, TRAINING_RUN_NAME, RUN_STATUS, PROVIDER, START_TIMESTAMP, 
    COMPLETED_TIMESTAMP, TRAINING_DURATION 
    FROM INFORMATION_SCHEMA.ML_TRAINING_RUNS
    ORDER BY START_TIMESTAMP DESC`;

    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment, sql).pipe(
      tap(() => this.loadInProgress=true),
      repeatWhen(obs => obs.pipe(
        delay(this.refreshTimer),
        tap(() => this.loadInProgress=true))),
      takeWhile(
        () => (this.type=='trainingRuns' && this.drawerOpened)
      )
    ).subscribe(sqlResponse => {
      this.trainingRunsData=sqlResponse['resultSet'].data;
      this.loadInProgress=false;
    }));

  }

  getValidationRuns(): void {
    this.loadInProgress = true;
    
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    const sql =
      `SELECT MODEL_NAME, TRAINED_MODEL_NAME,VALIDATION_RUN_NAME, RUN_STATUS, START_TIMESTAMP, 
    COMPLETED_TIMESTAMP, VALIDATION_DURATION 
    FROM INFORMATION_SCHEMA.ML_VALIDATION_RUNS
    ORDER BY START_TIMESTAMP DESC`;

    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment, sql).pipe(
      tap(() => this.loadInProgress=true),
      repeatWhen(obs => obs.pipe(
        delay(this.refreshTimer),
        tap(() => this.loadInProgress=true))),
      takeWhile(
        () => (this.type=='validationRuns' && this.drawerOpened)
      )
    ).subscribe(sqlResponse => {
      this.validationRunsData=sqlResponse['resultSet'].data;
      this.loadInProgress=false;
    }));


  }

  getValidationMetrics(): void {
    this.loadInProgress = true;
    
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
   
    //column order needs to match validationMetricsColumnIds
    //TODO - should be refactored to split out regression query vs classification query
    //and probably a separate query for each row to handle large result sets.
      const sql = 
      `SELECT MODEL_NAME, VALIDATION_RUN_NAME, RUN_STATUS, 
      (SELECT PREDICTING_COLUMN_TYPE FROM INFORMATION_SCHEMA.ML_MODELS WHERE MODEL_NAME=runs.model_name) As PREDICTING_COLUMN_TYPE,
      (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name AND metric_name='Variance' and target_value IS NULL) as Variance,
      (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name AND metric_name='R2' and target_value IS NULL) as R2,
      (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name AND metric_name='MSE' and target_value IS NULL) as MSE,
      (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name AND metric_name='RMSE' and target_value IS NULL) as RMSE,
      (SELECT (CASE WHEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged Precision') is not null THEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged Precision') ELSE (SELECT TOP(1) METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Precision') END)) As Precision,
      (SELECT (CASE WHEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged Recall') is not null THEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged Recall') ELSE (SELECT TOP(1) METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Recall') END)) As Recall,
      (SELECT (CASE WHEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged F-Measure') is not null THEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged F-Measure') ELSE (SELECT TOP(1) METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='F-Measure') END)) As "F-Measure",
      (SELECT (CASE WHEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged Accuracy') is not null THEN (SELECT METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Micro-averaged Accuracy') ELSE (SELECT TOP(1) METRIC_VALUE FROM INFORMATION_SCHEMA.ML_VALIDATION_METRICS WHERE validation_run_name=runs.validation_run_name and metric_name='Accuracy') END)) As Accuracy
      FROM INFORMATION_SCHEMA.ML_VALIDATION_RUNS as runs
          ORDER BY START_TIMESTAMP DESC`;


    this.sub.add(this.sqlQueryService.executeSQLStatement(deployment, sql).pipe(
      tap(() => this.loadInProgress=true),
      // repeatWhen(obs => obs.pipe(
      //   delay(this.refreshTimer),
      //   tap(() => this.loadInProgress=true))),
      // takeWhile(
      //   () => (this.type=='validationMetrics' && this.drawerOpened)
      // )
    ).subscribe(sqlResponse => {
      this.validationRegressionMetricsData=sqlResponse['resultSet'].data.filter(row => {
        switch (row[this.validationMetricsColumnIds.PREDICTING_COLUMN_TYPE]) {
          case "VARCHAR":
          case "BIT":
          case "INTEGER":
            return false;
          default:
            return true;
        }
      }); 

      this.validationClassificationMetricsData=sqlResponse['resultSet'].data.filter(row => {
        switch (row[this.validationMetricsColumnIds.PREDICTING_COLUMN_TYPE]) {
          case "VARCHAR":
          case "BIT":
          case "INTEGER":
            return true;
          default:
            return false;
        }
      }); 
      this.loadInProgress=false;
    }));
  }

  viewLog(runName): void {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
        
    if (this.type=='trainingRuns') {
      var fieldName="TRAINING_RUN_NAME";
      var tableName="INFORMATION_SCHEMA.ML_TRAINING_RUNS";
    } else if (this.type=="validationRuns") {
      var fieldName="VALIDATION_RUN_NAME";
      var tableName="INFORMATION_SCHEMA.ML_VALIDATION_RUNS";
    }
    const sql =
      `SELECT LOG FROM ${tableName} WHERE ${fieldName} = '${runName}'`;

      this.sqlQueryService.executeSQLStatement(deployment, sql).subscribe(sqlResponse => {
        //get log field from response and pass to fileviewer
        this.openFileViewerModal({ "title": runName, "log": sqlResponse['resultSet']?.data[0]}); //,this.onModalSubmit.bind(this));
        });
  }


  openFileViewerModal(data:any): void {
    const modalData: IscFormModalData = {
        modalTitle: data.title, 
        iscFormInputs: {
          Id: 'logViewer',
        
          FDN: {
            name: '',
            validateOn: 'change',
            sectionLayout: { showSectionHeaders: false },
            sections: [
                {
                fields: [
                  {
                    id: 'fileData',
                    key: 'fileData',
                    type: 'instructions',
                    templateOptions: {
                      label: '',
                    },
                    data: {
                      content: data.log.toString(),
                    },
                    wrappers: ['file-viewer-wrapper'],
                  },
                ]
              }
            ]
          }, 
          formModel:  data,
          mode: FormModes.EDIT,
          formConfig: {},
          buttons: [
            {
            id: 'close',
            text: 'Close',
            buttonClass: 'primary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
          ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }

  showChart(runName): void {
    const deploymentId = this.route.snapshot.paramMap.get('deploymentId');
    const deployment: DeploymentObject = this.deploymentsService.findDeployment(
      this.deploymentsService.deployments,
      deploymentId,
    );
    
    //update this to get trained model name and the model field to pass in for the query
    const sql =
    `SELECT model_name, trained_model_name,validation_run_query,
      (select predicting_column_name FROM INFORMATION_SCHEMA.ML_MODELS WHERE model_name=runs.model_name) as Column
      FROM INFORMATION_SCHEMA.ML_VALIDATION_RUNS as runs
      WHERE validation_run_name = '${runName}'`;
      



    this.sqlQueryService.executeSQLStatement(deployment, sql).pipe(take(1)).subscribe(sqlResponse => {
      var table=sqlResponse['resultSet'].data[0][2].split(" FROM ");
      table=table[table.length-1];
      //query to get the trained model name, the model field and the table for the query
      const sqlChartData =`SELECT \
      TOP(100) \
      PREDICT(${sqlResponse['resultSet'].data[0][0]} use ${sqlResponse['resultSet'].data[0][1]}) as prediction, \
      ${sqlResponse['resultSet'].data[0][3]} as actual \
      FROM ${table}`;

        //get log field from response and pass to fileviewer
        this.openIMLChartModal({ "title": runName, "deployment": deployment,"sql": sqlChartData}); //,this.onModalSubmit.bind(this));
      });
  }


  openIMLChartModal(data:any): void {
    const modalData: IscFormModalData = {
        modalTitle: data.title, 
        iscFormInputs: {
          Id: 'imlChart',
        
          FDN: {
            name: '',
            validateOn: 'change',
            sectionLayout: { showSectionHeaders: false },
            sections: [
                {
                fields: [
                  {
                    id: 'fileData',
                    key: 'fileData',
                    type: 'instructions',
                    templateOptions: {
                      label: '',
                    },
                    data: {
                      content: data,
                    },
                    wrappers: ['iml-chart-wrapper'],
                  },
                ]
              }
            ]
          }, 
          formModel:  data,
          mode: FormModes.EDIT,
          formConfig: {},
          buttons: [
            {
            id: 'close',
            text: 'Close',
            buttonClass: 'primary',
            type: 'button',
            callback: (clickEvent: any, button: any, formModel: any, formOptions: any, form: any) => {
                dialogRef.close();
            }
            },
          ]
        }
    }

    const dialogRef: MatDialogRef<any, any> = this.dialog.open(
      GenericDialogComponent,
        {
            panelClass: 'isc-form-modal',
            data: modalData,
            autoFocus: false
        }
    );
  }


}
