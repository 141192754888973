<app-deployments-list
  *ngIf="deployments$ | async | deploymentsListFilter as deployments"
  [deployments]="deployments"
  [currentTenantData]="currentTenantData"
  (selected)="onSelect($event)"
  (deleted)="onDelete($event)"
  (servicelevel)="onServiceLevel($event)"
  (update)="onUpdateDeploymentVersion($event)"
  (create)="onCreate()"
>
</app-deployments-list>
